import { FC, useEffect, useState } from 'react';

import { usePostFeedback } from "../hooks/api";
import useScreenSize from "../hooks/useScreenSize";
import useDetectClientBroswerOS from "../hooks/useDetectClientBroswerOS";
import useDebounceShowNotificationModal from "../hooks/useDebounceNotificationModal";
import useChangeHeaderColorFirstLoad from "../hooks/useChangeHeaderColorFirstLoad";

import { APP_COLOR_HINT, PLATFORM_ANDROID, webApp } from "../models/models";
import { showMainTelegramButton, unmountedTelegramButton } from "../models/functions";

import Htag from "../components/Htag";
import NotificationModal from "../components/Modals/NotificationModal";
import CloseIcon from "../components/CloseIcon";

import Form from 'react-bootstrap/Form';

import { useTranslation } from "react-i18next";

import "../styles/Feedback.css";

const Feedback: FC = (): JSX.Element => {

  const { t } = useTranslation();

  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [showNotificationModalError, setShowNotificationModalError] = useState<boolean>(false);
  const [feedbackValue, setFeedbackValue] = useState<string>("");
  const [feedbackImages, setFeedbackImages] = useState<File[]>([]);
  const [feedbackImagesValidated, setFeedbackImagesValidated] = useState<string>("");
  const { os: clientOs, browser: clientBrowser } = useDetectClientBroswerOS();
  const { clientWidth, clientHeight } = useScreenSize();
  const MAX_FILE_COUNT = 100;
  const MAX_FILE_SIZE_MB = 10;

  const {
		mutate: postFeedback,
    status: postFeedbackStatus,
    error: postFeedbackError
	} = usePostFeedback();

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackValue(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);

      if (selectedFiles.length + feedbackImages.length > MAX_FILE_COUNT) { 
        setFeedbackImagesValidated(`${t("Error feedback - limit files")} ${MAX_FILE_COUNT}`);
        return;
      }

      const oversizedFiles = selectedFiles.filter(file => file.size > MAX_FILE_SIZE_MB * 1024 * 1024).length;
      if (oversizedFiles > 0) {
        setFeedbackImagesValidated(`${t("Error feedback - limit size")} ${MAX_FILE_SIZE_MB}`);
      }

      let filesToLoad = selectedFiles.filter(file => file.size < MAX_FILE_SIZE_MB * 1024 * 1024);

      // Добавляем проверку только для платформы Android
      filesToLoad = selectedFiles.filter(file => {
        if (PLATFORM_ANDROID) {
          const imageType = file.type.startsWith('image/');
          // Если платформа Android, пропускаем только изображения
          if(!imageType) {
            setFeedbackImagesValidated(t("Error feedback - file type"))
          } 
          return imageType;
        } else {
          // Для других платформ пропускаем все файлы
          return true;
        }
      });

      setFeedbackImages([...feedbackImages, ...filesToLoad]);
    }
  }

  const onSubmit = () => {
    postFeedback({
      feedback: feedbackValue,
      os: clientOs,
      browser: clientBrowser,
      screen_width: clientWidth.toString(),
      screen_height: clientHeight.toString(),
      images: feedbackImages
    });
    setFeedbackValue("");
    setFeedbackImages([]);
    setFeedbackImagesValidated("");
  }

  useDebounceShowNotificationModal(showNotificationModal, setShowNotificationModal);
  useDebounceShowNotificationModal(showNotificationModalError, setShowNotificationModalError);
  useChangeHeaderColorFirstLoad();

  useEffect(() => {
    if(!feedbackValue) {
      showMainTelegramButton(onSubmit, t("Send"), APP_COLOR_HINT);
    } else {
      showMainTelegramButton(onSubmit, t("Send"));
    }
    return () => unmountedTelegramButton(onSubmit);
  }, [feedbackValue, feedbackImages])

  useEffect(() => {
    if(postFeedbackStatus === "pending") {
      webApp.MainButton.disable();
      webApp.MainButton.showProgress();
      webApp.MainButton.color = APP_COLOR_HINT;
      webApp.MainButton.text = t("Sending");
    } else {
      webApp.MainButton.text = t("Send");
      webApp.MainButton.hideProgress();
      if(feedbackValue) {
        webApp.MainButton.color = webApp.themeParams.button_color;
        webApp.MainButton.enable();
      } else {
        webApp.MainButton.color = APP_COLOR_HINT;
        webApp.MainButton.disable();
      }

    if(postFeedbackStatus === "success") {
      setShowNotificationModal(true);
      setFeedbackValue("");
    }

    if(postFeedbackStatus === "error") {
      setShowNotificationModalError(true);
      setFeedbackValue("");
    }
    }
  }, [postFeedbackStatus])

  useEffect(() => {
    if(feedbackValue) {
      webApp.MainButton.enable()
      webApp.MainButton.color = webApp.themeParams.button_color
    } else {
      webApp.MainButton.disable()
      webApp.MainButton.color = APP_COLOR_HINT
    } 
  }, [feedbackValue])

  return (
    <section className="feedback-wrapper">

      <Htag tag="h3">{t("Feedback")}</Htag>

      <form
        onSubmit={(e) => e.preventDefault()}
        className="feedback-form-wrapper"
        encType="multipart/form-data"
      >

        <Form.Group controlId="feedback-input" className="feedback-form rounded">
          <Form.Control
            autoFocus
            maxLength={1024}
            type="text"
            as="textarea"
            rows={5}
            value={feedbackValue}
            onChange={onChange} 
          />

          <div className="feedback-close-wrapper">
            <CloseIcon onClick={() => setFeedbackValue("")}/>
          </div>
        </Form.Group>

        <div className="feedback-images">
          <label>
          <input 
              type="file" 
              multiple
              // image/* - вызывает ошибку на android
              accept={!PLATFORM_ANDROID ? "image/*" : undefined}
              onChange={handleImageChange} 
            />
            <span className="input-file-btn" onClick={() => setFeedbackImagesValidated("")}>{t("Select files")}</span>           
	   	      
            <p className="input-file-text text-hint">
              {
                feedbackImages.length === 0
                  ? t("Max size file")
                  : `${t("Count files")} ${feedbackImages.length}` 
              }
            </p>

          </label>

          <div className="feedback-images-close-wrapper">
            <CloseIcon onClick={() => { setFeedbackImages([]); setFeedbackImagesValidated(""); }} />
          </div>
        </div>

      </form>

      {
        feedbackImagesValidated
        ? <p className="feedback-subtitle text-danger">{feedbackImagesValidated}</p>
        : <p className="feedback-subtitle">{t("Feedback description")}</p>
      }

      {
        showNotificationModal && (
          <NotificationModal
            icon="checkmark"
            text={t("Feedback sent")} />
        )
      }

      {
        showNotificationModalError && (
          <NotificationModal
            icon="warning"
            text={t("There is an error") + ": " + postFeedbackError?.message} />
        )
      }

    </section >
  );
};

export default Feedback;
