import { create } from "zustand";
import { persist } from "zustand/middleware";

import {
  ChangeLanguage,
  SelectMethodInModal,
  RememberChannelWithoutFeeds,
  PageScrollPosition,
  SearchChannelValue,
  SliderPositionX,
  DefaultFeedToChannelAction,
  SortedChannelsOption,
  SortedChannelsByDateProps,
  ProductTourProps,
  ToggleHeaderCatalog,
  ShowModalProps,
  CountryStore,
  ToggleStore,
} from "./models/storeModels";

export const useShowModal_Favorites = create<ShowModalProps>()(
  persist(
    (set, get) => ({
      show: false,
      setShow: (showModal: boolean) => set(() => ({ show: showModal })),
    }),
    {
      name: "key_change-modal-favorites",
    }
  )
);

export const useShowModal_Help = create<ShowModalProps>()(
  persist(
    (set, get) => ({
      show: false,
      setShow: (showModal: boolean) => set(() => ({ show: showModal })),
    }),
    {
      name: "key_change-modal-help",
    }
  )
);

export const useShowModal_FeedsLimit = create<ShowModalProps>()(
  persist(
    (set, get) => ({
      show: false,
      setShow: (showModal: boolean) => set(() => ({ show: showModal })),
    }),
    {
      name: "key_change-modal-feeds-limit",
    }
  )
);

export const useShowAddChannelModal_Library = create<ShowModalProps>()(
  persist(
    (set, get) => ({
      show: false,
      setShow: (showModal) => set(() => ({ show: showModal })),
    }),
    {
      name: "key_show-add-channel-modal-library",
    }
  )
);

export const useProductTour_SwipeChannel = create<ProductTourProps>()(
  persist(
    (set, get) => ({
      statusProductTour: true,
      updateStatusProductTour: (status) => set(() => ({ statusProductTour: status })),
    }),
    {
      name: "key_change-product-tour_swipe-channel",
    }
  )
);

export const useProductTour_SwipeFeed = create<ProductTourProps>()(
  persist(
    (set, get) => ({
      statusProductTour: true,
      updateStatusProductTour: (status) => set(() => ({ statusProductTour: status })),
    }),
    {
      name: "key_change-product-tour_swipe-feed",
    }
  )
);

export const useProductTour_AddChannelFromFeed = create<ProductTourProps>()(
  persist(
    (set, get) => ({
      statusProductTour: true,
      updateStatusProductTour: (status) => set(() => ({ statusProductTour: status })),
    }),
    {
      name: "key_change-product-tour_add-channel-from-feed",
    }
  )
);

export const useProductTour_CreateFeed = create<ProductTourProps>()(
  persist(
    (set, get) => ({
      statusProductTour: true,
      updateStatusProductTour: (status) => set(() => ({ statusProductTour: status })),
    }),
    {
      name: "key_change-product-tour_create-feed",
    }
  )
);

export const useProductTour = create<ProductTourProps>()(
  persist(
    (set, get) => ({
      statusProductTour: true,
      updateStatusProductTour: (status) => set(() => ({ statusProductTour: status })),
    }),
    {
      name: "key_change-product-tour-onboarding",
    }
  )
);

export const useToggleHeaderCatalog = create<ToggleHeaderCatalog>()(
  persist(
    (set, get) => ({
      channelInputFocusStore: null,
      showSearchInputStore: false,
      updateChannelInputFocusStore: (focus) => set(() => ({ channelInputFocusStore: focus })),
      updateShowSearchInputStore: (show) => set(() => ({ showSearchInputStore: show })),
    }),
    {
      name: "key_toggle-header-catalog",
    }
  )
);

export const useSortedChannelsByDate = create<SortedChannelsByDateProps>()(
  persist(
    (set, get) => ({
      date: "",
      changeDate: (date) => set(() => ({ date: date })),
    }),
    {
      name: "key_change-sorted-channels-by-date",
    }
  )
);

export const useSortedChannelsOption = create<SortedChannelsOption>()(
  persist(
    (set, get) => ({
      option: "participants",
      changeOption: (option) => set(() => ({ option: option })),
    }),
    {
      name: "key_change-sorted-channels-option",
    }
  )
);

export const useDefaultFeedToAddChannel = create<DefaultFeedToChannelAction>()(
  persist(
    (set, get) => ({
      defaultFeed: null,
      updateFeed: (feed) => set(() => ({ defaultFeed: feed || null })),
    }),
    {
      name: "key_change-default-feed-to-add-channel",
    }
  )
);

export const useDefaultFeedToMoveChannel = create<DefaultFeedToChannelAction>()(
  persist(
    (set, get) => ({
      defaultFeed: null,
      updateFeed: (feed) => set(() => ({ defaultFeed: feed || null })),
    }),
    {
      name: "key_change-default-feed-to-move-channel",
    }
  )
);

export const useCategoriesSliderPositionX = create<SliderPositionX>()(
  persist(
    (set, get) => ({
      xCoord: 0,
      updateXCoord: (coord) => set(() => ({ xCoord: coord })),
    }),
    {
      name: "key_change-categories-slider-position-x",
    }
  )
);

export const useBlogsSliderPositionX = create<SliderPositionX>()(
  persist(
    (set, get) => ({
      xCoord: 0,
      updateXCoord: (coord) => set(() => ({ xCoord: coord })),
    }),
    {
      name: "key_change-blogs-slider-position-x",
    }
  )
);

export const useSearchChannelValue = create<SearchChannelValue>()(
  persist(
    (set, get) => ({
      value: "",
      updateValue: (value) => set(() => ({ value: value })),
    }),
    {
      name: "key_change-search-channel-value",
    }
  )
);

export const useCategoryDetailScrollPosition = create<PageScrollPosition>()(
  persist(
    (set, get) => ({
      scrollPositionState: 0,
      changeScrollPosition: (position) =>
        set(() => ({ scrollPositionState: position })),
    }),
    {
      name: "key_change-library-scroll-position",
    }
  )
);

export const useLibraryScrollPosition = create<PageScrollPosition>()(
  persist(
    (set, get) => ({
      scrollPositionState: 0,
      changeScrollPosition: (position) =>
        set(() => ({ scrollPositionState: position })),
    }),
    {
      name: "key_change-library-scroll-position",
    }
  )
);

export const useRememberChannelWithoutFeeds =
  create<RememberChannelWithoutFeeds>()(
    persist(
      (set, get) => ({
        channelId: null,
        rememberChannelId: (id) => set(() => ({ channelId: id })),
      }),
      {
        name: "key_change-channel-without-feeds",
      }
    )
  );

export const useSelectMethodToAddChannel = create<SelectMethodInModal>()(
  persist(
    (set, get) => ({
      method: "",
      changeMethod: (newMethod) => set(() => ({ method: newMethod })),
    }),
    {
      name: "key_change-method-to-add-channel",
    }
  )
);

// settings - beging
export const useChangeLanguage = create<ChangeLanguage>()(
  persist(
    (set, get) => ({
      language: "ru",
      changeLanguage: (langCode) => set(() => ({ language: langCode })),
    }),
    {
      name: "key_change-language",
    }
  )
);

export const useChangeCountry = create<CountryStore>()(
  persist(
    (set, get) => ({
      countryCodeStore: "ru",
      changeCountryCodeStore: (code) => set(() => ({ countryCodeStore: code })),
      countryNameStore: "Россия",
      changeCountryNameStore: (name) => set(() => ({ countryNameStore: name })),
    }),
    {
      name: "key_change-country",
    }
  )
);

export const useToggleShowHelpers = create<ToggleStore>()(
  persist(
    (set, get) => ({
      toggleStateStore: true,
      setToggleStateStore: () =>
        set((state) => ({ toggleStateStore: !state.toggleStateStore })),
    }),
    {
      name: "key_show-helpers",
    }
  )
);

export const useToggleShowAdultsChannels = create<ToggleStore>()(
  persist(
    (set, get) => ({
      toggleStateStore: false,
      setToggleStateStore: () =>
        set((state) => ({ toggleStateStore: !state.toggleStateStore })),
    }),
    {
      name: "key_show-adults-channels",
    }
  )
);

export const useToggleSummarizeAdPosts = create<ToggleStore>()(
  persist(
    (set, get) => ({
      toggleStateStore: false,
      setToggleStateStore: () =>
        set((state) => ({ toggleStateStore: !state.toggleStateStore })),
    }),
    {
      name: "key_show-summarize-ad-posts",
    }
  )
);

// settings - end