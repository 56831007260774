import { useEffect, useState } from "react";

const useDetectClientBroswerOS = () => {
	const [os, setOs] = useState<string>("unknown");
	const [browser, setBrowser] = useState<string>("unknown");
  
	useEffect(() => {
	  const header = [
		navigator.platform,
		navigator.userAgent,
		navigator.appVersion,
		navigator.vendor,
		// @ts-ignore
		window.opera,
	  ];
	  const dataos = [
		{ name: "Windows Phone", value: "Windows Phone" },
		{ name: "Windows", value: "Win" },
		{ name: "iPhone", value: "iPhone" },
		{ name: "iPad", value: "iPad" },
		{ name: "Kindle", value: "Silk" },
		{ name: "Android", value: "Android" },
		{ name: "PlayBook", value: "PlayBook" },
		{ name: "BlackBerry", value: "BlackBerry"},
		{ name: "Macintosh", value: "Mac" },
		{ name: "Linux", value: "Linux" },
		{ name: "Palm", value: "Palm" },
	  ];
	  const databrowser = [
		{ name: "Chrome", value: "Chrome" },
		{ name: "Firefox", value: "Firefox" },
		{ name: "Safari", value: "Safari" },
		{ name: "Internet Explorer", value: "MSIE" },
		{ name: "Opera", value: "Opera" },
		{ name: "BlackBerry", value: "CLDC" },
		{ name: "Mozilla" },
	  ];
  
	  const agent = header.join(" ");
	  const osResult = matchItem(agent, dataos);
	  const browserResult = matchItem(agent, databrowser);
  
	  setOs(osResult);
	  setBrowser(browserResult);
	}, []);
  
	const matchItem = (string:string, data: any) => {
	  for (let i = 0; i < data.length; i++) {
		const regex = new RegExp(data[i].value, "i");
		const match = regex.test(string);
  
		if (match) {  
		  return data[i].name
		}
	  }
  
	  return "unknown"
	};
  
	return { os, browser }
}

export default useDetectClientBroswerOS;