import { FC, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {
    webApp,
    ChannelModel,
    DeleteChannelParams,
    FeedModel,
    MoveChannelParams,
    MAX_CHANNELS_IN_FEED,
    THEME_DARK,
    PLATFORM_IOS,
    PLATFORM_ANDROID,
    APP_COLOR_HINT
} from '../models/models';
import {
    checkDuplicateChannels,
    createPreviewImageFromTitle,
    createPreviewLink,
    getSubscribers,
    calculateFeedsModalPaddingTop
} from "../models/functions";
import { getFeeds, useDeleteChannelFromFeed, useMoveChannelToAnotherFeed } from "../hooks/api";
import useDebounceShowNotificationModal from "../hooks/useDebounceNotificationModal";
import useChangeHeaderColorInModal from "../hooks/useChangeHeaderColorInModal";
import { useDefaultFeedToMoveChannel } from "../store";

import { ReactComponent as CheckMarkIcon } from "../icons/checkmark.svg";
import { ReactComponent as MoveIcon } from "../icons/move-icon.svg";

import NotificationModal from "./Modals/NotificationModal";
import ChannelIcon from "./ChannelIcon";

import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';

import { useQuery } from "@tanstack/react-query";
import { useExpand, useShowPopup } from '@vkruglikov/react-telegram-web-app';
import { useTranslation } from "react-i18next";

import 'react-swipeable-list/dist/styles.css';

import "../styles/Lists.css"

const ChannelsList: FC<{
    channels: ChannelModel[],
    feedId: number,
    closeAddChannelModal: () => void,
    showMoveModal: boolean,
    setShowMoveModal: (boolean: boolean) => void;
    movingChannel: boolean;
    setMovingChannel: (state: boolean) => void;
}> = ({ 
    channels, 
    feedId, 
    closeAddChannelModal, 
    showMoveModal, 
    setShowMoveModal,
    movingChannel, 
    setMovingChannel 
}) => {

    const { t } = useTranslation();

    const navigate = useNavigate()

    const { defaultFeed: defaultFeedToMove, updateFeed: updateDefaultFeedToMove } = useDefaultFeedToMoveChannel();

    const [isExpanded, expand] = useExpand();

    const [selectedChannelToDelete, setSelectedChannelToDelete] = useState<number | null>(null);
    const [selectedChannelToMove, setSelectedChannelToMove] = useState<number | null>(null);
    const [selectedChannelToCheckDuplicate, setSelectedChannelToCheckDuplicate] = useState<number | null>(null);
    const [selectedFeedToMove, setSelectedFeedToMove] = useState<FeedModel | null>(defaultFeedToMove);
    const [dragProgressState, setDragProgressState] = useState<number>(0);
    const [deletedChannels, setDeletedChannels] = useState<{ id: number }[]>([]);
    const [availabilityFeedsToMoveChannel, setAvailabilityFeedsToMoveChannel] = useState<boolean>(false);
    const [maximumChannelsIfFeedToMove, setMaximumChannelsIfFeedToMove] = useState<boolean>(false);

    const handleCloseMoveModal = () => {
        setShowMoveModal(false);
    }

    const handleShowMoveModal = () => {
        if(feeds && feeds.filter((feed) => feed.status === "transferred").length < 2) {
            setAvailabilityFeedsToMoveChannel(true);
        } else {
            setShowMoveModal(true);
        }
    }

    const showPopup = useShowPopup();

    const handleShowDeleteModal = () => {
        showPopup({
            buttons: [
                { id: "delete_channel-no", type: 'cancel' },
                { id: "delete_channel-yes", type: 'destructive', text: t("Delete") }
            ],
            message: t("Want to delete channel")
        })
            .then(id => id === 'delete_channel-yes' && selectedChannelToDelete && deleteChannel(paramsToDeleteChannel));
    }

    const paramsToDeleteChannel = {
        feed: feedId,
        channel: selectedChannelToDelete,
    };

    const paramsToMoveChannel = {
        from: feedId,
        where: selectedFeedToMove && selectedFeedToMove.id,
        channel: {
            channel_id: selectedChannelToMove
        },
    };

    const { mutate: deleteChannelFromFeed } = useDeleteChannelFromFeed();
    const { 
        mutate: moveChannelToAnotherFeed,
        status: statusMoveChannel
     } = useMoveChannelToAnotherFeed();

    const deleteChannel = (params: DeleteChannelParams) => {
        deleteChannelFromFeed(params);
        params.channel && setDeletedChannels([...deletedChannels, { id: params.channel }]);
    };
    const moveChannel = (params: MoveChannelParams) => {
        setMovingChannel(true);
        moveChannelToAnotherFeed(params);
    };

    const { data: feeds } = useQuery<FeedModel[], Error>({
        queryKey: ["feeds"],
        queryFn: getFeeds,
    });

    const duplicateChannel = checkDuplicateChannels(selectedFeedToMove, selectedChannelToCheckDuplicate);
    const fullChannelsInFeedToMove = selectedFeedToMove && selectedFeedToMove.channels && selectedFeedToMove.channels.length >= MAX_CHANNELS_IN_FEED;

    const onMainButtonClick = () =>{
        if(fullChannelsInFeedToMove) {
            setMaximumChannelsIfFeedToMove(true);
        } else {
            moveChannel(paramsToMoveChannel);
            handleCloseMoveModal();
            closeAddChannelModal();
        }
    }

    useChangeHeaderColorInModal(showMoveModal);
    useDebounceShowNotificationModal(availabilityFeedsToMoveChannel, setAvailabilityFeedsToMoveChannel);
    useDebounceShowNotificationModal(maximumChannelsIfFeedToMove, setMaximumChannelsIfFeedToMove);

    // render & watch MainButton - start
    useEffect(() => {
        if(!showMoveModal) return;
        if (duplicateChannel || !selectedFeedToMove) {
            webApp.MainButton.disable();
            webApp.MainButton.color = APP_COLOR_HINT;
        } else {
            webApp.MainButton.enable();
            webApp.MainButton.onClick(onMainButtonClick);
            webApp.MainButton.color = webApp.themeParams.button_color;
        }

        return () => {
            if (duplicateChannel || !selectedFeedToMove) {
                webApp.MainButton.disable();
                webApp.MainButton.color = webApp.themeParams.button_color;
            } else {
                webApp.MainButton.offClick(onMainButtonClick);
                webApp.MainButton.color = webApp.themeParams.button_color;
            }
        }
    }, [])

    useEffect(() => {
        if(!showMoveModal) return;
        if (duplicateChannel || !selectedFeedToMove || movingChannel) {
            webApp.MainButton.disable();
            webApp.MainButton.color = APP_COLOR_HINT;
        } else {
            if(fullChannelsInFeedToMove) {
                webApp.MainButton.color = APP_COLOR_HINT;
            } else {
                webApp.MainButton.color = webApp.themeParams.button_color;
            }
            webApp.MainButton.enable();
            webApp.MainButton.onClick(onMainButtonClick);
        }

        return () => {
            if (duplicateChannel || !selectedFeedToMove) {
                webApp.MainButton.disable();
                webApp.MainButton.color = webApp.themeParams.button_color;
            } else {
                webApp.MainButton.offClick(onMainButtonClick)
                webApp.MainButton.color = webApp.themeParams.button_color;
            }
        }
    }, [duplicateChannel, selectedFeedToMove, showMoveModal, fullChannelsInFeedToMove, movingChannel])

    useEffect(() => {
        if (duplicateChannel) {
            webApp.MainButton.text = t("Сhannel already been added");
        } else {
            webApp.MainButton.text = t("Move");
        }
    }, [])

    useEffect(() =>{
        if(statusMoveChannel === "success") {
            setTimeout(() => {
                setMovingChannel(false);
            }, 500)
        }
    }, [statusMoveChannel])

    useEffect(() => {
        if(!showMoveModal) return;
        if (duplicateChannel) {
            webApp.MainButton.text = t("Сhannel already been added")
        } else {
            webApp.MainButton.text = t("Move")
        }
    }, [duplicateChannel, showMoveModal])
    // render & watch MainButton - end

    useEffect(() => {
        if (showMoveModal && !isExpanded) {
            handleCloseMoveModal();
        }
    }, [isExpanded])

    const leftSwipe = () => (
        <LeadingActions>
            <SwipeAction
                onClick={handleShowMoveModal}>
                <div className="channels-list-left-swipe swipe-wrapper">
                    <MoveIcon />
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const rightSwipe = () => (
        <TrailingActions>
            <SwipeAction
                onClick={handleShowDeleteModal}>
                <div className="channels-list-right-swipe ight-swipe swipe-wrapper">
                    <p className="text-white m-auto p-1 fs-1">&#215;</p>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    const listItems = channels.map((item, index) => {
        const { id, title, participants, url, image100, telegram_id } = item;

        const onClickItem = (item: ChannelModel) => {
            if ((!PLATFORM_ANDROID || !PLATFORM_IOS) && dragProgressState) {
                return undefined
            } else {
                navigate("/library/channel/" + item.id, { replace: false, state: { channel: item, fromFeed: true } })
            }
        }

        if (deletedChannels.find((channel) => channel.id === id)) return <></>;

        return (
            <SwipeableListItem
                maxSwipe={0.5}
                leadingActions={leftSwipe()}
                trailingActions={rightSwipe()}
                onSwipeStart={() => { 
                    setSelectedChannelToDelete(id); 
                    setSelectedChannelToMove(id); 
                    setSelectedChannelToCheckDuplicate(telegram_id) 
                }}
                onSwipeEnd={() => setTimeout(() => setDragProgressState(0), 0)}
                onSwipeProgress={(progress: number) => setDragProgressState(progress)}
                key={id}
            >
                <ListGroup.Item
                    className="custom-list-wrapper-item background-list-item"
                    onClick={() => onClickItem(item)}
                    id={index === 0 ? "product_tour_swipe-channel" : " "}
                >

                    <ChannelIcon
                        img={image100}
                        title={title}
                    />

                    <div className="custom-list-wrapper-title custom-wrap-text-ellipsis">
                        <p className="text-color custom-wrap-text-ellipsis">{title}</p>
                        <p className="text-hint custom-list-wrapper-subtitle">
                            {`${!url.includes("/joinchat") ? createPreviewLink(url) : ""} [${getSubscribers(participants)}]`}
                        </p>
                    </div>

                </ListGroup.Item>
            </SwipeableListItem>
        )
    });

    const feedsToMove = feeds && feeds.filter(
        (feed) => feed.status === "transferred" 
        && feed.channels 
        && feed.id.toString() !== feedId.toString()
    );

    return (
        <>
            <ListGroup className="custom-list-wrapper background-list-item">
                <SwipeableList
                    threshold={0.25}
                    scrollStartThreshold={0.5}
                    swipeStartThreshold={0.5}
                    fullSwipe={false}
                    type={ListType.IOS}>
                    {listItems}
                </SwipeableList>
            </ListGroup>

            <Modal
                animation={false}
                centered
                show={showMoveModal}
                onHide={handleCloseMoveModal}
                dialogClassName="custom-modal modal-move"
                style={feeds && feedsToMove && feedsToMove.length > 0 ? { paddingTop: calculateFeedsModalPaddingTop(feedsToMove.length) } : { display: "none" } }
                aria-labelledby="modal-move-channel"
            >

                <Modal.Header className="custom-modal-header-wrapper">
                    <p className="custom-modal-header text-hint">{t("Select feed")}</p>
                </Modal.Header>

                <Modal.Body>
                    {
                        feedsToMove &&
                        <ListGroup className="custom-list-wrapper">
                            {
                                feedsToMove.map((feed, index) => {
                                    const { id, title } = feed;

                                    return (
                                        <ListGroup.Item
                                            className="custom-list-wrapper-item background-list-item"
                                            key={id}
                                            onClick={() => {
                                                setSelectedFeedToMove(feed);
                                                updateDefaultFeedToMove(feed);
                                                id === paramsToMoveChannel.where && setSelectedFeedToMove(null);
                                                setMaximumChannelsIfFeedToMove(false);
                                            }}>

                                            <div className="list-item-no-image-wrapper">
                                                <div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
                                            </div>

                                            <div className={`
                                                custom-list-wrapper-title custom-wrap-text-ellipsis 
                                                ${THEME_DARK
                                                    ? "text-white border-color-separator"
                                                    : ""
                                                }
                                            `}>
                                                <p className="custom-wrap-text-ellipsis custom-list-wrapper-title_checkmark">{title}</p>
                                            </div>

                                            {
                                                id === paramsToMoveChannel.where && (
                                                    <div className="custom-list-wrapper-checkmark">
                                                        <CheckMarkIcon />
                                                    </div>
                                                )
                                            }

                                            {
                                                feedsToMove.length !== index + 1 && (
                                                    <span className={THEME_DARK ? "border-bottom_after-dark" : "border-bottom_after"} />
                                                )
                                            }

                                        </ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                    }
                </Modal.Body>

            </Modal>

            {
                availabilityFeedsToMoveChannel && (
                    <NotificationModal 
                        text={t("You haven't available feeds to move channels")}
                        icon="warning"
                    />
                )
            }

            {
                maximumChannelsIfFeedToMove && selectedFeedToMove && (
                    <NotificationModal 
                        text={`${t("Maximum number of channels in feed")} «${selectedFeedToMove.title}»: ${MAX_CHANNELS_IN_FEED}`} 
                        icon="warning"
                    /> 
                )
            }

            {
                statusMoveChannel === "success" && (
                    <NotificationModal 
                        text={t("Channel was moved")} 
                        icon="checkmark"
                    /> 
                )
            }
        </>
    );
};

export default ChannelsList;