import { THEME_DARK } from "../../models/models";

import Htag from "../Htag";
import CloseIcon from "../CloseIcon";

import { TooltipRenderProps } from "react-joyride";

import "../../styles/ProductTour.css";

const Tooltip = ({
	continuous,
	index,
	step,
	backProps,
	primaryProps,
	tooltipProps,
	skipProps
  }: TooltipRenderProps) => {

	const { target } = step;

	const selectNextButtonText = () => {
		if(target === "#product_tour_create_feed" || target === "#product_tour_add-channel-from-feed_catalog" || (target === "#product-tour-main-button" && index === 5)) {
			return "Понятно"
		} else {
			return "Дальше"
		}
	}

	return (
		<div {...tooltipProps} className="product-tour-tooltip-wrapper">

			<div className="product-tour-tooltip-skip-wrapper">
				<button className="product-tour-tooltip-skip-wrapper-button" {...skipProps}>
					<CloseIcon />
				</button>
			</div>
		  
			<div>
				{step.title && <Htag tag="h2" textColor={THEME_DARK ? "text-white" : "text-dark"}>{step.title}</Htag>}
		
				<div className={`${THEME_DARK ? "text-white" : "text-dark"}`}>{step.content}</div>
			</div>
		
			<div className="product-tour-tooltip-buttons-wrapper">
				{index > 0 && (
					<button className="product-tour-tooltip-button product-tour-tooltip-button_back" {...backProps}>
						Назад
					</button>
				)}
				{continuous && target !== "#product_tour_swipe-feed" && target !== "#product_tour_swipe-channel" && (
					<button className="product-tour-tooltip-button product-tour-tooltip-button_next" {...primaryProps}>
						{selectNextButtonText()}
					</button>
				)}
			</div>
	
		</div>
	  )
  };

export default Tooltip;