import { useEffect } from "react";
import { 
	MODAL_BG_COLOR,
	MODAL_BG_COLOR_DARK, 
	PLATFORM_IOS, 
	THEME_DARK, 
	webApp
} from "../models/models";

export default function useChangeHeaderColorInModal(
	show: boolean
) {

	useEffect(() => {
		if (show) {
			if (!THEME_DARK) {
				webApp.setBackgroundColor(MODAL_BG_COLOR);				
			} else {
				webApp.setBackgroundColor(MODAL_BG_COLOR_DARK);
			}
		} else {
			if (PLATFORM_IOS && THEME_DARK) {
				webApp.setBackgroundColor(webApp.themeParams.bg_color);
			} else {
				webApp.setBackgroundColor(webApp.themeParams.secondary_bg_color);
			}
		}
		webApp.setHeaderColor(webApp.backgroundColor as "bg_color");
	}, [show])

}