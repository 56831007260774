import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FeedModel, ChannelModel, webApp, PLATFORM_DESKTOP } from "../models/models";
import { 
	getSubscribers, 
	createPreviewImageFromTitle, 
	openTelegramLink, 
	unmountedTelegramButton, 
	showMainTelegramButton,
	unmountedTelegramBackButton, 
	showTelegramBackButton
} from "../models/functions";
import { getFeeds } from "../hooks/api";
import { useDetectReloadApp } from "../hooks/useDetectReloadApp";
import { useRememberChannelWithoutFeeds } from "../store";

import Htag from "../components/Htag";
import AddChannelModal from "../components/Modals/AddChannelModal";
import NotificationModal from "../components/Modals/NotificationModal";
import WithoutFeedsModal from "../components/Modals/WithoutFeedsModal";

import Card from 'react-bootstrap/Card';
import Image from "react-bootstrap/Image";
import Placeholder from 'react-bootstrap/Placeholder';

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import '../styles/ChannelDetail.css';

const ChannelDetail: FC = () => {

	const { t } = useTranslation();

	const navigate = useNavigate();
	const location = useLocation();

	const { rememberChannelId } = useRememberChannelWithoutFeeds();

	const [channel] = useState<ChannelModel | null>(location.state.channel || null);
	const [fromFeed] = useState<boolean>(location.state.fromFeed || false);
	const [fromForward] = useState<boolean>(location.state.fromForward || false);
	const [pageImageLoaded, setPageImageLoaded] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showModalWithoutFeeds, setShowModalWithoutFeeds] = useState<boolean>(false);
	const [addChannelStatus, setAddChannelStatus] = useState<string>("");
	const [currentFeed, setCurrentFeed] = useState<number>();
	const [backButtonClicked, setBackButtonClicked] = useState<boolean>(false);
	const isReloadApp = useDetectReloadApp();

	const {
		data: feeds,
	} = useQuery<FeedModel[], Error>({
		queryKey: ["feeds"],
		queryFn: getFeeds,
	});

	const onHide = () => {
		setShowModal(false);
	};

	const onHideWithoutFeeds = () => {
		setShowModalWithoutFeeds(false)
	}

	const backButtonClick = () => {
		setBackButtonClicked(true);
	}

	const goBack = () => {
		navigate(-1);
	}

	const goToFeed = () => {
		navigate("/feeds/details/" + currentFeed);
	}

	const goFeeds = () => {
		navigate("/")
	}

	const onClick = () => {
		if (feeds && feeds.length > 0) {
			setShowModal(true);
		} else {
			rememberChannelId(id)
			setShowModalWithoutFeeds(true)
		}
	}

	useEffect(() => {
		if(!isReloadApp) return;
			webApp.BackButton.hide();
			showMainTelegramButton(goFeeds, t("Back"));
	}, [isReloadApp]);

	useEffect(() => {
		webApp.expand();

		if(isReloadApp) return;

		if(!fromFeed) {
			showMainTelegramButton(onClick, t("Add channel"));
		} else {
			showMainTelegramButton(goBack, t("Back"));
		}
		showTelegramBackButton(backButtonClick)

		return () => {
			if(!fromFeed) {
				unmountedTelegramButton(onClick);
			} else {
				unmountedTelegramButton(goBack);
			}
			unmountedTelegramBackButton(backButtonClick);
		}
	}, [])

	useEffect(() => {
		if(!fromFeed && !showModal && !isReloadApp) {
			showMainTelegramButton(onClick, t("Add channel"));
		} 

		return () => {
			if(!fromFeed && !showModal) {
				unmountedTelegramButton(onClick);
			} 
		}
	}, [showModal])

	useEffect(() => {
		if (!fromForward && !isReloadApp) return;
		setShowModal(true);
		webApp.BackButton.hide();
	}, [fromForward])

	useEffect(() => {
		if (backButtonClicked === true) {
			setTimeout(() => {
				navigate(-1)
			}, 200);
		}
	}, [backButtonClicked])

	useEffect(() => {
		if (!showModal && fromFeed && !isReloadApp) {
			webApp.MainButton.show()
			webApp.MainButton.color = webApp.themeParams.button_color
			webApp.MainButton.text = t("Back")
		}
	}, [addChannelStatus, showModal])

	useEffect(() => {
		if(isReloadApp) return;

		if (addChannelStatus === "success" && fromForward) {
			webApp.MainButton.text = t("Read")
			webApp.MainButton.onClick(goToFeed)
		} else if (addChannelStatus === "success") {
			webApp.MainButton.text = t("Back")
			webApp.MainButton.onClick(goBack)
		}

		return () => {
			if(addChannelStatus === "success") {
				unmountedTelegramButton(goBack)
			}
		}
	}, [addChannelStatus])

	if (!channel) return <></>;

	const availabilityFeeds = feeds && feeds.length > 0;

	const { id, url, title, description, image640, participants, telegram_id } = channel;

	return (
		<>
			<section className="channel-detail-wrapper">

				<Card className="bg-transparent border-0 channel-card">

					{
						PLATFORM_DESKTOP
							? (
								<Image
									className="channel-image"
									rounded
									src={image640}
									alt={title} />
							)
							: (
								<>
									{
										image640
											? (
												<Image
													style={pageImageLoaded ? {} : { display: "none" }}
													className="channel-image"
													rounded
													src={image640}
													alt={title}
													onLoad={() => setPageImageLoaded(true)} />
											)
											: (
												<div className="channel-detail-no-image-wrapper">
													<div className="list-item-no-image-wrapper">
														<div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
													</div>
												</div>
											)
									}

									{
										image640 && !pageImageLoaded && (
											<Placeholder as="div" animation="glow" className="channel-details-placeholder-image">
												<Placeholder xs={12} bg="secondary" className="bg-placeholder-secondary" />
											</Placeholder>
										)
									}
								</>
							)
					}

					<Card.Body>
						<Htag tag="h4" textAlign="left">{title}</Htag>

						<div className="card-body-content">
							<Card.Subtitle>
								{getSubscribers(participants)}
							</Card.Subtitle>

							<Card.Link
								as="div"
								onClick={() => openTelegramLink(url)}
							>
								{t("Read")}
							</Card.Link>

						</div>

						<Card.Text>
							{description}
						</Card.Text>

					</Card.Body>
				</Card>

			</section>

			{
				availabilityFeeds && (
					<AddChannelModal
						show={showModal}
						onHide={onHide}
						channelId={id}
						telegramId={telegram_id}
						setAddChannelStatus={setAddChannelStatus}
						setCurrentFeed={setCurrentFeed}
					/>
				)
			}

			{
				!availabilityFeeds && showModalWithoutFeeds && <WithoutFeedsModal onHide={onHideWithoutFeeds} />
			}

			{addChannelStatus === "success" && (
				<NotificationModal text={`${t("Channel")} ${title} ${t("Successfully added")}`} />
			)}
		</>
	)
};

export default ChannelDetail;