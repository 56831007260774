import {
  BASE_URL,
  FeedModel,
  DeleteChannelParams,
  MetaDataParams,
  ChannelModel,
  MoveChannelParams,
  CreateFeedProps,
  AddChannelProps,
  webApp,
  EditFeedParams,
  getInfiniteChannelsByCategoryProps,
  getInfiniteChannelsBySearchProps,
  FeedbackPostProps,
  CountryCode,
} from '../models/models';
import axios from 'axios';
import { useMutation, useQueryClient } from "@tanstack/react-query";

axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'Bearer ' + bearer();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


// Get Feeds list - Begin
export async function getFeeds(): Promise<FeedModel[]> {
  const { data } = await axios.get(BASE_URL + '/feeds');
  return data.feeds as FeedModel[]
}
// Get Feeds list - End


// Get single Feed - Begin
export async function getFeed(feed: number): Promise<FeedModel> {
  const { data } = await axios.get(BASE_URL + "/feed/" + feed);
  return data.feed as FeedModel;
}
// Get single Feed - End


// Get  Channel - Begin
export async function getTelegramChannelLinkInfo(channelUrl: string): Promise<ChannelModel> {
  const { data } = await axios.get(BASE_URL + "/feeds/channel?url=" + channelUrl);
  return data.channel as ChannelModel;
}
// Get  Channel - End


//  Create Feed - Begin
export function useCreateFeed() {
  return useMutation({
    mutationFn: (feed: CreateFeedProps) => {
      return axios.post(BASE_URL + '/feed', feed)
    }
  })
}
// Create Feed - End


// Edit feed - Begin 
export function useEditFeed() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: EditFeedParams) => {
      return axios.post(BASE_URL + '/feed/' + params.feedId, params.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["feed"] })
    }
  })
}
// Edit feed - End


// Delete Feed - Begin
async function deleteFeed(feedId: number): Promise<FeedModel> {
  const { data } = await axios.delete(BASE_URL + '/feed/' + feedId);
  return data;
}
export function useDeleteFeed() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteFeed,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["feeds"] })
    },
  })
}
// Delete Feed - End


// Remove From Favorite - Begin
async function removeFromFavorites(post: string) {
  const { data } = await axios.delete(BASE_URL + '/favorites/' + post);
  return data;
}
export function useRemoveFromFavorites() {
  return useMutation({
    mutationFn: removeFromFavorites
  })
}
// Remove From Favorite - End


// Move channel to another feed - Begin 
export function useAddToFavorites() {
  return useMutation({
    mutationFn: (post: string) => {
      return axios.post(BASE_URL + '/favorites/' + post);
    }
  })
}
// Move channel to another feed - End


// Move channel to another feed - Begin 
export function useMoveChannelToAnotherFeed() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: MoveChannelParams) => {
      return axios.post(BASE_URL + '/feed/' + params.from + '/move/' + params.where, params.channel)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["feed"] })
    }
  })
}
// Move channel to another feed - End


// Remove Channel From Feed - Begin
async function deleteChannelFromFeed(params: DeleteChannelParams): Promise<DeleteChannelParams> {
  const { data } = await axios.delete(BASE_URL + '/feed/' + params.feed + '/channel/' + params.channel);
  return data;
}
export function useDeleteChannelFromFeed() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteChannelFromFeed,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["feed"] })
    },
  })
}
// Remove Channel From Feed - End


// Get meta data - Begin
export async function getMetaData(): Promise<MetaDataParams> {
  const { data } = await axios.get(BASE_URL + '/channels/meta');
  return data as MetaDataParams;
}
// Get meta data - End


// Get library channels list by category - Begin
export async function getLibraryChannelsByCategory(categoryId: number, country: CountryCode): Promise<ChannelModel[]> {
  const { data } = await axios.get(BASE_URL + "/channels?order_by=participants&order_direction=desc&category_id=" + categoryId + "&country=" + country);
  return data.channels as ChannelModel[];
}
// Get library channels list by category - End 


// Get infinite channels list by category - Begin
export async function getInfiniteChannelsByCategory({
  categoryId,
  page,
  by = "participants",
  direction = "desc",
  country = "ru",
  period = "",
}: getInfiniteChannelsByCategoryProps) {
  const { data } = await axios.get(BASE_URL + "/channels?country=" + country + "&order_by=" + by + "&order_direction=" + direction + "&category_id=" + categoryId + "&page=" + page + "&period=" + period);
  return data;
}
// Get infinite channels list by category - End


// Search channels in library - Begin
export async function getInfiniteChannelsBySearch({
  search = "",
  page,
  by = "participants",
  direction = "desc",
  country = "ru",
  period = "",
}: getInfiniteChannelsBySearchProps) {
  const { data } = await axios.get(BASE_URL + "/channels?country=" + country + "&order_by=" + by + "&order_direction=" + direction + "&q=" + search + "&page=" + page + "&period=" + period);
  return data;
}
// Search channels in library - End


// Add channel to feed - Begin
export function useAddChannel() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: AddChannelProps) => {
      return axios.post(BASE_URL + "/feed/" + params.feedId + "/channel", params.channelParams)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["feeds", "feed"] })
    },
  })
}
// Add channel to feed - End

// Get telegram folder link info - Begin
export async function getFolderChats(folderUrl: string): Promise<ChannelModel[]> {
  const { data } = await axios.get(BASE_URL + "/feeds/folder?url=" + folderUrl);
  return data.channels as ChannelModel[];
}
// Get telegram folder link info - End


// Post feedback - Begin
export function usePostFeedback() {
  return useMutation({
    mutationFn: (feedbackProps: FeedbackPostProps) => {
      const {feedback, os, browser, screen_height, screen_width, images} = feedbackProps;
      const formData = new FormData();
      formData.append('feedback', feedback);
      formData.append('os', os);
      formData.append('browser', browser);
      formData.append('screen_width', screen_width);
      formData.append('screen_height', screen_height);
      images.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });
      return axios.post(BASE_URL + "/feedback", formData)
    }
  })
}
// Post feedback - End

function bearer() {
  if (process.env.REACT_APP_API_TEST_USER) {
    return btoa(`TEST:${process.env.REACT_APP_API_TEST_USER}`);
  }

  return btoa(webApp.initData);
}