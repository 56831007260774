import { BaseSyntheticEvent, FC, Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useChangeCountry, useRememberChannelWithoutFeeds, useSortedChannelsByDate, useSortedChannelsOption } from "../store";
import { FeedModel, ChannelModel, PLATFORM_IOS } from "../models/models";
import { 
	createPreviewImageFromTitle, 
	getSubscribers, 
	selectCategoryData
} from "../models/functions";
import { getFeeds, getInfiniteChannelsByCategory } from "../hooks/api";
import useOnScreen from "../hooks/useOnScreen";
import useDetectScrollPosition from "../hooks/useDetectScrollPosition";

import Error from '../components/fetch/Error';
import Htag from "../components/Htag";
import AddChannelModal from "../components/Modals/AddChannelModal";
import CategoryDetailPlaceholder from "../components/Preloaders/CategoryDetailPlaceholder";
import Load from "../components/fetch/Load";
import NotificationModal from "../components/Modals/NotificationModal";
import WithoutFeedsModal from "../components/Modals/WithoutFeedsModal";
// import SortedChannelsHeader from "../components/LibraryScreen/SortedChannelsHeader";

import { ReactComponent as AddIcon } from "../icons/plus-icon.svg"

import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Placeholder from 'react-bootstrap/Placeholder';

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import "../styles/LibrariesList.css"
import "../styles/CategoryDetail.css"

const CategoryDetail: FC = () => {

	const { t } = useTranslation();

	const params = useParams();
	const categoryId = params.categoryId as unknown as number;
	const location = useLocation();
	const navigate = useNavigate();

	const { rememberChannelId } = useRememberChannelWithoutFeeds();
	const { date: channelsPeriod } = useSortedChannelsByDate();
	const { option } = useSortedChannelsOption();
	const { countryCodeStore } = useChangeCountry();
	
	const [pageImageLoaded, setPageImageLoaded] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showModalWithoutFeeds, setShowModalWithoutFeeds] = useState<boolean>(false);
	const [selectedChannel, setSelectedChannel] = useState<ChannelModel | null>(null);
	const [addChannelStatus, setAddChannelStatus] = useState<string>("");
	const [transparentPageImage, setTransparentPageImage] = useState<boolean>(false);

	const onHide = () => {
		setShowModal(false)
	};

	const onHideWithoutFeeds = () => {
		setShowModalWithoutFeeds(false)
	};

	const scrollPosition = useDetectScrollPosition();

	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		isPending,
		isError,
		isRefetching,
		refetch
	} = useInfiniteQuery({
		queryKey: ["infinite-categories", `${option}&${channelsPeriod}&${categoryId}&${countryCodeStore}`],
		queryFn: ({ pageParam }) => getInfiniteChannelsByCategory({
			categoryId: categoryId,
			page: pageParam,
			by: option,
			direction: "desc",
			country: countryCodeStore,
			period: channelsPeriod
		}),
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			if (lastPage.channels.length === 0) {
				return undefined
			}
			return lastPageParam + 1
		}
	});

	const { data: feeds } = useQuery<FeedModel[], Error>({
		queryKey: ["feeds"],
		queryFn: getFeeds,
	});

	const elementRef = useRef<HTMLDivElement>(null);
	const isOnScreen = useOnScreen(elementRef, [feeds, scrollPosition]);

	const goBack = () => {
		navigate(-1)
	}

	useEffect(() => {
		if (!PLATFORM_IOS) return;
		if (scrollPosition > 100) {
			setTransparentPageImage(true);
		} else {
			setTransparentPageImage(false);
		}
	}, [scrollPosition]);

	useEffect(() => {
		refetch();
	}, [channelsPeriod, option]);

	useEffect(() => {
		if (isOnScreen && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	}, [isOnScreen]);

	if ((!data || isPending) && !pageImageLoaded) return <CategoryDetailPlaceholder />;
	if (isError) return <Error message={error.message} />;

	const { name, code } = location.state;
	const availabilityFeeds = feeds && feeds.length > 0;

	return (
		<>
			<div className="category-detail-page-wrapper">

				<div
					style={PLATFORM_IOS && transparentPageImage ? { opacity: "0" } : {}}
					className="category-detail-image-wrapper border-only_bottom-radius">
					<img
						alt=""
						style={pageImageLoaded ? {} : { display: "none" }}
						className="category-detail-image"
						src={selectCategoryData(code).pageImage}
						onLoad={() => setPageImageLoaded(true)} />


					{!pageImageLoaded &&
						<Placeholder as="div" animation="glow" className="category-detail-card-placeholder-banner-wrapper">
							<Placeholder xs={1} bg="secondary" className="bg-placeholder-secondary" />
						</Placeholder>
					}

					<Htag textAlign="left" tag="h4">{name}</Htag>

				</div>

				{
					pageImageLoaded ? (
						<section className="background-list-item border-0 category-detail-list-wrapper libraries-list-card">

							{/* <SortedChannelsHeader /> */}

							{
								data && data.pages[0].channels.length === 0 && (
									<p className="category-detail-epmty-content">{t("Empty - no channels found")}</p>
								)
							}

							{
								isRefetching && option === "created_at" && (
									<div className="loaded-spinner">
										<Load />
									</div>
								)
							}

							{
								data && data.pages && (
									<ListGroup
										style={isRefetching ? { opacity: "0", height: "0px" } : {}}
										className="background-list-item libraries-list-group category-detail-list-group">
										{data.pages.map((page: any, index) => (
											<Fragment key={index}>
												{
													page.channels.map((item: ChannelModel) => {
														const { id, image100, title, participants } = item;

														const onShow = (event: BaseSyntheticEvent) => {
															event.stopPropagation()
															setSelectedChannel(item)
															setShowModal(true)
														}

														const onShowWithoutFeeds = (event: BaseSyntheticEvent) => {
															event.stopPropagation()
															rememberChannelId(item.id)
															setShowModalWithoutFeeds(true)
														}

														return (
															<ListGroup.Item
																key={id}
																onClick={() => navigate("/library/channel/" + id, { replace: false, state: { channel: item } })}
																className="background-list-item libraries-list-channel-item">

																{
																	image100
																		? (
																			<Image
																				src={image100}
																				alt={title}
																				roundedCircle />
																		)
																		: (
																			<div className="list-item-no-image-wrapper">
																				<div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
																			</div>
																		)
																}

																<div className="channel-item-description-wrapper border-bottom">
																	<div className="channel-item-description-column custom-wrap-text-ellipsis">
																		<p className="channel-item-title custom-wrap-text-ellipsis text-color">{title}</p>
																		<p className="channel-item-participants">{getSubscribers(participants)}</p>
																	</div>

																	<div onClick={availabilityFeeds ? onShow : onShowWithoutFeeds} className="channel-item-svg-wrapper">
																		<AddIcon />
																	</div>
																</div>

															</ListGroup.Item>
														)
													})
												}
											</Fragment>
										)
										)}
									</ListGroup>
								)
							}

							<div ref={elementRef} />

							{
								!isRefetching && isFetching && (
									<div className="loaded-spinner">
										<Load />
									</div>
								)
							}
						</section>
					)
						: <CategoryDetailPlaceholder />
				}

			</div >

			{
				availabilityFeeds && (
					<AddChannelModal
						show={showModal}
						onHide={onHide}
						telegramId={selectedChannel?.telegram_id}
						channelId={selectedChannel?.id}
						setAddChannelStatus={setAddChannelStatus}
						parentMainButtonFunction={goBack}
					/>
				)
			}

			{
				!availabilityFeeds && showModalWithoutFeeds && <WithoutFeedsModal onHide={onHideWithoutFeeds} />
			}


			{addChannelStatus === "success" && (
				<NotificationModal text={`${t("Channel")} ${selectedChannel?.title} ${t("Successfully added")}`} />
			)}
		</>
	)
};

export default CategoryDetail;