import { FC, useEffect, useState } from "react";

import { APP_COLOR_HINT, MODAL_BG_COLOR_DARK, THEME_DARK, webApp } from "../../../models/models";
import {  useProductTour_AddChannelFromFeed } from "../../../store";

import Step1 from "./Steps/Step1";
import Tooltip from "../Tooltip";

import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

import "../../../styles/ProductTour.css"

const ProductTourAddChannelFromFeed: FC = () => {

	const { statusProductTour, updateStatusProductTour } = useProductTour_AddChannelFromFeed();

	const [runTour] = useState<boolean>(statusProductTour);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [steps] = useState<Step[]>([
		{
			title: "Выбрать из Каталога",
			content: <Step1 />,
			target: "#product_tour_add-channel-from-feed_catalog",
			disableBeacon: true,
		}
	  ]);

	  useEffect(() => {
		webApp.MainButton.disable();
		webApp.MainButton.color = APP_COLOR_HINT;
		
		return () => {
			webApp.MainButton.enable();
		}
	  }, [currentStep])

	  const handleJoyrideCallback = (data: CallBackProps) => {
		const { status, index } = data;
		const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

		setCurrentStep(index + 1)

		if(THEME_DARK) {
			window.document.querySelector(".react-joyride__overlay")?.classList.add("spotlight_dark_theme")
			window.document.querySelector(".react-joyride__spotlight")?.classList.add("react-joyride__spotlight_dark_theme")
		}

		if (finishedStatuses.includes(status)) {
			updateStatusProductTour(false);
		};
	  }  

	return (
		<Joyride
			disableOverlayClose
			disableScrollParentFix
			disableCloseOnEsc
			tooltipComponent={Tooltip}
			callback={handleJoyrideCallback}
			continuous
			hideCloseButton
			run={runTour}
			scrollToFirstStep
			showProgress
			steps={steps}
			styles={{
				options: {
					arrowColor: MODAL_BG_COLOR_DARK,
					zIndex: 20,
				},
				spotlight: {
					backgroundColor: THEME_DARK ? APP_COLOR_HINT : "#ffffff"
				}
			}}
		/>
	)
}

export default ProductTourAddChannelFromFeed;