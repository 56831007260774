import { FC, Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { MetaDataCategory, ChannelModel } from "../../models/models";
import { getSubscribers } from "../../models/functions";
import { useBlogsSliderPositionX, useChangeCountry } from "../../store";

import { getInfiniteChannelsByCategory } from "../../hooks/api";
import useOnScreen from "../../hooks/useOnScreen";

import Load from "../fetch/Load";
import Htag from "../Htag";
import Error from '../fetch/Error';
import BlogsSliderPlaceholder from "../Preloaders/BlogsSliderPlaceholder";

import { ReactComponent as AddIcon } from "../../icons/plus-icon_bg-white.svg"

import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

import { useInfiniteQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import "../../styles/BlogsSlider.css"

const BlogsSider: FC<{
	category: MetaDataCategory,
}> = ({ category }) => {

	const { t } = useTranslation();

	const navigate = useNavigate();

	const { xCoord, updateXCoord } = useBlogsSliderPositionX();
	const { countryCodeStore } = useChangeCountry();

	const { id: categoryId, code: categoryCode, name: categoryName } = category;

	const navigateParams = {
		name: categoryName,
		code: categoryCode
	};

	const [scrollLeftPosition, setScrollLeftPosition] = useState<number>(0);

	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		isError,
		isPending
	} = useInfiniteQuery({
		queryKey: ["infinite-categories", `categoryId=${categoryId}&country=${countryCodeStore}`],
		queryFn: ({ pageParam }) => getInfiniteChannelsByCategory({
			categoryId: categoryId,
			page: pageParam,
			by: "participants",
			direction: "desc",
			country: countryCodeStore,
			period: ""
		}),
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			if (lastPage.channels.length === 0) {
				return undefined
			}
			return lastPageParam + 1
		}
	});

	const sliderRef = useRef<HTMLDivElement>(null);

	const elementRef = useRef<HTMLDivElement>(null);
	const isOnScreen = useOnScreen(elementRef, [scrollLeftPosition]);

	useLayoutEffect(() => {
		if (!xCoord) return;
		if (sliderRef.current) {
			sliderRef.current.scrollTo({ top: 0, left: xCoord, behavior: "instant" });
			setTimeout(() => updateXCoord(0), 0)
		}
	}, [])

	useEffect(() => {
		if (isOnScreen && !isFetchingNextPage) {
			fetchNextPage()
		}
	}, [hasNextPage, isFetchingNextPage, isOnScreen]);

	if (isPending) return <BlogsSliderPlaceholder />
	if (isError) return <Error message={error.message} />;
	if (!data) return <></>;

	const items = data.pages && data.pages.map((page: any, index) => (
		<Fragment key={index}>
			{
				page.channels.map((item: ChannelModel) => {
					const { id, image640, title, participants } = item;
					return (
						<div
							className="blogs-slider-item-wrapper"
							key={id}
							onClick={() => {
								navigate("/library/channel/" + id, { replace: false, state: { channel: item } });
								sliderRef.current && updateXCoord(sliderRef.current?.scrollLeft)
							}}>
							<div className="blogs-slider-item">

								<Image
									className="blogs-slider-item-img"
									src={image640}
									alt={title}
								/>

								<div className="blogs-slider-item-description text-color">

									<div className="blogs-slider-item-description-content">

										<div className="blogs-slider-item-description-text">
											<p className="blogs-slider-item-description-text-title custom-wrap-text-ellipsis">
												{title}
											</p>
											<p className="blogs-slider-item-description-text-subtitle custom-wrap-text-ellipsis">
												{getSubscribers(participants)}
											</p>
										</div>

										<div className="blogs-slider-item-description-icon">
											<AddIcon />
										</div>

									</div>

								</div>
							</div>
						</div>
					)
				})
			}
		</Fragment>
	))

	return (
		<div className="blogs-slider-wrapper">

			<div className="blogs-slider-title">
				<Htag tag="h4" textAlign="left">{t("Blogs")}</Htag>

				<Button
					onClick={() => navigate("/library/categories/" + categoryId, { replace: false, state: navigateParams })}
					className="blogs-slider-title-button"
					variant="link">
					{t("All")}
				</Button>
			</div>

			<div
				ref={sliderRef}
				className="blogs-slider disable-scrollbar"
				onTouchEnd={() => { setScrollLeftPosition(sliderRef.current?.scrollLeft || 0) }}>

				{items}

				<div ref={elementRef} />

				{
					isFetching && (
						<div className="loaded-spinner_slider">
							<Load />
						</div>
					)
				}
			</div>

		</div>
	);
};

export default BlogsSider;