import { useState } from "react";

import { ChannelIconProps } from "../models/models";
import { createPreviewImageFromTitle } from "../models/functions";

import Image from 'react-bootstrap/Image';

const ChannelIcon = ({img, title, searchChannelList = false}: ChannelIconProps) => {
	
    const [channelImage, setChannelImage] = useState<boolean>(false);

    return (
        <>
            {
                img
                    ? (
						<Image
                            onLoad={() => setChannelImage(true)}
                            style={channelImage ? {} : { display: "none" }}
                            className={`custom-list-wrapper-item-image ${searchChannelList ? "search-channel-list-item-image" : ""}`}
                            src={img}
                            alt={createPreviewImageFromTitle(title)}
                            roundedCircle 
                        />
					)
                    : (
						<div className="list-item-no-image-wrapper">
							<div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
						</div>
					)
            }

            {
                !channelImage && img && (
                    <div className="list-item-no-image-wrapper">
                        <div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
                    </div>
                )
            }
        </>
    )
}

export default ChannelIcon;