import { useNavigate } from "react-router-dom";
import { WithoutFeedsModalProps } from "../../models/models";

import { useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { useTranslation } from "react-i18next";

const WithoutFeedsModal = ({ onHide }: WithoutFeedsModalProps) => {
	const showPopup = useShowPopup();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleShowCreateFeedModal = () => {
		showPopup({
			title: t("Create Feed"),
			buttons: [
				{ id: "create_feed-no", type: 'cancel' },
				{ id: "create_feed-yes", type: 'ok' }
			],
			message: t("Create feed modal - Text")
		})
			.then(id => id === "create_feed-yes" ? navigate("/feeds/create") : onHide());
	};

	handleShowCreateFeedModal()

	return (
		<></>
	)
}

export default WithoutFeedsModal;