import { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useDeleteFeed } from "../hooks/api";
import { FeedModel, PLATFORM_IOS, PLATFORM_ANDROID, PLATFORM_WEB, webApp, MAX_LENGTH_FEEDS, APP_COLOR_HINT } from '../models/models';
import { createPreviewImageFromTitle, openTelegramLink } from "../models/functions";

import { ReactComponent as ShareIcon } from "../icons/share.svg";

import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';

import ChannelIcon from "./ChannelIcon";

import { useShowPopup } from '@vkruglikov/react-telegram-web-app';
import { useTranslation } from "react-i18next";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';

import 'react-swipeable-list/dist/styles.css';

import "../styles/Lists.css";

const FeedsList: FC<
    {
        feeds: FeedModel[],
        onClick?: (item: FeedModel) => void,
        clickedSubscribeButton: boolean,
        setClickedSubscribeButton: (state: boolean) => void;
    }
> = ({ 
        feeds,
        onClick,
        clickedSubscribeButton,
        setClickedSubscribeButton
    }) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [selectedFeed, setSelectedFeed] = useState<FeedModel | null>(null);
    const [dragProgressState, setDragProgressState] = useState<number>(0);
    const [deletedFeeds, setDeletedFeeds] = useState<{ id: number }[]>([]);
    const [verifiedForSubscriptionFeeds, setVerifiedForSubscriptionFeeds] = useState<number[]>([]);

    useEffect(() => {
        if(clickedSubscribeButton) return;
        setVerifiedForSubscriptionFeeds([])
    }, [clickedSubscribeButton])

    const checkFeedVerified = (feedId: number) => {
        return verifiedForSubscriptionFeeds.find(id => id === feedId)
    }
    
    const { mutate: deleteFeedMethod } = useDeleteFeed();

    const deleteFeed = (feed: FeedModel) => {
        if(feeds.length >= MAX_LENGTH_FEEDS) {
            webApp.MainButton.disable();
            webApp.MainButton.text = t("Feed deleted")
            webApp.MainButton.color = APP_COLOR_HINT;
        }
        deleteFeedMethod(feed.id);
        feed && setDeletedFeeds([...deletedFeeds, { id: feed.id }]);
        showModal_afterDeleteFeed(feed);
    }

    const showPopup = useShowPopup();

    const showModal_deleteFeed = () => {
        showPopup({
            buttons: [
                { id: "delete_feed-no", type: 'cancel'},
                { id: "delete_feed-yes", type: 'destructive', text: t("Delete") }
            ],
            message: t("Want to delete feed")
        })
            .then(id => id === 'delete_feed-yes' && selectedFeed && deleteFeed(selectedFeed));
    }

    const showModal_afterDeleteFeed = (feed:FeedModel) => {
        const selectButtonsToShow = () => {
            return [
                { id: "delete_feed-info_message", type: "ok" }
            ]
        }
        showPopup({
            buttons: selectButtonsToShow(),
            title: `${t("Feed")} «${feed.title}» ${t("Deleted")}`,
            message: `${t("In Telegram channel")} «${feed.title}» ${t("Messages from Telegrok bot will no longer be published")}.`
        })
    }

    const leftSwipe = (feedUrl: string) => (
        <LeadingActions >
            <SwipeAction
                onClick={() => openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(feedUrl)}`)}>
                <div className="feeds-list-right-swipe swipe-wrapper">
                    <ShareIcon />
                </div>
            </SwipeAction>
        </LeadingActions>
    );

    const rightSwipe = () => (
        <TrailingActions>
            <SwipeAction
                onClick={showModal_deleteFeed}>
                <div className="feeds-list-left-swipe swipe-wrapper">
                    <p className="text-white m-auto p-1 fs-1">&#215;</p>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    const chooseIconsWidth = (count: number) => {
        if (count === 6 || count === 5 || count === 4 || count > 6) {
            return { width: 144, minWidth: 144, maxWidth: 144 }
        } else if (count === 3) {
            return { width: 120, minWidth: 120, maxWidth: 120 }
        } else if (count === 2) {
            return { width: 80, minWidth: 80, maxWidth: 80 }
        } else if (count === 1) {
            return { width: 40, minWidth: 40, maxWidth: 40 }
        } else return {}
    }

    const selectOffset = (count: number, index: number) => {
        if (count === 6) {
            return { zIndex: 8 - index, right: index * 21 }
        } else if (count === 5 || count > 6) {
            return { zIndex: 8 - index, right: index * 26 }
        } else if (count === 4) {
            return { zIndex: 8 - index, right: index * 35 }
        } else if (count === 3 || count === 2) {
            return { zIndex: 8 - index, right: index * 40 }
        } else if (count === 1) {
            return { zIndex: 8 - index, right: 0 }
        } else return {}
    }

    return (
        <ListGroup className="custom-list-wrapper background-list-item">
            <SwipeableList
                threshold={0.25}
                scrollStartThreshold={0.5}
                swipeStartThreshold={0.5}
                fullSwipe={false}
                type={ListType.IOS}>

                {feeds.map((item, index) => {
                    const { id, invite_url, title: feedTitle, channels, status } = item;
                    const shortedChannels = channels?.slice(0, 6);
                    const isBlockedWhenSubsribing = clickedSubscribeButton && !checkFeedVerified(id);

                    const onClickItem = (item: FeedModel) => {
                        if(clickedSubscribeButton && checkFeedVerified(id)) return;

                        if (onClick) {
                            onClick(item)
                        } else if ((!PLATFORM_ANDROID || !PLATFORM_IOS) && dragProgressState) {
                            return undefined
                        } else {
                            setClickedSubscribeButton(false);
                            navigate("/feeds/details/" + item.id, {replace: false, state: {feedStatus: status}});
                        }
                    }

                    const onSubscribeClick = (e:BaseSyntheticEvent) => {
                        e.stopPropagation();
                        if(!isBlockedWhenSubsribing) {
                            (PLATFORM_WEB || PLATFORM_IOS) && setClickedSubscribeButton(true);
                            (PLATFORM_WEB || PLATFORM_IOS) && setVerifiedForSubscriptionFeeds([...verifiedForSubscriptionFeeds, id]);
                            openTelegramLink(item.invite_url);
                        }
                    }

                    if (deletedFeeds.find((feed) => feed.id === id)) return <></>;

                    return (
                        <SwipeableListItem
                            maxSwipe={0.5}
                            onSwipeStart={() => setSelectedFeed(item)}
                            onSwipeEnd={() => setTimeout(() => setDragProgressState(0), 0)}
                            onSwipeProgress={(progress: number) => setDragProgressState(progress)}
                            leadingActions={leftSwipe(invite_url)}
                            trailingActions={rightSwipe()}
                            key={id}
                        >

                            <ListGroup.Item
                                className="custom-list-wrapper-item background-list-item"
                                onClick={() => onClickItem(item)}
                                id={index === 0 ? "product_tour_swipe-feed" : " "}
                            >

                                <div className="list-item-no-image-wrapper">
                                    <div className="list-item-no-image">{createPreviewImageFromTitle(feedTitle)}</div>
                                </div>

                                <div className="custom-list-wrapper-title custom-wrap-text-ellipsis">
                                    <div className="custom-list-wrapper-feed-title">
                                        <p className="text-color custom-wrap-text-ellipsis">{feedTitle}</p>
                                        {
                                            status !== "transferred"
                                                ? (
                                                    <div>
                                                        {
                                                            status === "transferring"
                                                            ? (                 
                                                                <p className="custom-list-wrapper-feed-title-unsubscribe text-hint">
                                                                    {t("Subscribing")}
                                                                </p>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        clickedSubscribeButton && checkFeedVerified(id)
                                                                            ? (
                                                                                <p className="custom-list-wrapper-feed-title-unsubscribe text-hint">
                                                                                    {t("Just a moment")}
                                                                                </p>
                                                                            )
                                                                            : (
                                                                                <p
                                                                                    className={`
                                                                                        custom-list-wrapper-feed-title-unsubscribe
                                                                                        ${isBlockedWhenSubsribing ? "text-hint" : ""}
                                                                                    `}
                                                                                    onClick={(e) => onSubscribeClick(e)}
                                                                                >
                                                                                    {t("Subscribe")}
                                                                                </p>
                                                                            )
                                                                    }
                                                                </> 
                                                            )
                                                        }
                                                    
                                                    </div>                                                )
                                                : (
                                                    <>
                                                        {channels && shortedChannels && channels.length < 7
                                                            ? (
                                                                <div
                                                                    style={chooseIconsWidth(shortedChannels.length)}
                                                                    className="custom-list-wrapper-feed-title-list-images">
                                                                    {
                                                                        shortedChannels.map((channel, index) => {
                                                                            const { image100, id: channelId, title: channelTitle } = channel;

                                                                            return (
                                                                                <div
                                                                                    style={selectOffset(shortedChannels.length, index)}
                                                                                    className="custom-list-wrapper-feed-title-image"
                                                                                    key={channelId}
                                                                                >
                                                                                    <ChannelIcon
                                                                                        img={image100}
                                                                                        title={channelTitle}
                                                                                    /> 
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                            : channels && shortedChannels && (
                                                                <div
                                                                    style={chooseIconsWidth(shortedChannels.length)}
                                                                    className="custom-list-wrapper-feed-title-list-images">
                                                                    {
                                                                        shortedChannels.map((channel, index) => {
                                                                            const { image100: channelImage, id: channelId, title: channelTitle } = channel;

                                                                            if (index === 0) {
                                                                                return (
                                                                                    <div
                                                                                        style={selectOffset(shortedChannels.length, index)}
                                                                                        className="custom-list-wrapper-feed-title-image"
                                                                                        key={channelId}>
                                                                                        <div className="list-item-no-image-wrapper list-item-no-image-wrapper-channels">
                                                                                            <div className="list-item-no-image">+{channels.length - (shortedChannels.length - 1)}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            return (
                                                                                <div
                                                                                    style={selectOffset(shortedChannels.length, index)}
                                                                                    className="custom-list-wrapper-feed-title-image"
                                                                                    key={channelId}>
                                                                                    {channelImage
                                                                                        ? (
                                                                                            <Image
                                                                                                roundedCircle
                                                                                                src={channelImage}
                                                                                                alt={createPreviewImageFromTitle(channelTitle)} />
                                                                                        )
                                                                                        : (
                                                                                            <div className="list-item-no-image-wrapper">
                                                                                                <div className="list-item-no-image">{createPreviewImageFromTitle(channelTitle)}</div>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>

                            </ListGroup.Item>

                        </SwipeableListItem>
                    )
                })}

            </SwipeableList>
        </ListGroup>
    );
};

export default FeedsList;