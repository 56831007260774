import { BaseSyntheticEvent, FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { getFeed, getFeeds, useEditFeed } from "../hooks/api";
import { APP_COLOR_HINT, FeedModel, MAX_LENGTH_FEED_NAME, webApp } from "../models/models";
import { 
  createPreviewImageFromTitle, 
  showLimitFeedNameValueLength, 
  showMainTelegramButton, 
  unmountedTelegramButton, 
  checkDuplicateFeed 
} from "../models/functions";

// import NotificationModal from "../components/Modals/NotificationModal";
// import CustomDropdown from "../components/CustomDropdown";
import CloseIcon from "../components/CloseIcon";

import { ReactComponent as ArrowIcon } from "../icons/arrow.svg"
// import { ReactComponent as GlobeIcon } from "../icons/globe.svg"

// import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { ListGroup } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import "../styles/FeedDetailsSettings.css"

const FeedDetailsSettings: FC = () => {

  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
		data: feeds
  } = useQuery<FeedModel[], Error>({
		queryKey: ["feeds"],
		queryFn: getFeeds,
		refetchOnWindowFocus: false,
	});

  const defaultFeedName = state ? state.feedName : "";
  const feedId = state ? state.feedId : 0;
  const publicationMethod = state ? state.publicationMethod : "automatic";
  const feedsList = feeds || null;

  const { data: dataFeed } = useQuery<FeedModel, Error>({
		queryKey: ["feed", feedId],
		queryFn: () => getFeed(feedId),
	});

  const [feedNameState, setFeedNameState] = useState<string>(defaultFeedName);
  const [focusFeedName, setFocusFeedName] = useState<boolean>(false);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [publicationMethodState, setPublicationMethodState] = useState<"automatic" | "manual">(publicationMethod);
  // const [headersCheck, setHeadersCheck] = useState<boolean>(true);
  // const [contentCheck, setContentCheck] = useState<boolean>(false);
  // const [inputValue, setInputValue] = useState<string>("");
  // const [dropdownHours] = useState<string[]>([
  //   `1 ${t("hour")}`,
  //   `2 ${t("hours")}`,
  //   `3 ${t("hours")}`,
  //   `4 ${t("hours")}`,
  //   `8 ${t("hours's")}`,
  // ]);
  // const [selectedDropdownItem, setSelectedDropdownItem] = useState<string>(`1 ${t("hour")}`);
  // const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const duplicateFeed = feedsList && checkDuplicateFeed(feedsList, feedNameState, defaultFeedName);

  // const onChange = (event: BaseSyntheticEvent) => {
  //   setInputValue(event.target.value);
  // };

  const feedNameValidation = () => {
    return <div className="create-feed-form-status-icon">{showLimitFeedNameValueLength(feedNameState)}</div>
  }

  const checkFeedNameDuplicate = () => {
    if (duplicateFeed) {
      return <p className="text-danger request-info-subtitle">{t("Error - duplicate feed")}</p>
    }
  }

  const onClearInputIcon = () => {
    setFeedNameState("");
  }

  const onChangeFeedName = (event: BaseSyntheticEvent) => {
    setFeedNameState(event.target.value);
  };

  // const onHideDropdown = () => {
  //   setShowDropDown(false)
  // }

  // const toggleHeadersCheck = () => {
  //   setHeadersCheck(!headersCheck)
  // }
  // const toggleContentCheck = () => {
  //   setContentCheck(!contentCheck)
  // }

  const editFeedParams = {
    feedId: feedId,
    data: {
      title: feedNameState,
      // publication_method: publicationMethod
    }
  }

  const {
    mutate: editFeed,
    data,
    isPending
  } = useEditFeed();

	const updateFeedName = () => {
    if (feedNameState.length > MAX_LENGTH_FEED_NAME || duplicateFeed) return;

    if(defaultFeedName === feedNameState) {
      inputRef.current?.blur();
    }

    if (defaultFeedName !== feedNameState && !isPending && feedNameState) {
      editFeed(editFeedParams);
		  inputRef.current?.blur();
    }

    navigate(-1);
	}

  // useEffect(() => {
	// 	if(!dataFeed) return;
  //   setPublicationMethodState(dataFeed.publication_method)
	// }, [dataFeed])

	useEffect(() => {
		showMainTelegramButton(updateFeedName, t("Save"));
		return () => unmountedTelegramButton(updateFeedName);
	}, [])

  useEffect(() => {
    if (feedNameState && !duplicateFeed) {
      webApp.MainButton.enable();
      webApp.MainButton.color = webApp.themeParams.button_color;
      showMainTelegramButton(updateFeedName, t("Save"));
    } else {
      webApp.MainButton.disable();
      webApp.MainButton.color = APP_COLOR_HINT;
    }
		
		return () => unmountedTelegramButton(updateFeedName);
	}, [feedNameState])

  useEffect(() => {
    if (feedNameState.length > MAX_LENGTH_FEED_NAME || feedNameState.length === 0) {
      setFeedNameState(defaultFeedName)
    }
  }, [focusFeedName])

  useEffect(() => {
    if (!data) return;
    setFeedNameState(data.data.feed.title);
  }, [data])

  useEffect(() => {
    if (!data) return;
    if (feedNameState) return;
    if (focusFeedName) return;
    setFeedNameState(data.data.feed.title);
  }, [feedNameState, focusFeedName])

  useEffect(() => {
    if (focusFeedName) {
      inputRef.current?.focus({ preventScroll: true })
    }
  }, [focusFeedName])

  return (
    <section className="feed-details-settings-wrapper" onClick={() => { setShowDropDown(false); setFocusFeedName(false) }}>

      <div className="feed-details-settings-image-wrapper">
        <div className="feed-details-settings-image">
          {createPreviewImageFromTitle(defaultFeedName)}
        </div>

        {/* <p className="feed-details-settings-image-text">{t("Select photo")}</p> */}
      </div>

      <Form
        onSubmit={(e) => e.preventDefault()}
        className="feed-details-settings-form-wrapper"
        onClick={(e) => { e.stopPropagation(); setFocusFeedName(true) }}>
        <Form.Group
          controlId="feed-details-settings-form"
          className="feed-details-settings-form rounded">
          <Form.Control
            maxLength={MAX_LENGTH_FEED_NAME}
            autoComplete="off"
            autoFocus={true}
            onFocus={(e) => { e.preventDefault(); setFocusFeedName(true) }}
            onBlur={(e) => e.preventDefault()}
            type="text"
            as="input"
            className="text-color"
            placeholder={""}
            value={feedNameState[0] === " " ? feedNameState.substring(1) : feedNameState.replace(/\s+/g, ' ')}
            onChange={onChangeFeedName}
            ref={inputRef} />

          {
            feedNameValidation()
          }

          {
            feedNameState && focusFeedName && (
              <div className="clear-input-icon">
                <CloseIcon onClick={onClearInputIcon} />
              </div>
            )
          }
        </Form.Group>

        {
          checkFeedNameDuplicate()
        }

      </Form>

      {/* <ListGroup className="feed-details-settings-list"> */}

        {/* {publicationMethodState && (
            <ListGroup.Item 
              className="feed-details-settings-list-item pointer"
              onClick={() => navigate(`/feeds/${feedId}/settings/publication-method`, { state: { defaultPublicationMethod: publicationMethodState, feedId: feedId, feedTitle: defaultFeedName } })}
            >
              <div className="feed-details-settings-list-item-name">
                <div className="feed-details-settings-list-item-icon">💬</div>

                <p className="feed-details-settings-list-item-text">{t("Update Feed")}</p>
              </div>

              <div className="feed-details-settings-list-item-value">
                <p className="feed-details-settings-list-item-value-text">{t(publicationMethodState)}</p>

                <div className="feed-details-settings-list-item-value-icon svg-fill-hint">
                  <ArrowIcon width={15} height={15} />
                </div>
              </div>
          </ListGroup.Item>
        )} */}

          {/* <ListGroup.Item className="feed-details-settings-list-item">
            <div className="feed-details-settings-list-item-name">
              <div className="feed-details-settings-list-item-icon svg-fill-purple">
                <GlobeIcon />
              </div>

              <p className="feed-details-settings-list-item-text">{t("Show titles only")}</p>
            </div>

            <Form>
              <Form.Check type="switch" id="only-headers-switch" onChange={toggleHeadersCheck} checked={headersCheck} />
            </Form>
          </ListGroup.Item>

          <ListGroup.Item className="feed-details-settings-list-item">
            <div className="feed-details-settings-list-item-name">
              <div className="feed-details-settings-list-item-icon svg-fill-red">
                <GlobeIcon />
              </div>

              <p className="feed-details-settings-list-item-text">{t("Shorten content")}</p>
            </div>

            <Form>
              <Form.Check type="switch" id="content-switch" onChange={toggleContentCheck} checked={contentCheck} />
            </Form>
          </ListGroup.Item> */}


        {/* </ListGroup> */}

      {/* <Form className="feed-settings-digest-wrapper">
        <Form.Label className="feed-settings-digest-label">{t("Digest")}</Form.Label>

        <Form.Group 
          controlId="feed-settings-digest" 
          className="feed-settings-digest-form rounded border-bottom_after">
          <Form.Control
            type="text"
            as="textarea"
            placeholder={t("Enter the digest prompt here")}
            value={inputValue}
            onChange={onChange} />
        </Form.Group>
      </Form>

      <div className="feed-settings-digest-options-wrapper">

        <div className="feed-settings-digest-single-option-wrapper">
          <div className="feed-settings-digest-option border-bottom_after">
            <p className="feed-settings-digest-option-name text-color">{t("Digest Frequency")}</p>

            <button
              className={`feed-settings-digest-option-button-hour ${showDropDown && "text-primary"}`}
              onClick={(e) => { e.stopPropagation(); setShowDropDown(!showDropDown) }}>
              {selectedDropdownItem}
            </button>

            {showDropDown && (
              <CustomDropdown
                items={dropdownHours}
                activeItem={selectedDropdownItem}
                onHide={onHideDropdown}
                setSelectItem={setSelectedDropdownItem}
                rightPositon={"16px"}
                bottomPosition={"48px"}
                width={130}
              />
            )}

          </div>
        </div>

        <div className="feed-settings-digest-single-option-wrapper border-only_bottom-radius">
          <div className="feed-settings-digest-option">
            <p className="feed-settings-digest-option-name text-color">{t("Post Digest")}</p>

            <button
              onClick={() => setTimeout(() => setShowNotificationModal(!showNotificationModal), 1000)}
              className="feed-settings-digest-option-button-post">
              {t("Post")}
            </button>
          </div>
        </div>


      </div> */}

      {/* {showNotificationModal && (
        <NotificationModal text={t("Digest was posted to the feed")} />
      )} */}

    </section >
  );
};

export default FeedDetailsSettings;