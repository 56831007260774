import { BaseSyntheticEvent, useEffect, useState } from "react";
import { ReactComponent as CheckmarkWhite } from "../../icons/checkmark-white.svg";
import { ReactComponent as WarningWhite } from "../../icons/warning-white.svg";
import { NotificationModalProps } from "../../models/models";

import "../../styles/NotificationModal.css";

const NotificationModal = ({ text, icon = "checkmark", bottomMargin = false }: NotificationModalProps) => {

	const [hide, setHide] = useState<boolean>(false);
	const [showModalAnimation, setShowModalAnimation] = useState<boolean>(true);

	useEffect(() => {
		setTimeout(() => {
			setShowModalAnimation(false);
		}, 2000)
	}, [])

	const onHideModal = (e: BaseSyntheticEvent) => {
		e.stopPropagation();
		setHide(true);
	}

	if (!text) return <></>;

	const selectIcon = () => {
		switch (icon) {
			case "checkmark":
				return <CheckmarkWhite />
			case "warning":
				return <WarningWhite />
			default:
				return <CheckmarkWhite />
		}
	}

	return (
		<section
			onClick={!showModalAnimation ? (e) => onHideModal(e) : undefined}
			className={`
				notification-modal-wrapper 
				${bottomMargin ? "notification-modal-wrapper_bottom-margin" : ""}
				${hide ? "notification-modal-wrapper_hide" : ""}
			`}>
			<div className="notification-modal-content">

				<div className="notification-modal-icon">
					{selectIcon()}
				</div>

				<p className="notification-modal-text">
					{text}
				</p>

			</div>
		</section>
	)
}

export default NotificationModal;