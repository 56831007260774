import { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import { APP_COLOR_HINT, AddChannelProps, ChannelModel, FeedModel, webApp } from "../models/models";
import { 
  getSubscribers, 
  createPreviewLink, 
  createPreviewImageFromTitle, 
  unmountedTelegramButton_UI, 
  showMainTelegramButton_UI, 
  showMainTelegramButton, 
  unmountedTelegramButton, 
  checkDuplicateChannels, 
} from "../models/functions";
import { getFeed, getTelegramChannelLinkInfo, useAddChannel } from "../hooks/api";
import useDebounceInputRefetch from "../hooks/useDebounceInputRefetch";
import useChangeHeaderColorFirstLoad from "../hooks/useChangeHeaderColorFirstLoad";

import Htag from "../components/Htag";

import { ReactComponent as Link } from "../icons/link-transparent.svg"

import Form from 'react-bootstrap/Form';
import Image from "react-bootstrap/Image";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import "../styles/LinkToChannel.css"

const LinkToChannel: FC = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [channelImage, setChannelImage] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [feed] = useState<FeedModel>(location.state);

  const channelLinkValidate = (text: string) => {
    let modifiedText = text;

    if (modifiedText.startsWith("@")) {
      return modifiedText = "https://t.me/" + modifiedText.slice(1);
    } else if (!modifiedText.startsWith("https://")) {
      return modifiedText = "https://" + modifiedText;
    } else if (modifiedText.startsWith("https://") || modifiedText.startsWith("http://")) {
      return modifiedText
    } else if (!modifiedText.startsWith("t.me/")) {
      return modifiedText = "t.me/" + modifiedText;
    } else return modifiedText
  }

  const {
    data: channelData,
    isLoading: isLoadingChannelData,
    isError: isErrorChannelData,
    refetch: refetchChannelData,
  } = useQuery<ChannelModel, Error>({
    queryKey: ["channel", inputValue],
    queryFn: () => getTelegramChannelLinkInfo(encodeURIComponent(channelLinkValidate(inputValue))),
    enabled: false
  });

  const {
    data: dataFeed
  } = useQuery<FeedModel, Error>({
    queryKey: ["feed", feed.id],
    queryFn: () => getFeed(feed.id),
    initialData: () => feed,
    retry: 2
  });

  const { mutate: addChannel } = useAddChannel();

  const duplicateChannel = checkDuplicateChannels(dataFeed, channelData?.telegram_id || 0);

  const addChannelAndNavigate = (params: AddChannelProps, title: string, id: number) => {
    addChannel(params);
    navigate("/feeds/details/" + params.feedId, { replace: false, state: { channelName: title, channelId: id} });
  };

  const handleChange = (event: BaseSyntheticEvent) => {
    if (event.currentTarget.checkValidity()) {
      event.preventDefault();
      channelData && setValidated(true)
    } else {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false)
      setInputValue("")
    };
  };

  useDebounceInputRefetch(inputValue, refetchChannelData);
  useChangeHeaderColorFirstLoad();

  useEffect(() => {
    if (!feed.id) navigate("/");
  }, [feed.id])

  useEffect(() => {
    showMainTelegramButton_UI(t("Add"), APP_COLOR_HINT);
    return () => unmountedTelegramButton_UI();
  }, [])

  const paramsToAddChannel = {
    feedId: feed.id,
    channelParams: {
      channel_id: channelData?.id || 0
    }
  };

  const onClick = () => {
    addChannelAndNavigate(paramsToAddChannel, channelData?.title || "", channelData?.telegram_id || 0);
  }

  useEffect(() => {
    showMainTelegramButton(onClick, t("Add"));
    return () => unmountedTelegramButton(onClick);
  }, [])

  useEffect(() => {
    if(duplicateChannel && !channelData) return;
    showMainTelegramButton(onClick, t("Add"));
    return () => unmountedTelegramButton(onClick);
  }, [duplicateChannel, channelData])

  useEffect(() => {
    if(duplicateChannel) {
      webApp.MainButton.color = APP_COLOR_HINT;
      webApp.MainButton.text = t("Сhannel already been added");
      webApp.MainButton.disable();
    } else if(!channelData) {
      webApp.MainButton.color = APP_COLOR_HINT;
      webApp.MainButton.text = t("Add");
      webApp.MainButton.disable();
    } else {
      webApp.MainButton.color = webApp.themeParams.button_color;
      webApp.MainButton.text = t("Add");
      webApp.MainButton.enable();
    }
  }, [duplicateChannel, channelData, inputValue])

  const getChannelStatus = () => {
    if (isLoadingChannelData && !isErrorChannelData && inputValue) {
      return <p className="text-hint request-info-subtitle">{t("Checking link to channel")}</p>
    } else if (isErrorChannelData && inputValue) {
      return <p className="text-danger request-info-subtitle">{t("Invalid link to channel")}</p>
    } else return <></>
  }

  const ChannelInfo = () => {
    if (!channelData) return <></>;

    const { image100, title, participants, url } = channelData;

    return (
      <div
        className="link-to-channel-info background-list-item border-0 rounded pointer"
        onClick={() => navigate("/library/channel/" + paramsToAddChannel.channelParams.channel_id, { replace: false, state: { channel: channelData } })}>

        <Image
          onLoad={() => setChannelImage(true)}
          style={channelImage ? {} : { display: "none" }}
          src={image100}
          alt={createPreviewImageFromTitle(title)}
          roundedCircle
          className="link-to-channel-info-image" />

        {
          !channelImage && (
            <div className="list-item-no-image-wrapper">
              <div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
            </div>
          )
        }

        <div className="link-to-channel-info-text">
          <p className="link-to-channel-info-text-title custom-wrap-text-ellipsis text-color">{title}</p>
          <p className="link-to-channel-info-text-participants text-hint">
            {`${!url.includes("/joinchat") ? createPreviewLink(url) : ""} [${getSubscribers(participants)}]`}
          </p>
        </div>

      </div>
    )
  };

  return (
    <>
      <Htag tag="h3">{t("Add channel")} </Htag>

      <Form
        onSubmit={(e) => e.preventDefault()}
        validated={validated}>

        <div className="link-to-channel-wrapper">
          <Form.Text className="link-to-channel-specify-link text-hint text-uppercase">{t("Specify link")}</Form.Text>

          <Form.Group className="link-to-channel-input-wrapper rounded" controlId="link-to-channel_input">

            <div>
              <Link />
            </div>

            <Form.Control
              required
              autoFocus
              autoComplete='off'
              onChange={(event) => { setInputValue(event.target.value.trim()); handleChange(event) }}
              value={inputValue}
              className="link-to-channel-input border-0"
              name="url"
              type="text"
              placeholder={t("Link to channel")}
              maxLength={100}
            />

          </Form.Group>

          {getChannelStatus()}

          <ChannelInfo />

        </div>

      </Form>
    </>
  );
};

export default LinkToChannel;