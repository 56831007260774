import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  useDefaultFeedToAddChannel,
  useProductTour_AddChannelFromFeed,
  useProductTour_SwipeChannel,
  useRememberChannelWithoutFeeds,
  useSelectMethodToAddChannel
} from "../store";
import {
  APP_COLOR_HINT,
  FeedModel,
  MAX_CHANNELS_IN_FEED,
  PLATFORM_IOS,
  PLATFORM_WEB,
  THEME_DARK,
  webApp
} from "../models/models";
import { createPreviewImageFromTitle, openTelegramLink, setAppSecondaryColor, showMainTelegramButton_UI, unmountedTelegramButton_UI } from "../models/functions";
import { getFeed, useAddChannel } from "../hooks/api";
import useDebounceShowNotificationModal from "../hooks/useDebounceNotificationModal";
import useChangeHeaderColorInModal from "../hooks/useChangeHeaderColorInModal";

import ChannelsList from '../components/ChannelsList';
import Htag from "../components/Htag";
import NotificationModal from "../components/Modals/NotificationModal"
import PromptModal from "../components/Modals/PromptModal";
import Load from "../components/fetch/Load";
import ProductTourAddChannelFromFeed from "../components/ProductTour/AddChannelFromFeed";
import ProductTourSwipeChannel from "../components/ProductTour/SwipeChannel";

import { ReactComponent as LibraryIcon } from "../icons/book.svg"
import { ReactComponent as LinkIcon } from "../icons/link.svg"
import { ReactComponent as FromSubscriptionsIcon } from "../icons/add-channel_from-my-subscriptions.svg"
import { ReactComponent as CheckMarkIcon } from "../icons/checkmark.svg"

import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import { ReactComponent as ShareIcon } from "../icons/share.svg";

import { useTranslation } from "react-i18next";
import {  useExpand, BackButton } from "@vkruglikov/react-telegram-web-app";
import { useQuery } from "@tanstack/react-query";

import "../styles/FeedDetails.css"

const FeedDetails: FC = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [isExpanded, expand] = useExpand();

  const { channelId, rememberChannelId } = useRememberChannelWithoutFeeds();
  const { method, changeMethod } = useSelectMethodToAddChannel();
  const { updateFeed } = useDefaultFeedToAddChannel();
  const { statusProductTour: statusProductTour_AddChannelFromFeed } = useProductTour_AddChannelFromFeed();
  const { statusProductTour: statusProductTour_SwipeChannel } = useProductTour_SwipeChannel();

  const [channelNameState] = useState<string>(location.state && location.state.channelName ? location.state.channelName : "");
  const [channelTelegramIdState] = useState<number>(location.state && location.state.channelId ? location.state.channelId : 0);
  const [pageFromState, setPageFromState] = useState<string>(location.state && location.state.pageFrom ? location.state.pageFrom : "");
  const [feedStatusState] = useState<string>(location.state && location.state.feedStatus ? location.state.feedStatus : "");
  const [isRenderingAddedChannel, setIsRenderingAddedChannel] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showNotificationLimitChannelsModal, setShowNotificationLimitChannelsModal] = useState<boolean>(false);
  const [showNotificationSubscribeFeed, setShowNotificationSubscribeFeed] = useState<boolean>(false);
  const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
  const [clickedSubscribeButton, setClickedSubscribeButton] = useState<boolean>(false);
  const [refetchCount, setRefetchCount] = useState<number>(0);
  const [showSwipeChannelTour, setShowSwipeChannelTour] = useState<boolean>(false);
  const [movingChannelState, setMovingChannelState] = useState<boolean>(false);

  const simulateClick = useRef<HTMLDivElement>(null);
  const simulateClickAddChannelMethod = useRef<HTMLDivElement>(null)

  const params = useParams();
  const feedId = params.feedId as unknown as number;

  const fromPageSubsribe = pageFromState === "subscribe";
  const fromChannelFeed = pageFromState === "channelFeed";
  
  const {
    data: dataFeed,
    refetch,
    isLoading: isLoadingFeed,
    isError: isErrorFeed,
    isRefetchError: isRefetchErrorFeed
  } = useQuery<FeedModel, Error>({
    queryKey: ["feed", feedId],
    queryFn: () => getFeed(feedId),
    refetchInterval: () => fromPageSubsribe || clickedSubscribeButton || isRenderingAddedChannel ? 500 : false,
    notifyOnChangeProps: ["data", "error"],
    refetchOnWindowFocus: false,
    retry: 2
  });

  const notSubscribe = dataFeed?.status !== "transferred";
  const subscribing = dataFeed?.status === "transferring";

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const onSettingsClick = () => {
    simulateClick.current?.click();
  };

  const unmountedComponent = () => {
    // @ts-ignore
    webApp.SettingsButton.offClick(() => onSettingsClick());
    // @ts-ignore
    webApp.SettingsButton.hide();
  }

  const { mutate: addChannel } = useAddChannel();

  const selectMethodToAddChannel = (selectMethod: boolean, selectedButton: string) => {
    if (selectMethod) {
      changeMethod(selectedButton);
    }
    if (selectedButton === "library" && dataFeed) {
      updateFeed(dataFeed);
    } else if (selectedButton === "link-to-channel" && dataFeed) {
      updateFeed(dataFeed);
    }
    
    setTimeout(() => {
      if(selectedButton === "link-to-channel") {
        navigate(`/${selectedButton}`, { replace: false, state: dataFeed })
      } else if(selectedButton === "library") {
        navigate(`/${selectedButton}`, { replace: false, state: feedId })
      } else if(selectedButton === "add-channel-from-subscriptions") {
        navigate(`/${selectedButton}`)
      }
    }, (0));
    setAppSecondaryColor();
  }

  useDebounceShowNotificationModal(showNotificationLimitChannelsModal, setShowNotificationLimitChannelsModal)
  useChangeHeaderColorInModal(showModal);
  useChangeHeaderColorInModal(showSwipeChannelTour);

  useEffect(() => {
    if(!dataFeed || !dataFeed.channels || statusProductTour_AddChannelFromFeed || !statusProductTour_SwipeChannel || showModal) return;

    if(dataFeed.channels.length > 0) {
      setShowSwipeChannelTour(true);
    }
    
    return () => {
      setShowSwipeChannelTour(false);
    }
  }, [dataFeed, statusProductTour_AddChannelFromFeed, statusProductTour_SwipeChannel, showModal])

  useEffect(() => {
    if(showSwipeChannelTour || notSubscribe) return;
    webApp.MainButton.text = t("Add channel");

    if(dataFeed && dataFeed.channels) {
      if(dataFeed.channels.length >= MAX_CHANNELS_IN_FEED) {
        webApp.MainButton.color = APP_COLOR_HINT;
      } else {
        webApp.MainButton.color = webApp.themeParams.button_color;
      }
    }
  }, [showSwipeChannelTour, notSubscribe])

  useEffect(() => {
    if(showSwipeChannelTour && showModal) {
      handleClose();
    }
  }, [showModal])

  useEffect(() => {
    if(!statusProductTour_AddChannelFromFeed || notSubscribe) return;
    handleShow();
  }, [isLoadingFeed])

  useEffect(() => {
    if(refetchCount >= 5 && !subscribing) {
      setClickedSubscribeButton(false);
      webApp.MainButton.text = t("Subscribe");
      webApp.MainButton.hideProgress();
      webApp.MainButton.enable();
      webApp.MainButton.color = webApp.themeParams.button_color;
      setRefetchCount(0);
    }
  }, [refetchCount])

  useEffect(() => {
    const refetchCountInterval = setInterval(() => {
      setRefetchCount(refetchCount => refetchCount + 1)
    }, 1000)

    if (!clickedSubscribeButton) {
      clearInterval(refetchCountInterval)
    }

    return () => clearInterval(refetchCountInterval)    
  }, [clickedSubscribeButton])

  // render & watch MainButton - start
  const onSimulateClick = () => {
    simulateClickAddChannelMethod.current?.click();
  }

  const onOpenLink = () => {
    openTelegramLink(invite_url);
    if(PLATFORM_IOS || PLATFORM_WEB) {
      setClickedSubscribeButton(true);
      webApp.MainButton.color = APP_COLOR_HINT;
      webApp.MainButton.disable();
      webApp.MainButton.text = t("Just a moment");
    }
  }

  const onShowLimitChannels = () => {
    setShowNotificationLimitChannelsModal(true);
  }

  useEffect(() => {
    if(method) {
      webApp.MainButton.enable();
      webApp.MainButton.color = webApp.themeParams.button_color;
    } else {
      webApp.MainButton.disable();
      webApp.MainButton.color = APP_COLOR_HINT;
    }
  }, [method, showModal])

  useEffect(() => {
    if(!isRefetchErrorFeed) return;
    setClickedSubscribeButton(false);
  }, [isRefetchErrorFeed])

  useEffect(() => {
    if(fromChannelFeed && isLoadingFeed) {
      webApp.MainButton.hide();
      return
    } else {
      webApp.MainButton.show();
    }

    if(subscribing) {
      webApp.MainButton.disable();
      webApp.MainButton.showProgress();
      webApp.MainButton.color = APP_COLOR_HINT;
      webApp.MainButton.text = t("Subscribing");
    } else {
      webApp.MainButton.enable();
      webApp.MainButton.hideProgress();
      webApp.MainButton.color = webApp.themeParams.button_color;
        if(notSubscribe && feedStatusState !== "transferred") {
          webApp.MainButton.text = t("Subscribe");
        } else {
          webApp.MainButton.text = t("Add channel");
        }
      setClickedSubscribeButton(false);
    }
  }, [subscribing, isLoadingFeed])

  useEffect(() => {
    if(showMoveModal || !dataFeed || subscribing) return;
    
    if (showModal) {
      webApp.MainButton.text = t("Next")
    } else if (!showModal && notSubscribe) {
      webApp.MainButton.text = t("Subscribe")
    } else if (movingChannelState) {
      webApp.MainButton.text = "⌛";
    } else {
      webApp.MainButton.text = t("Add channel")
      webApp.MainButton.color = webApp.themeParams.button_color;
    }
    webApp.MainButton.hideProgress();
  }, [notSubscribe, showModal, showMoveModal, dataFeed, movingChannelState])

  useEffect(() => {
    if(!dataFeed) return;
    if(dataFeed.channels?.filter((channel) => channel.telegram_id === channelTelegramIdState).length === 0 && channelNameState) {
      setIsRenderingAddedChannel(true);
    } else {
      setIsRenderingAddedChannel(false);
    }

    return () => setIsRenderingAddedChannel(false);
  }, [dataFeed?.channels])

    useEffect(() => {
      showMainTelegramButton_UI();
      return () => unmountedTelegramButton_UI()
    }, [])

    useEffect(() => {
      if (showModal) {
        webApp.MainButton.onClick(onSimulateClick)
      } else if (!showModal && notSubscribe) {
        webApp.MainButton.onClick(onOpenLink)
      } else {
        if (notSubscribe) {
          webApp.MainButton.onClick(onOpenLink)
        } else if (!notSubscribe && channels && channels.length < MAX_CHANNELS_IN_FEED) {
          webApp.MainButton.onClick(handleShow);
        } else {
          webApp.MainButton.onClick(onShowLimitChannels)
        }
      }

      return () =>{
        if (showModal) {
          webApp.MainButton.offClick(onSimulateClick)
        } else if (!showModal && notSubscribe) {
          webApp.MainButton.offClick(onOpenLink)
        } else {
          if (notSubscribe) {
            webApp.MainButton.offClick(onOpenLink)
          } else if (!notSubscribe && channels && channels.length < MAX_CHANNELS_IN_FEED) {
            webApp.MainButton.offClick(handleShow);
          } else {
            webApp.MainButton.offClick(onShowLimitChannels)
          }
        }
      }
    }, [])

    useEffect(() => {
      if(showMoveModal) return;

      if(movingChannelState) {
        webApp.MainButton.disable();
      } else {
        webApp.MainButton.enable();
      }

      if (showModal) {
        webApp.MainButton.onClick(onSimulateClick)
      } else if (!showModal && notSubscribe) {
        webApp.MainButton.onClick(onOpenLink)
      } else {
        if (notSubscribe) {
          webApp.MainButton.onClick(onOpenLink)
        } else if (!notSubscribe && dataFeed?.channels && dataFeed?.channels.length < MAX_CHANNELS_IN_FEED) {
          !showSwipeChannelTour && webApp.MainButton.onClick(handleShow);
        } else {
          webApp.MainButton.onClick(onShowLimitChannels)
        }
      }

      return () =>{
        if (showModal) {
          webApp.MainButton.offClick(onSimulateClick)
        } else if (!showModal && notSubscribe) {
          webApp.MainButton.offClick(onOpenLink)
        } else {
          if (notSubscribe) {
            webApp.MainButton.offClick(onOpenLink)
          } else if (!notSubscribe && dataFeed?.channels && dataFeed?.channels.length < MAX_CHANNELS_IN_FEED) {
            webApp.MainButton.offClick(handleShow);
          } else {
            webApp.MainButton.offClick(onShowLimitChannels)
          }
        }
      }
    }, [dataFeed?.channels, notSubscribe, showModal, showMoveModal, showSwipeChannelTour, movingChannelState])

    useEffect(() => {
      if(showMoveModal) return;

      if(movingChannelState) { 
        webApp.MainButton.color = APP_COLOR_HINT;
      } else if (notSubscribe || (dataFeed?.channels && dataFeed?.channels.length < MAX_CHANNELS_IN_FEED)) {
        webApp.MainButton.color = webApp.themeParams.button_color
      } else {
        webApp.MainButton.color = APP_COLOR_HINT
      }
    }, [dataFeed?.channels, notSubscribe, showMoveModal, movingChannelState])

    useEffect(() => {
      if (notSubscribe || (channels && channels.length < MAX_CHANNELS_IN_FEED)) {
        webApp.MainButton.color = webApp.themeParams.button_color
      } else {
        webApp.MainButton.color = APP_COLOR_HINT
      }
    }, [])
  // render & watch MainButton - end

  useEffect(() => {
    if (!dataFeed?.title || statusProductTour_AddChannelFromFeed) return;
    // @ts-ignore
    webApp.SettingsButton.show();
    // @ts-ignore
    webApp.SettingsButton.onClick(() => onSettingsClick());

    return () => unmountedComponent();
  }, [dataFeed?.title])

  useEffect(() => {
    if (showModal && !isExpanded) {
      handleClose();
    }
  }, [isExpanded])

  useEffect(() => {
    if(fromPageSubsribe) {
      setClickedSubscribeButton(true);
      webApp.MainButton.color = APP_COLOR_HINT;
      webApp.MainButton.disable();
      webApp.MainButton.text = t("Just a moment");
    }
  }, [fromPageSubsribe])

  useEffect(() => {
    if (dataFeed && dataFeed.status === "transferred" && fromPageSubsribe) {
      setShowNotificationSubscribeFeed(true);
      setTimeout(() => setShowNotificationSubscribeFeed(false), 7500);
    }
  }, [dataFeed?.status])

  useEffect(() => {
    if (!dataFeed) return;
    if (!channelId) return;
    const paramsToAddChannel = {
      feedId: dataFeed.id,
      channelParams: {
        channel_id: channelId
      }
    };
    addChannel(paramsToAddChannel)
    rememberChannelId(null)
  }, [channelId, dataFeed])

  useEffect(() => {
    if (!dataFeed) return
    if (dataFeed.invite_url) return;
    refetch();
  }, [dataFeed])

  if (isErrorFeed) {
    navigate("/", { replace: true, state: { unauthorizedError: true } });
  }
  if (!dataFeed || !dataFeed.invite_url || isLoadingFeed) return <Load fullscreen />;

  const { title, channels, invite_url } = dataFeed;

  const maxChannelsText = `${t("You have the maximum number of channels")} ${MAX_CHANNELS_IN_FEED}`;

  const backButtonClick = () => {
    setPageFromState("");
    webApp.MainButton.enable();
    webApp.MainButton.hideProgress();
    if(fromChannelFeed) {
      navigate(-1);
    } else if (fromPageSubsribe) {
      navigate(-3);
    } else {
      webApp.MainButton.disable();
      setTimeout(() => navigate(-1), 200);
    }
  }

  return (
    <>
      <BackButton onClick={backButtonClick} />

      <div className="feed-details-headline-wrapper">

        <div className="feed-details-headline-logo-wrapper">
          <div className="feed-details-headline-logo">{createPreviewImageFromTitle(title)}</div>
        </div>

        <div 
          className="feed-details-headline-title-wrapper"
          onClick={onSettingsClick}>
          <Htag tag="h3">{title}</Htag>
        </div>

        {/* <div
          className="feed-details-headline-link-wrapper"
          onClick={() => openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(invite_url)}`)}>
          <ShareIcon />
        </div> */}

      </div>

      {
        !notSubscribe && channels && channels.length > 0 && (
          <nav className="feed-details-subtitle-wrapper">
            <div className="feed-details-subtitle-wrapper-link" onClick={() => {
              fromChannelFeed
                ? webApp.close()
                : openTelegramLink(invite_url)
            }}>
              {t("Read")}
            </div>
          </nav>
        )
      }


      {notSubscribe
        ? (
          <>
            {
              !clickedSubscribeButton && <PromptModal text={t("Click here to subscribe to the feed")} />
            }
          </>
        )
        : channels && channels.length > 0
          ? (
            <div className="feed-details-channels-list-wrapper">
              <ChannelsList
                showMoveModal={showMoveModal}
                setShowMoveModal={setShowMoveModal}
                closeAddChannelModal={handleClose}
                channels={channels ?? []}
                feedId={feedId} 
                movingChannel={movingChannelState}
                setMovingChannel={setMovingChannelState}
              />

                {
                  isRenderingAddedChannel
                    ? <Load />
                    : <></>
                }
            </div >
          )
          : (
            <>
              <div className="without-feeds text-hint">
                {
                  isRenderingAddedChannel
                    ? <Load />
                    : <p>{t("Add channels to your feed")}</p>
                }
                
              </div>

              {!showNotificationSubscribeFeed && <PromptModal text={t("Click here to add a channel")} />}
            </>
          )
      }

      <Modal
        centered
        animation={false}
        show={showModal}
        onHide={handleClose}
        dialogClassName="custom-modal modal-select-method-add-channel"
        aria-labelledby="modal-add-channel-methods">

        <Modal.Header className="custom-modal-header-wrapper">
          <p className="custom-modal-header text-hint">{t("Add channel")}</p>
        </Modal.Header>

        <Modal.Body>
          <ListGroup className="custom-list-wrapper">

            <ListGroup.Item
              id="product_tour_add-channel-from-feed_catalog"
              className="custom-list-wrapper-item background-list-item"
              onClick={() => selectMethodToAddChannel(true, "library")}>

              <div className="custom-list-icon-wrapper">
                <LibraryIcon />
              </div>

              <p className={`custom-list-wrapper-title custom-wrap-text-ellipsis text-color ${THEME_DARK ? "text-white border-bottom_after-dark" : "border-bottom_after"}`}>
                {t("Choose from the catalog")}
              </p>

              {
                method === "library" && <div className="custom-list-wrapper-checkmark"><CheckMarkIcon /></div>
              }
            </ListGroup.Item>

            <ListGroup.Item
              id="product_tour_add-channel-from-feed_link"
              className="custom-list-wrapper-item background-list-item"
              onClick={() => selectMethodToAddChannel(true, "link-to-channel")}>

              <div className="custom-list-icon-wrapper">
                <LinkIcon />
              </div>

              <p className={`custom-list-wrapper-title custom-wrap-text-ellipsis text-color ${THEME_DARK ? "text-white border-bottom_after-dark" : "border-bottom_after"}`}>
                {t("Insert link channel")}
              </p>

              {
                method === "link-to-channel" && <div className="custom-list-wrapper-checkmark"><CheckMarkIcon /></div>
              }
            </ListGroup.Item>

            <ListGroup.Item
              id="product_tour_add-channel-from-feed_link"
              className="custom-list-wrapper-item background-list-item"
              onClick={() => selectMethodToAddChannel(true, "add-channel-from-subscriptions")}>

              <div className="circle-icon-wrapper">
                <FromSubscriptionsIcon />
              </div>

              <p className={`custom-list-wrapper-title custom-wrap-text-ellipsis text-color ${THEME_DARK && "text-white"}`}>
                {t("From my subscriptions")}
              </p>

              {
                method === "add-channel-from-subscriptions" && <div className="custom-list-wrapper-checkmark"><CheckMarkIcon /></div>
              }

            </ListGroup.Item>

          </ListGroup>

          <ProductTourAddChannelFromFeed />
        </Modal.Body>

      </Modal>

      {
        !isRenderingAddedChannel && channelNameState && (
          <NotificationModal text={`${t("Channel")} ${channelNameState} ${t("Successfully added")}`} />
        )
      }

      {
        showNotificationLimitChannelsModal && (
          <NotificationModal icon="warning" text={maxChannelsText} />
        )
      }

      {
        showNotificationSubscribeFeed && (
          <NotificationModal text={t("You have successfully subscribed to the feed")} />
        )
      }

      {
        showSwipeChannelTour && (
          <ProductTourSwipeChannel />
        )
      }

      <span
        ref={simulateClick}
        className="opacity-click-simulate-element"
        onClick={() => navigate("/feeds/" + feedId + "/settings", {
          replace: false,
          state: {
            feedName: dataFeed?.title || "",
            feedId: feedId,
            // publicationMethod: (dataFeed && dataFeed.publication_method) || "automatic"
          }
        })} 
      />

      <span
        ref={simulateClickAddChannelMethod}
        className="opacity-click-simulate-element"
        onClick={() => selectMethodToAddChannel(false, method)} 
      />
    </>
  );
};

export default FeedDetails;