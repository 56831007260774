import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ChannelModel } from '../models/models';
import { getTelegramChannelLinkInfo } from '../hooks/api';

import Load from '../components/fetch/Load';

import { useDefaultFeedToAddChannel } from "../store";

import { useQuery } from "@tanstack/react-query";

const AddChannelFromBotLink: FC = () => {

  const navigate = useNavigate();

  const { updateFeed } = useDefaultFeedToAddChannel();

  let [searchParams] = useSearchParams();
  const url = searchParams.get("url") as string;
  const correctUrl = url.charAt(0) === "@" ? url.substring(1) : url;

  const {
    data: channelData,
    isError,
    error
  } = useQuery<ChannelModel, Error>({
    queryKey: ["channel", correctUrl],
    queryFn: () => getTelegramChannelLinkInfo("https://t.me/" + correctUrl),
    retry: 1
  });

  useEffect(() => {
    updateFeed(null)
  }, [])

  useEffect(() => {
    if (!channelData) return;
    navigate("/library/channel/" + channelData.id, { replace: false, state: { channel: channelData, fromForward: true } })
  }, [channelData])

  useEffect(() => {
    if (isError) {
      navigate("/", { replace: false, state: { fromForward_error: error.message } })
    }
  }, [isError])

  if (!channelData) return <Load fullscreen />;

  return (
    <Load fullscreen />
  );
};

export default AddChannelFromBotLink;