import React, { createContext, useState } from "react";

const TIMEOUT = 2000;

export enum NotificationType {
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
  }

export type Notification = {
    type: NotificationType,
    text: string
};

type NotificationCallable = (notification: Notification) => void;

type NotificationContextType = {
    notification: Notification | undefined,
    setNotification: NotificationCallable
}

export const NotificationContext = createContext<NotificationContextType>({
    notification: undefined,
    setNotification: ()=>{}
});

export const NotificationProvider = (props: any) => {
  const [notification, setNotification] = useState<Notification>();

  const setNotificationWrapper = (notification: Notification) => {
    setNotification(notification);
    setTimeout(() => {
        setNotification(undefined);
    }, TIMEOUT);
  }

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification: setNotificationWrapper
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
