import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';

const BlogsSliderPlaceholder = () => {
	return (
		<div className="mt-3 blogs-slider-placeholder">

			<Placeholder as="div" animation="glow" className="d-flex justify-content-between align-items-center p-0">
				<Placeholder xs={6} bg="secondary" className="bg-placeholder-secondary" size="lg" />  <Placeholder xs={2} bg="primary" size="lg" className="bg-placeholder-primary" />
			</Placeholder>

			<div className="blogs-slider-cards-wrapper-placeholder">
				{
					[1, 2].map((item, index) => {
						return (
							<Card key={item + index} className="border-0 blogs-slider-card-placeholder p-3 mt-2">

								<Placeholder as="div" animation="glow" className="blogs-slider-card-placeholder-image-wrapper">
									<Placeholder className="blogs-slider-card-placeholder-image-wrapper bg-placeholder-secondary" xs={1} bg="secondary" size="xs" />
								</Placeholder>

								<div className="d-flex justify-content-between align-items-center mt-2">
									<Placeholder as="div" animation="glow" className="blogs-slider-card-placeholder-text-wrapper">
										<Placeholder xs={10} bg="secondary" className="bg-placeholder-secondary" size="xs" />
										<Placeholder xs={6} bg="secondary" className="bg-placeholder-secondary mt-1" size="xs" />
									</Placeholder>

									<Placeholder as="div" animation="glow" className="blogs-slider-card-placeholder-icon-wrapper">
										<Placeholder xs={2} bg="primary" className="bg-placeholder-primary-light" />
									</Placeholder>
								</div>

							</Card>
						)
					})
				}
			</div>
		</div>
	)
}

export default BlogsSliderPlaceholder;