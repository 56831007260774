import { useEffect } from "react";

export default function useDebounceInputRefetch(
	value: string,
	refetch: () => void
) {

    useEffect(() => {
		if (!value) return;
		const delayDebounceFn = setTimeout(() => {
		  refetch();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	  }, [value]);

}