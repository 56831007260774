import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { languagesList, showMainTelegramButton, unmountedTelegramButton } from "../models/functions";
import { useEditFeed } from "../hooks/api";

import { ReactComponent as CheckMarkIcon } from "../icons/checkmark.svg";

import ListGroup from 'react-bootstrap/ListGroup';

import { useTranslation } from "react-i18next";

import "../styles/Language.css";

const publicationMethodArray = ["automatic", "manual"] as "automatic"[] | "manual"[]

const FeedDetailsSettingsPublicationMethod: FC = () => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const defaultPublicationMethod = state ? state.defaultPublicationMethod : "automatic";
	const feedId = state ? state.feedId : "";
	const feedName = state ? state.defaultFeedName : "";

	const [publicationMethodState, setPublicationMethodState] = useState<"automatic" | "manual">(defaultPublicationMethod);

	const editFeedParams = {
	feedId: feedId,
	data: {
		title: feedName,
		publication_method: publicationMethodState
	}
	}

	const { mutate: editFeed } = useEditFeed();

	const updatePublicationMethod = () => {
		if(publicationMethodState !== defaultPublicationMethod) {
			editFeed(editFeedParams)
		}
		navigate(-1);
	}

	useEffect(() => {
		showMainTelegramButton(updatePublicationMethod, t("Save"));
		return () => unmountedTelegramButton(updatePublicationMethod);
	}, [publicationMethodState])
	
	return (
		<section className="language-page-wrapper">
			<p className="language-page-title">{t("Update Feed")}</p>

			<ListGroup className="language-page-list">
				{
					publicationMethodArray.map((item, index) => (
						<ListGroup.Item
							onClick={() => item !== publicationMethodState && setPublicationMethodState(item)}
							className={`background-list-item language-page-list-item-wrapper ${index + 1 !== languagesList.length ? "border-bottom_after" : " "} pointer`}
							key={item}>

							<div className="language-page-list-item">
								<p className="language-page-list-item-title text-color">{t(item)}</p>
							</div>

							{
								item === publicationMethodState && (
									<div className="custom-list-wrapper-checkmark">
										<CheckMarkIcon />
									</div>
								)
							}

						</ListGroup.Item>
					))
				}
			</ListGroup>

		</section>
	)
}

export default FeedDetailsSettingsPublicationMethod;