import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APP_COLOR_HINT, FeedModel, MAX_LENGTH_FEEDS, webApp } from '../models/models';
import { showMainTelegramButton, unmountedTelegramButton } from "../models/functions";
import { getFeeds } from "../hooks/api";
import useChangeHeaderColorInModal from "../hooks/useChangeHeaderColorInModal";
import useDebounceShowNotificationModal from "../hooks/useDebounceNotificationModal";
import { useProductTour, useProductTour_SwipeFeed, useShowModal_Favorites, useShowModal_FeedsLimit, useShowModal_Help } from "../store";

import FeedsList from '../components/FeedsList';
import Error from '../components/fetch/Error';
import Load from "../components/fetch/Load";
import NotificationModal from "../components/Modals/NotificationModal";
import PromptModal from "../components/Modals/PromptModal";
import ProductTourSwipeFeed from "../components/ProductTour/SwipeFeed/";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Feeds: FC = () => {

  const { t } = useTranslation();

  const { pathname, state } = useLocation();

  const navigate = useNavigate();

  const { statusProductTour } = useProductTour();
  const { statusProductTour: statusProductTour_SwipeFeed } = useProductTour_SwipeFeed();
  const { show: showNotificationLimitFeedsModal, setShow: setShowNotificationLimitFeedsModal } = useShowModal_FeedsLimit();
  const { show: showHelpModal, setShow: setShowHelpModal} = useShowModal_Help();
  const { show: showFavoritesModalStore } = useShowModal_Favorites();

  const [unauthorizedError, setUnauthorizedError] = useState<boolean>(state ? state.unauthorizedError : false);
  const [fromForward_error] = useState<boolean>(state ? state.fromForward_error : false);
  const [pageFrom] = useState<string>(state && state.pageFrom ? state.pageFrom : "");
  const [showNotificationUnauthorizedError, setShowNotificationUnauthorizedError] = useState<boolean>(false);
  const [clickedSubscribeButton, setClickedSubscribeButton] = useState<boolean>(false);
  const [maxFeeds, setMaxFeeds] = useState<boolean | null>(null);
  const [subscribingFeed, setSubscribingFeed] = useState<boolean>(false);
  const [showSwipeFeedTour, setShowSwipeFeedTour] = useState<boolean>(false);

  const simulateClick = useRef<HTMLDivElement>(null);

  const {
    data,
    isError,
    isLoading,
    error,
    isRefetchError
  } = useQuery<FeedModel[], Error>({
    queryKey: ["feeds"],
    queryFn: getFeeds,
    refetchInterval: () => subscribingFeed ? 1500 : false,
    retry: 2
  })

  const isLoadingPage = !data || isLoading || (isLoading && pathname === "/");

  const showLimitFeedsModal = () => {
    if(showHelpModal) {
      setShowHelpModal(false);
    } else {
      setShowNotificationLimitFeedsModal(true);
    }
  }

  const onSimulateClick = () => {
    if(showHelpModal) {
      setShowHelpModal(false);
    } else {
      simulateClick.current?.click();
    }
  }

  const onCLoseApp = () => {
    webApp.close();
  }

  useDebounceShowNotificationModal(showNotificationLimitFeedsModal, setShowNotificationLimitFeedsModal);
  useChangeHeaderColorInModal(statusProductTour);
  useChangeHeaderColorInModal(showSwipeFeedTour);

  useEffect(() => {
    if(pageFrom === "forward-redirect") {
      setTimeout(() => {
        webApp.BackButton.hide();
      }, 0)
    }
  }, [pageFrom])

  useEffect(() => {
    if(!data || statusProductTour || !statusProductTour_SwipeFeed) return;
    
    if(data.length > 0) {
      setShowSwipeFeedTour(true);
    }
    
    return () => {
      setShowSwipeFeedTour(false);
    }
  }, [data, statusProductTour, statusProductTour_SwipeFeed])

  useEffect(() => {
    if(!clickedSubscribeButton) return;
    setTimeout(() => setClickedSubscribeButton(false), 5000)
  }, [clickedSubscribeButton])

  useEffect(() => {
    if(isLoadingPage) {
      webApp.MainButton.disable();
    }

    if(statusProductTour) {
      webApp.MainButton.show();
    } 

    if(isLoadingPage || maxFeeds === null) return;

		if (statusProductTour || showSwipeFeedTour || statusProductTour_SwipeFeed) {
      webApp.MainButton.show();
      webApp.MainButton.offClick(onSimulateClick);
		} else {
      if (!maxFeeds && showFavoritesModalStore) {
        showMainTelegramButton(onCLoseApp , t("Back to Feed"), webApp.themeParams.button_color);
      } else if (!maxFeeds && !showFavoritesModalStore) {
        showMainTelegramButton(onSimulateClick , t("Create Feed"), webApp.themeParams.button_color);
      } else {
        showMainTelegramButton(showLimitFeedsModal ,t("Create Feed"), APP_COLOR_HINT);
      }
		}

    return () => {
      if(showFavoritesModalStore) {
        unmountedTelegramButton(onCLoseApp);
      }
      if (!maxFeeds && !statusProductTour) {
        unmountedTelegramButton(onSimulateClick);
      } else if(maxFeeds && !statusProductTour) {
        unmountedTelegramButton(showLimitFeedsModal);
      }
    }
	}, [statusProductTour, maxFeeds, isLoadingPage, showHelpModal, showSwipeFeedTour, showFavoritesModalStore])

  useEffect(() => {
    if(isLoadingPage || maxFeeds === null) return;

    if(showFavoritesModalStore) {
      showMainTelegramButton(onCLoseApp , t("Back to Feed"), webApp.themeParams.button_color);
    } else if(maxFeeds && !statusProductTour && !showSwipeFeedTour) {
      showMainTelegramButton(showLimitFeedsModal ,t("Create Feed"), APP_COLOR_HINT)
    } else if(!maxFeeds && !statusProductTour && !showSwipeFeedTour) {
      showMainTelegramButton(onSimulateClick ,t("Create Feed"), webApp.themeParams.button_color)
    }

    return () => {
      if(showFavoritesModalStore) {
        unmountedTelegramButton(onCLoseApp);
      }
      if(maxFeeds && !showFavoritesModalStore) {
        unmountedTelegramButton(showLimitFeedsModal)
      } else if(!maxFeeds && !statusProductTour && !showSwipeFeedTour) {
        unmountedTelegramButton(onSimulateClick)
      }
    }
  }, [maxFeeds, isLoadingPage, showFavoritesModalStore])

  useEffect(() => {
    if(showHelpModal) {
      webApp.MainButton.text = t("Back")
      webApp.MainButton.color = webApp.themeParams.button_color;
    } else {
      webApp.MainButton.text = t("Create Feed")
      if(maxFeeds && !statusProductTour && !statusProductTour_SwipeFeed) {
        webApp.MainButton.color = APP_COLOR_HINT;
      } else {
        webApp.MainButton.color = webApp.themeParams.button_color;
      }
    }
  }, [showHelpModal])

  useEffect(() => {
    setShowNotificationLimitFeedsModal(false);
    webApp.expand();
    webApp.ready();

    if (fromForward_error) {
      window.history.replaceState({}, document.title);
    }
    if (unauthorizedError) {
      webApp.BackButton.hide();
      setShowNotificationUnauthorizedError(true);
      setTimeout(() => setShowNotificationUnauthorizedError(false), 7000);
    }

    return () => {
      if(unauthorizedError) {
        setUnauthorizedError(false);
      }
    }
  }, [])

  useEffect(() => {
    if(!data) return;
    
    if(data.length === MAX_LENGTH_FEEDS) {
      setMaxFeeds(true);
      if(statusProductTour || showSwipeFeedTour || statusProductTour_SwipeFeed) {
        webApp.MainButton.color = webApp.themeParams.button_color;
      } else {
        webApp.MainButton.color = APP_COLOR_HINT;
      }
    } else {
      setMaxFeeds(false);
      webApp.MainButton.color = webApp.themeParams.button_color;
    }

    webApp.MainButton.enable();
   if(statusProductTour || showSwipeFeedTour) {
    webApp.MainButton.text = t("Stop training");
   } else {
    webApp.MainButton.text = t("Create Feed");
   }
  }, [data])

  useEffect(() => {
    if(!clickedSubscribeButton) return;
    setSubscribingFeed(true);
  }, [clickedSubscribeButton])

  useEffect(() => {
    if(!data || clickedSubscribeButton) return;
    if(data.filter((feed) => feed.status === "transferring").length === 0) {
      setSubscribingFeed(false);
      setClickedSubscribeButton(false);
    }
  }, [data, clickedSubscribeButton])

  useEffect(() => {
    if(!isRefetchError) return;
    setSubscribingFeed(false);
    setClickedSubscribeButton(false);
  }, [isRefetchError])

  if (isLoadingPage) return <Load fullscreen={true} />;
  if (isError) return <Error message={error.message} />;

  const maxFeedText = `${t("You have the maximum number of feeds")} ${MAX_LENGTH_FEEDS}`;

  return (
    <section>
      {
        data.length > 0
          ? (
              <FeedsList 
                feeds={data} 
                clickedSubscribeButton={clickedSubscribeButton}
                setClickedSubscribeButton={setClickedSubscribeButton}
              />
          )
          : !statusProductTour && <PromptModal text={t("Click here to create a feed")} />
      }

      {
        showNotificationLimitFeedsModal && (
          <NotificationModal
            icon="warning"
            text={maxFeedText} />
        )
      }

      {
        showNotificationUnauthorizedError && (
          <NotificationModal
            icon="warning"
            text={t("Feed not found")} />
        )
      }

      {
        fromForward_error && (
          <NotificationModal
            icon="warning"
            text={t("There is an error") + ": " + fromForward_error} />
        )
      }

      {
        showSwipeFeedTour && (
          <ProductTourSwipeFeed />
        )
      }

      <span
        ref={simulateClick}
        className="opacity-click-simulate-element"
        onClick={() => navigate("/feeds/create")}
      />
    </section>
  );
};

export default Feeds;