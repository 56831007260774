import { FC, useEffect, useState } from "react";

import { APP_COLOR_HINT, MODAL_BG_COLOR_DARK, THEME_DARK, webApp } from "../../../models/models";
import { useProductTour, useProductTour_AddChannelFromFeed, useProductTour_CreateFeed, useProductTour_SwipeChannel, useProductTour_SwipeFeed } from "../../../store";

import Step1 from "./Steps/Step1";
import Tooltip from "../Tooltip";

import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

import "../../../styles/ProductTour.css"
import { setAppSecondaryColor } from "../../../models/functions";

const ProductTourSwipeChannel: FC = () => {

	const { updateStatusProductTour } = useProductTour();
	const { updateStatusProductTour: updateStatusProductTour_CreateFeed } = useProductTour_CreateFeed();
	const { updateStatusProductTour: updateStatusProductTour_AddChannelFromFeed } = useProductTour_AddChannelFromFeed();
	const { updateStatusProductTour: updateStatusProductTour_SwipeFeed } = useProductTour_SwipeFeed();
	const { statusProductTour: statusProductTour_SwipeChannel, updateStatusProductTour: updateStatusProductTour_SwipeChannel } = useProductTour_SwipeChannel();
	
	const [runTour] = useState<boolean>(statusProductTour_SwipeChannel);
	const [steps] = useState<Step[]>([
		{
			title: "",
			content: <Step1 />,
			placement: "auto",
			target: "#product_tour_swipe-channel",
			disableBeacon: true,
		},
	  ]);

	  const onClickEndTour = () => {
		updateStatusProductTour(false);
		updateStatusProductTour_CreateFeed(false);
		updateStatusProductTour_AddChannelFromFeed(false);
		updateStatusProductTour_SwipeFeed(false);
		updateStatusProductTour_SwipeChannel(false);
	  }

	  const handleJoyrideCallback = (data: CallBackProps) => {
		const { status } = data;
		const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

		if(THEME_DARK) {
			window.document.querySelector(".react-joyride__overlay")?.classList.add("spotlight_dark_theme")
			window.document.querySelector(".react-joyride__spotlight")?.classList.add("react-joyride__spotlight_dark_theme")
		}

		if (finishedStatuses.includes(status)) {
			updateStatusProductTour_SwipeChannel(false);
		};
	  }

	  useEffect(() => {
		webApp.MainButton.onClick(onClickEndTour);
		webApp.MainButton.text = "Завершить обучение";
		webApp.MainButton.enable();
		return () => {
			webApp.MainButton.offClick(onClickEndTour);
		}
	  }, [])

	return (
		<Joyride
			disableOverlayClose
			disableScrollParentFix
			disableCloseOnEsc
			tooltipComponent={Tooltip}
			callback={handleJoyrideCallback}
			continuous
			hideCloseButton
			run={runTour}
			scrollToFirstStep
			showProgress
			steps={steps}
			styles={{
				options: {
					arrowColor: MODAL_BG_COLOR_DARK,
					zIndex: 20,
				},
				spotlight: {
					backgroundColor: THEME_DARK ? APP_COLOR_HINT : "#ffffff"
				}
			}}
		/>
	)
}

export default ProductTourSwipeChannel;