import { CustomButtonProps, THEME_DARK } from "../models/models";

import ListGroup from 'react-bootstrap/ListGroup';

import { ReactComponent as AddFeedIcon } from "../icons/plus-icon.svg"
import { ReactComponent as LibraryIcon } from "../icons/book.svg"
import { ReactComponent as FolderIcon } from "../icons/folder.svg"
import { ReactComponent as LinkIcon } from "../icons/link.svg"
import { ReactComponent as CheckMarkIcon } from "../icons/checkmark.svg"
import { ReactComponent as ArrowIcon } from "../icons/arrow.svg"
import { ReactComponent as FlagIcon } from "../icons/flag.svg";
import { ReactComponent as ReferenceIcon } from "../icons/reference.svg"
import { ReactComponent as SupportIcon } from "../icons/support.svg"

import '../styles/CustomButton.css';

const CustomButton = ({
	name,
	onClick,
	textColor = "primary",
	icon,
	additionalIcon,
	showCheckMark = false,
	borderBottom = false,
	borderTopRadius = true
}: CustomButtonProps): JSX.Element => {
   
	const selectTextColor = () => {
		switch (textColor) {
			case 'dark':
				return THEME_DARK ? "text-white" : "text-dark"
			case 'primary':
				return "custom-button-text-primary"
			case 'secondary':
				return "text-hint"
			default:
				return "custom-button-text-primary"
		}
	}

	const selectIcon = () => {
		switch (icon) {
			case 'AddFeedIcon':
				return <AddFeedIcon width={36} height={36} />
			case 'LibraryIcon':
				return <LibraryIcon width={36} height={36} />
			case 'FolderIcon':
				return <FolderIcon width={36} height={36} />
			case 'LinkIcon':
				return <LinkIcon width={36} height={36} />
			case 'FlagIcon':
				return <FlagIcon width={32} height={20} />
			case 'ReferenceIcon':
				return (
					<div className="circle-icon-wrapper">
						<ReferenceIcon />
					</div>
				)
			case 'SupportIcon':
				return (
					<div className="circle-icon-wrapper">
						<SupportIcon />
					</div>
				)
			default:
				return <></>;
		}
	}

	const selectAdditionalIcon = () => {
		if (additionalIcon === "Arrow") {
			return <ArrowIcon width={15} height={15} />
		} else if (additionalIcon === "ArrowDown") {
			return <div className="arrow-down"><ArrowIcon width={15} height={15} /></div>
		} else if (additionalIcon === "CheckMark" && showCheckMark) {
			return <CheckMarkIcon width={15} height={15} />
		} else {
			return <></>
		}
	}

	return (
		<div className="custom-button-list-wrapper">
			<ListGroup className={`
				custom-button-wrapper 
				${!borderBottom ? "border-only_bottom-radius" : " "}
				${!borderTopRadius ? "border-top-radius-none" : " "}
			`}>
				<ListGroup.Item
					className="custom-button background-list-item"
					role="button"
					onClick={onClick}>

					{icon && (
						<div className="select-icon-wrapper">
							{selectIcon()}
						</div>
					)}

					<div className={`custom-button-text-wrapper ${icon ? "icon-margin" : ""}`}>
						<p className={`custom-button-text ${selectTextColor()}`}>{name}</p>
						{
							additionalIcon && (
								<div className="custom-button-additional-icon svg-fill-primary">
									{selectAdditionalIcon()}
								</div>
							)
						}
					</div>

					{borderBottom && <span className="border-bottom_after" />}
				</ListGroup.Item>
			</ListGroup>
		</div>
	)
}

export default CustomButton;