import { BaseSyntheticEvent, FC, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { getFeeds, getLibraryChannelsByCategory } from "../../hooks/api";
import { FeedModel, ChannelModel, MetaDataCategory } from '../../models/models';
import { createPreviewImageFromTitle, getSubscribers, selectCategoryData } from "../../models/functions";
import { useChangeCountry, useRememberChannelWithoutFeeds, useShowAddChannelModal_Library } from "../../store";

import Htag from "../Htag";
import Error from '../fetch/Error';
import AddChannelModal from "../Modals/AddChannelModal";
import LibrariesListPlaceholder from "../Preloaders/LibrariesListPlaceholder";
import NotificationModal from "../Modals/NotificationModal";
import WithoutFeedsModal from "../Modals/WithoutFeedsModal";

import { ReactComponent as AddIcon } from "../../icons/plus-icon.svg"

import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import "../../styles/LibrariesList.css"

const LibrariesList: FC<{
	category: MetaDataCategory
}> = ({ category }) => {

	const { t } = useTranslation();

	const { rememberChannelId } = useRememberChannelWithoutFeeds();
	const { setShow: setShowModalStore } = useShowAddChannelModal_Library();
	const { countryCodeStore } = useChangeCountry();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [showModalWithoutFeeds, setShowModalWithoutFeeds] = useState<boolean>(false);
	const [selectedChannel, setSelectedChannel] = useState<ChannelModel | null>(null);
	const [addChannelStatus, setAddChannelStatus] = useState<string>("");

	const onHide = () => {
		setShowModal(false)
		setShowModalStore(false)
	}

	const onHideWithoutFeeds = () => {
		setShowModalWithoutFeeds(false)
	}

	const { id: categoryId, code } = category;

	const navigateParams = {
		name: t(selectCategoryData(code).categoryName),
		code: code
	};

	const navigate = useNavigate();

	const {
		data: channels,
		error,
		isError,
		isLoading
	} = useQuery<ChannelModel[], Error>({
		queryKey: ["library-category-channels", `categoryId=${categoryId}&country=${countryCodeStore}`],
		queryFn: () => getLibraryChannelsByCategory(categoryId, countryCodeStore),
		retry: 2,
		retryOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		staleTime: 300000
	});

	const { data: feeds } = useQuery<FeedModel[], Error>({
		queryKey: ["feeds"],
		queryFn: getFeeds,
	});

	if (isLoading) return <LibrariesListPlaceholder />
	if (isError) return <Error message={error.message} />;
	if (!channels) return <></>;

	const availabilityFeeds = feeds && feeds.length > 0;

	const listItems = channels.slice(0, 3).map((item, index) => {
		const { id, title, participants, image100 } = item;

		const onShow = (event: BaseSyntheticEvent) => {
			event.stopPropagation()
			setSelectedChannel(item)
			setShowModal(true)
			setShowModalStore(true)
		};

		const onShowWithoutFeeds = (event: BaseSyntheticEvent) => {
			event.stopPropagation()
			rememberChannelId(item.id)
			setShowModalWithoutFeeds(true)
		}

		return (
			<ListGroup.Item
				key={id}
				onClick={() => navigate("/library/channel/" + id, { replace: false, state: { channel: item } })}
				className="background-list-item libraries-list-channel-item">

				{
					image100
						? (
							<Image
								src={image100}
								alt={title}
								roundedCircle />
						)
						: (
							<div className="list-item-no-image-wrapper">
								<div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
							</div>
						)
				}

				<div className={`channel-item-description-wrapper ${index !== 2 ? "border-bottom" : "border-0"}`}>
					<div className="channel-item-description-column custom-wrap-text-ellipsis">
						<p className="channel-item-title custom-wrap-text-ellipsis">{title}</p>
						<p className="channel-item-participants">{getSubscribers(participants)}</p>
					</div>

					<div onClick={availabilityFeeds ? onShow : onShowWithoutFeeds} className="channel-item-svg-wrapper">
						<AddIcon />
					</div>
				</div>
			</ListGroup.Item>
		)
	});

	return (
		<>
			<Card className="border-0 libraries-list-card background-list-item">

				<div className="background-list-item libraries-list-card-headline">
					<Htag
						tag="h4"
						textAlign="left">
						{t(selectCategoryData(code).categoryName)}
					</Htag>

					<Button
						onClick={() => navigate("/library/categories/" + categoryId, { replace: false, state: navigateParams })}
						className="p-0"
						variant="link">
						{t("All")}
					</Button>
				</div>

				<ListGroup className="libraries-list-group background-list-item">
					{listItems}
				</ListGroup>

			</Card>

			{
				availabilityFeeds && (
					<AddChannelModal
						show={showModal}
						onHide={onHide}
						telegramId={selectedChannel?.telegram_id}
						channelId={selectedChannel?.id}
						setAddChannelStatus={setAddChannelStatus}
					/>
				)
			}

			{
				!availabilityFeeds && showModalWithoutFeeds &&	(
					<WithoutFeedsModal onHide={onHideWithoutFeeds} />
				)
			}

			{addChannelStatus === "success" && (
				<NotificationModal text={`${t("Channel")} ${selectedChannel?.title} ${t("Successfully added")}`} />
			)}
		</>
	);
};

export default LibrariesList;