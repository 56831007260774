import { FC, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useChangeCountry } from "../store";
import { showMainTelegramButton, unmountedTelegramButton } from "../models/functions";
import { CountryCode, MetaDataCountries, MetaDataParams } from "../models/models";
import { getMetaData } from "../hooks/api";

import { ReactComponent as CheckMarkIcon } from "../icons/checkmark.svg";

import Error from "../components/fetch/Error";
import Load from "../components/fetch/Load";

import ListGroup from 'react-bootstrap/ListGroup';

import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import "../styles/Language.css";

function countriesObjectToArray(map: MetaDataCountries): { code: CountryCode; name: string }[] {
    return Object.entries(map).map(([code, name]) => ({ code, name })) as { code: CountryCode; name: string }[];
}

const Country: FC = () => {

	const {
		data: metaData,
		isLoading,
		isError,
		error,
	} = useQuery<MetaDataParams, Error>({
		queryKey: ["meta-data"],
		queryFn: getMetaData,
		retry: 2,
		retryOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const { t } = useTranslation();
	const navigate = useNavigate();

	const { countryCodeStore, changeCountryCodeStore, changeCountryNameStore } = useChangeCountry();

	const updateCountryStore = (code: CountryCode, name: string) => {
		changeCountryCodeStore(code);
		changeCountryNameStore(name);
	}

	const goPreviousPage = () => {
		navigate(-1)
	}

	useEffect(() => {
		showMainTelegramButton(goPreviousPage, t("Back"));
		return () => unmountedTelegramButton(goPreviousPage);
	}, [])

	if (isLoading) return <Load fullscreen />;
	if (!metaData) return <Load fullscreen />;
	if (isError) return <Error message={error.message} />;

	const countriesArray = countriesObjectToArray(metaData.countries);

	return (
		<section className="language-page-wrapper">
			<p className="language-page-title">{t("All")}</p>

			<ListGroup className="language-page-list">
				{
					countriesArray.map((item, index) => {
						const { code, name } = item;
						return (
							<ListGroup.Item
								onClick={() => code !== countryCodeStore && updateCountryStore(code, name)}
								className={`
									background-list-item language-page-list-item-wrapper 
									${index + 1 !== countriesArray.length && "border-bottom_after"}
									pointer
								`}
								key={code}
							>
	
								<div className="language-page-list-item">
									<p className="language-page-list-item-title text-color">{name}</p>
								</div>
	
								{
									code === countryCodeStore && (
										<div className="custom-list-wrapper-checkmark">
											<CheckMarkIcon />
										</div>
									)
								}
	
							</ListGroup.Item>
						)
					})
				}
			</ListGroup>

		</section>
	)
}

export default Country;