import {
  BaseSyntheticEvent,
  FC,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import {
  useToggleShowAdultsChannels,
  useLibraryScrollPosition,
  useSearchChannelValue,
  useSortedChannelsByDate,
  useSortedChannelsOption,
  useShowAddChannelModal_Library,
  useToggleHeaderCatalog,
  useShowModal_Help,
  useChangeCountry,
} from "../store";
import {
  MetaDataParams,
  ChannelModel,
  PLATFORM_IOS,
  PLATFORM_DESKTOP,
  PLATFORM_ANDROID,
  webApp
} from "../models/models";
import {
  getSubscribers,
  selectCategoriesToShow,
  showMainTelegramButton,
  unmountedTelegramButton,
} from "../models/functions";
import {
  getInfiniteChannelsBySearch,
  getMetaData,
} from "../hooks/api";
import useOnScreen from "../hooks/useOnScreen";
import useDetectScrollPosition from "../hooks/useDetectScrollPosition";
import useDebounceInputRefetch from "../hooks/useDebounceInputRefetch";
import useContentLock from "../hooks/useContentLock";

import Load from "../components/fetch/Load";
import Error from "../components/fetch/Error";
import CategoriesList from "../components/LibraryScreen/CategoriesList";
import LibrariesList from "../components/LibraryScreen/LibrariesList";
import CategoriesSlider from "../components/Sliders/CategoriesSlider";
import BlogsSider from "../components/Sliders/BlogsSlider";
// import SortedChannelsHeader from "../components/LibraryScreen/SortedChannelsHeader";
import ChannelIcon from "../components/ChannelIcon";
import CloseIcon from "../components/CloseIcon";

import { ReactComponent as LoupIcon } from "../icons/loupe.svg";

import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import "../styles/Library.css";

const categoryWithChannels = [
  "news",
  "psychology",
  "tech",
  "crypto",
  "business",
  "edutainment",
  "music",
  "games",
  "travels",
];

const Library: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { toggleStateStore: showAdultsChannelsToggleStore } = useToggleShowAdultsChannels();
  const { scrollPositionState, changeScrollPosition } =
    useLibraryScrollPosition();
  const { updateValue: updateChannelStateValue, value: channelStateValue } =
    useSearchChannelValue();
  const { date: channelsPeriod } = useSortedChannelsByDate();
  const { option } = useSortedChannelsOption();
  const { show: showModal } = useShowAddChannelModal_Library();
	const { updateShowSearchInputStore, updateChannelInputFocusStore } = useToggleHeaderCatalog();
  const { show: showHelpModal, setShow: setShowHelpModal} = useShowModal_Help();
	const { countryCodeStore } = useChangeCountry();

  const scrollPosition = useDetectScrollPosition();

  const [channelInputValue, setChannelInputValue] = useState<string>("");
  const [channelInputFocus, setChannelInputFocus] = useState<boolean | null>(null);
  const [isLockedContent, setIsLockedContent] = useState<boolean>(false);

  const pageWithoutHeader = channelInputFocus || channelInputValue;

  const inputRef = useRef<HTMLInputElement>(null);
  const simulateClickRef = useRef<HTMLDivElement>(null);

  const onChange = (event: BaseSyntheticEvent) => {
    setChannelInputValue(event.target.value.replace(/\s+/g, " "));
  };

  const horizontalScrollTo = (position: number) => {
    window.scroll({
      top: position,
      left: 0,
      behavior: "instant",
    } as any);
  }

  const onFocus = () => {
    if(showHelpModal) {
      setShowHelpModal(false);
    } else {
      setChannelInputFocus(true);
      if(PLATFORM_ANDROID) {
        horizontalScrollTo(0);
        setIsLockedContent(true);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0)
        setTimeout(() => {
          setIsLockedContent(false);
        }, 1000)
      } else {
          inputRef.current?.focus();
      }
    }
  };

  const onClearInputIcon = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setChannelInputValue("");
    onFocus();
  };

  const {
    data: metaData,
    isLoading,
    isError,
    error,
  } = useQuery<MetaDataParams, Error>({
    queryKey: ["meta-data"],
    queryFn: getMetaData,
    retry: 2,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useContentLock(isLockedContent);

  useEffect(() => {
    showMainTelegramButton(onFocus, `🔍⠀${t("Search channel")}`);
    return () => unmountedTelegramButton(onFocus);
  }, []);

  useEffect(() => {
    updateChannelInputFocusStore(channelInputFocus)
  }, [channelInputFocus])

  useEffect(() => {
    if(channelInputFocus || channelInputValue || channelStateValue) {
      updateShowSearchInputStore(true)
    } else {
      updateShowSearchInputStore(false)
    }
  }, [channelInputFocus])

  useEffect(() => {
    if (showModal) return;
    showMainTelegramButton(onFocus, `🔍⠀${t("Search channel")}`);
    return () => unmountedTelegramButton(onFocus);
  }, [showModal]);

  useEffect(() => {
    showMainTelegramButton(onFocus, showHelpModal ? `${t("Back")}` : `🔍⠀${t("Search channel")}`);
    return () => unmountedTelegramButton(onFocus);
  }, [showHelpModal]);

  useEffect(() => {
    if (!channelStateValue) return;
    setChannelInputValue(channelStateValue);
    onFocus();
    setTimeout(() => updateChannelStateValue(""), 0);
  }, []);

  useEffect(() => {
    if (!channelInputValue && !channelInputFocus) {
      changeScrollPosition(scrollPosition);
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (scrollPositionState === 0) return;
    setTimeout(() => {
      window.history.scrollRestoration = "auto";
      horizontalScrollTo(scrollPositionState);
    }, 0);
  }, []);

  useEffect(() => {
    if (channelInputFocus) {
      webApp.MainButton.hide();
    } else {
      webApp.MainButton.show();
    }
  }, [channelInputFocus]);

  const SearchChannel = () => {
    const {
      data,
      refetch,
      fetchNextPage,
      hasNextPage,
      isFetching,
      isFetchingNextPage,
      isPending,
      isError,
    } = useInfiniteQuery({
      queryKey: [
        "infinite-channels-by-search",
        `${option}&${channelsPeriod}&${channelInputValue}&${countryCodeStore}`
      ],
      queryFn: ({ pageParam }) =>
        getInfiniteChannelsBySearch({
          search: channelInputValue,
          page: pageParam,
          by: option,
          direction: "desc",
          country: countryCodeStore,
          period: channelsPeriod,
        }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (lastPage.channels.length === 0) {
          return undefined;
        }
        return lastPageParam + 1;
      },
      enabled: false,
    });

    const elementRef = useRef<HTMLDivElement>(null);
    const isOnScreen = useOnScreen(elementRef, [data, scrollPosition]);

    useDebounceInputRefetch(channelInputValue, refetch);

    useEffect(() => {
      if (isOnScreen && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, [hasNextPage, isOnScreen]);

    if (isPending && channelInputValue)
      return (
        <div className="loaded-spinner-loading">
          <Load />
        </div>
      );
    if (isError) return <Error />;
    if (!data) return <></>;

    const pages = data.pages;
    const withoutChannels = pages[0].channels.length === 0;

    return (
      <div
        className={`search-channel-wrapper_default ${
          !PLATFORM_ANDROID
            ? "search-channel-wrapper_ios disable-scrollbar"
            : "search-channel-wrapper"
        }`}
      >
        {withoutChannels && (
          <p className="search-channel-wrapper-epmty-content">
            {t("Empty - no channels found")}
          </p>
        )}

        {/* <SortedChannelsHeader rounded={pages[0].channels.length === 0} /> */}

        {pages ? (
          channelInputValue && (
            <ListGroup
              className="search-channel-list-group background-list-item"
              onTouchStart={() => inputRef.current?.blur()}
            >
              {pages.map((page: any, index) => (
                <Fragment key={index}>
                  {page.channels.map((item: ChannelModel) => {
                    const { id, image100, title, participants } = item;
                    return (
                      <ListGroup.Item
                        onClick={() => {
                          navigate("/library/channel/" + id, {
                            replace: false,
                            state: { channel: item },
                          });
                          updateChannelStateValue(channelInputValue);
                        }}
                        className="search-channel-list-item background-list-item"
                        key={id}
                      >

                        <ChannelIcon
                          img={image100}
                          title={title}
                          searchChannelList
                        />

                        <div className="search-channel-list-item-text-wrapper">
                          <div
                            style={PLATFORM_DESKTOP ? { height: "60px" } : {}}
                            className="search-channel-list-item-text"
                          >
                            <p className="custom-wrap-text-ellipsis search-channel-list-item-text-title text-color">
                              {title}
                            </p>
                            <p className="search-channel-list-item-text-subtitle">
                              {getSubscribers(participants)}
                            </p>
                          </div>
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </Fragment>
              ))}
            </ListGroup>
          )
        ) : (
          <Load />
        )}

        <div className="transarent-trigger-next-page" ref={elementRef} />

        {channelInputValue && isFetchingNextPage && isFetching && (
          <div className="loaded-spinner">
            <Load />
          </div>
        )}
      </div>
    );
  };

  if (isLoading) return <Load fullscreen catalogPage/>;
  if (!metaData) return <Load fullscreen catalogPage/>;
  if (isError) return <Error message={error.message} />;

  const categoriesToShow = selectCategoriesToShow(
    showAdultsChannelsToggleStore,
    metaData.categories
  );

  const showSearchInput = channelInputFocus || channelInputValue || channelStateValue;

  return (
      <div
        className={`${channelInputValue && !PLATFORM_ANDROID
            ? "library-page-wrapper_ios"
            : "library-page-wrapper"
          }`}
      >
        <div className="library-page-wrapper-header">

          <div
            className={`
              library-page-wrapper-header-search
              ${
                channelInputFocus !== null ?
                  showSearchInput
                    ? "library-page-wrapper-header-search_focus"
                    : "library-page-wrapper-header-search_blur"
                : ""
              }
            `}
          >
            <div
              className={`library-form-wrapper ${
                channelInputValue && !PLATFORM_IOS
                  ? "library-form-wrapper_fixed"
                  : ""
              }`}
            >
              <Form
                className="library-form"
                onSubmit={(e) => e.preventDefault()}
              >
                <Form.Group
                  className="rounded library-input-wrapper"
                  controlId="library-input"
                >
                  <div className="library-input-svg-wrapper">
                    <LoupIcon />
                  </div>

                  <Form.Control
                    autoComplete="off"
                    value={
                      channelInputValue && channelInputValue[0] === " "
                        ? channelInputValue.substring(1)
                        : channelInputValue || ""
                    }
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={() => !channelInputValue && setChannelInputFocus(false)}
                    className="border-0"
                    type="text"
                    placeholder={t("Search channel")}
                    maxLength={25}
                    ref={inputRef}
                  />

                  { channelInputValue && <CloseIcon onClick={onClearInputIcon} /> }
                </Form.Group>
              </Form>

              {channelInputFocus !== null && (
                <div
                  onClick={() => {
                    setChannelInputValue("");
                    setChannelInputFocus(false);
                    horizontalScrollTo(0);
                  }}
                  className={`cancel-button ${
                    pageWithoutHeader
                      ? "cancel-button-show"
                      : "cancel-button-hide"
                  }${!channelInputFocus ? "library-form-wrapper-focus" : ""}`}
                >
                  {t("Cancel")}
                </div>
              )}
            </div>
          </div>
        </div>
        
        {!channelInputValue && !channelStateValue ? (
          <div onTouchStart={() => inputRef.current?.blur()}>
            <CategoriesSlider categories={categoriesToShow} />

            {categoriesToShow.map((category) => {
                if (category.code === "blogs") {
                  return (
                    <BlogsSider key={category.id} category={category} />
                  )
                } else return null;
              })}

              <div className="libraries-list-wrapper">
                {categoriesToShow?.map((item) => {
                  if (categoryWithChannels.includes(item.code)) {
                    return <LibrariesList key={item.id} category={item} />;
                  } else {
                    return null;
                  }
                })}
              </div>

              <div>
                <CategoriesList categories={categoriesToShow} />
              </div>
            </div>
        ) : (
          <SearchChannel />
        )}

      <span
        ref={simulateClickRef}
        className="opacity-click-simulate-element"
        onClick={onFocus} />

      </div>
  );
};

export default Library;
