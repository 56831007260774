import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { APP_COLOR_HINT, FeedModel, PLATFORM_IOS, PLATFORM_WEB, webApp } from "../models/models";
import { openTelegramLink, showMainTelegramButton, unmountedTelegramButton } from "../models/functions";
import { getFeed } from "../hooks/api";

import Htag from "../components/Htag";
import Load from "../components/fetch/Load";

import { ReactComponent as CheckmarkIcon } from "../icons/checkmark-circle.svg";

import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import "../styles/SubscribeFeed.css"

const SubscribeFeed: FC = () => {

	const { t } = useTranslation();

	const navigate = useNavigate();

	const params = useParams();
	const feedId = params.feedId as unknown as number;

	const [inviteUrl, setInviteUrl] = useState<string>("");

	const { data, isRefetching } = useQuery<FeedModel, Error>({
		queryKey: ["feed", feedId],
		queryFn: () => getFeed(feedId),
		refetchInterval: () => !inviteUrl ? 1500 : false
	});

	const isLoading = !inviteUrl || !feedId || !data || data.status !== "created";

	const onBackButtonClick = () => {
		navigate(-2);
	}

	useEffect(() => {
		if(isLoading) return;
		webApp.MainButton.color = webApp.themeParams.button_color;
		webApp.MainButton.enable();
		webApp.MainButton.hideProgress();
	}, [isLoading])

	useEffect(() => {
		webApp.MainButton.showProgress();
		webApp.MainButton.text = t("Creating feed...");
		webApp.MainButton.color = APP_COLOR_HINT;
		webApp.MainButton.disable();

		webApp.BackButton.onClick(onBackButtonClick);

		if(isLoading) return;
		
		const onClick = () => {
			openTelegramLink(inviteUrl);
			if(PLATFORM_IOS || PLATFORM_WEB) {
				navigate("/feeds/details/" + feedId, { replace: false, state: { pageFrom: "subscribe" } });
			}
		}

		showMainTelegramButton(onClick, t("Subscribe"))
		return () => {
			unmountedTelegramButton(onClick);
			webApp.BackButton.offClick(onBackButtonClick);
		}
	}, [])

	useEffect(() => {
		const onClick = () => {
			openTelegramLink(inviteUrl);
			if(PLATFORM_IOS || PLATFORM_WEB) {
				navigate("/feeds/details/" + feedId, { replace: false, state: { pageFrom: "subscribe" } });
			}
		}

		if(isLoading) return;
		
		showMainTelegramButton(onClick, t("Subscribe"))
		return () => unmountedTelegramButton(onClick)
	}, [inviteUrl])

	useEffect(() => {
		if (!data) return
		setInviteUrl(data.invite_url)
	}, [data, isRefetching])

	if (isLoading) return <Load fullscreen />

	return (
		<div className="subscribe-feed-wrapper">

			<div className="subscribe-feed-svg-wrapper">
				<CheckmarkIcon />
			</div>

			<div className="subscribe-feed-text-wrapper" >
				<Htag tag="h4">{t("Feed")} {t("Successfully created")}</Htag>
				<p>{t("Create feed - info")}</p>
			</div>

		</div>
	);
};

export default SubscribeFeed;