import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';

const CategoryDetailPlaceholder = () => {
	return (
		<Card className="border-0 libraries-list-card-placeholder pb-3 px-0">

			<Placeholder as="div" animation="glow" className="category-detail-card-placeholder-banner-wrapper">
				<Placeholder xs={1} bg="secondary" className="bg-placeholder-secondary" />
			</Placeholder>

			<ListGroup className="libraries-list-group">
				{
					[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
						return (
							<ListGroup.Item
								key={item + index}
								className="px-3 d-flex justify-content-start align-items-center libraries-list-card-placeholder-list-item border-0">

								<Placeholder as="div" animation="glow">
									<Placeholder xs={1} bg="secondary" className="libraries-list-card-placeholder-image bg-placeholder-secondary" />
								</Placeholder>

								<Placeholder as="div" animation="glow" className="libraries-list-card-placeholder-list-item-text">
									<Placeholder xs={10} bg="secondary" size="xs" className="bg-placeholder-secondary" />
									<Placeholder xs={4} bg="secondary" size="xs" className="bg-placeholder-secondary mt-2" />
								</Placeholder>


								<Placeholder as="div" animation="glow" className="libraries-list-card-placeholder-list-item-icon">
									<Placeholder xs={1} bg="primary" className="bg-placeholder-primary" />
								</Placeholder>

							</ListGroup.Item>
						)
					})
				}
			</ListGroup>
		</Card>
	)
}

export default CategoryDetailPlaceholder;