import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { selectCategoryData } from "../../models/functions";
import { MetaDataCategory, CategoriesSliderProps } from "../../models/models";
import { useCategoriesSliderPositionX } from "../../store";

import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

import "../../styles/CategoriesSlider.css"

const CategoriesSlider = ({ categories }: CategoriesSliderProps) => {

	const navigate = useNavigate();

	const { t } = useTranslation();

	const sliderRef = useRef<HTMLDivElement>(null);

	const { xCoord, updateXCoord } = useCategoriesSliderPositionX();

	const [categoryImage, setCategoryImage] = useState<boolean>(false);

	useLayoutEffect(() => {
		if (!xCoord) return;
		if (sliderRef.current) {
			sliderRef.current.scrollTo({ top: 0, left: xCoord, behavior: "instant" });
			setTimeout(() => updateXCoord(0), 0)
		}
	}, [])

	if (!categories) return <></>;

	const items = categories.map((item: MetaDataCategory) => {
		const { id, code } = item;

		const categoryName = t(selectCategoryData(code).categoryName);

		const navigateParams = {
			name: categoryName,
			code: code
		}

		return (
			<div
				key={id}
				className="categories-slider-item-wrapper"
				onClick={() => {
					navigate("/library/categories/" + id, { replace: false, state: navigateParams });
					sliderRef.current && updateXCoord(sliderRef.current?.scrollLeft)
				}}>

				<div className="categories-slider-item">
					<div className="categories-slider-item-description">
						<p>{categoryName}</p>
					</div>
					<Image
						onLoad={() => setCategoryImage(true)}
						style={categoryImage ? {} : { display: "none" }}
						className="categories-slider-item-img"
						src={selectCategoryData(code).sliderImage}
						alt={categoryName} />
				</div>

			</div>
		)
	});

	return (
		<div
			className="categories-slider-wrapper disable-scrollbar"
			ref={sliderRef}>
			{items}
		</div>
	)
}

export default CategoriesSlider;