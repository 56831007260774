import { BaseSyntheticEvent, FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { getFeeds, useCreateFeed } from '../hooks/api';
import { APP_COLOR_HINT, FeedModel, MAX_LENGTH_FEED_NAME, webApp } from "../models/models";
import { 
  checkEmojiInText, 
  showLimitFeedNameValueLength, 
  showMainTelegramButton, 
  unmountedTelegramButton, 
  checkDuplicateFeed 
} from "../models/functions";
import { useOnlineStatus } from "../hooks/useOnlineStatus";

import Htag from "../components/Htag";
import ProductTourCreateFeed from "../components/ProductTour/CreateFeed";
import { useProductTour_CreateFeed } from "../store";
import useChangeHeaderColorInModal from "../hooks/useChangeHeaderColorInModal";

import { ReactComponent as Spinner } from "../icons/spinner.svg"
import { ReactComponent as CheckMarkIcon } from "../icons/checkmark.svg";

import Form from 'react-bootstrap/Form';

import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import "../styles/CreateFeed.css";

const CreateFeed: FC = () => {

  const isOnline = useOnlineStatus();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { statusProductTour: statusProductTourCreateFeed } = useProductTour_CreateFeed();

  const [validated, setValidated] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false);
  const [duplicateFeedName, setDuplicateFeedName] = useState<boolean>(false);
  const [emojiDetected, setEmojiDetected] = useState<boolean>(false);

  const simulateClick = useRef<HTMLDivElement>(null);

  const {
    mutate,
    isPending,
    isError,
    isIdle,
    data
  } = useCreateFeed();

  const {
    data: feeds,
  } = useQuery<FeedModel[], Error>({
    queryKey: ["feeds"],
    queryFn: getFeeds,
    refetchOnWindowFocus: false,
  });

  const feed = data?.data.feed as FeedModel;

  const checkOnOnlySpaces = (str: string) => str.length > 0 && /^\s*$/.test(str);

  const creatingFeedProgress = isPending || loadingNextPage || webApp.MainButton.isProgressVisible;
  const enableButton = !creatingFeedProgress && validated && inputValue.length <= MAX_LENGTH_FEED_NAME && inputValue.length > 0 && !webApp.MainButton.isProgressVisible;

  const onSubmit = () => {
    if(inputValue) {
      mutate({ title: inputValue })
    }
  }

  useChangeHeaderColorInModal(statusProductTourCreateFeed);

  useEffect(() => {
    showMainTelegramButton(onSubmit,t("Create"));

    if(!inputValue) {
      webApp.MainButton.disable();
    } else {
      webApp.MainButton.enable();
    }

    return () => unmountedTelegramButton(onSubmit);
  }, [inputValue])

  useEffect(() => {
    if(creatingFeedProgress || !enableButton) {
      webApp.MainButton.disable();
      webApp.MainButton.color = APP_COLOR_HINT;
    } else {
      webApp.MainButton.color = webApp.themeParams.button_color;
    } 
  }, [enableButton, inputValue, creatingFeedProgress])

  useEffect(() =>{
    if (creatingFeedProgress) {
      webApp.MainButton.showProgress();
      webApp.MainButton.text = t("Creating feed...");
    } else {
      webApp.MainButton.hideProgress();
      webApp.MainButton.text = t("Create");
    }
  }, [creatingFeedProgress])

  useEffect(() => {
    if (!isIdle && data) {
      setLoadingNextPage(true)
      setTimeout(() => {
        simulateClick.current?.click();
      }, 3000);
    }
  }, [data, isIdle, navigate])

  const onChange = (event: BaseSyntheticEvent) => {

    if (creatingFeedProgress) return;

    const value = event.target.value.replace(/\s+/g, ' ');

    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget.checkValidity()) {

      const duplicateFeed = feeds && checkDuplicateFeed(feeds, value);

      if (checkOnOnlySpaces(value)) {
        setValidated(false)
        return
      }

      if (duplicateFeed) {
        setInputValue(value)
        setValidated(false)
        setDuplicateFeedName(true)
        return
      }

      if (checkEmojiInText(value)) {
        setInputValue(value)
        setValidated(false)
        setEmojiDetected(true)
        return
      }

      setEmojiDetected(false)
      setDuplicateFeedName(false)
      setValidated(true)
      setInputValue(value)
    } else {

      if (inputValue.length === MAX_LENGTH_FEED_NAME) return;

      setValidated(false)
      setInputValue("")
    }
  }

  const postStatus = () => {
    if (isError) {
      return <p className="text-danger request-info-subtitle">{t("Error - refresh page")}</p>
    } else if (creatingFeedProgress) {
      return <></>
    } else if (!isError && !isPending && duplicateFeedName) {
      return <p className="text-danger request-info-subtitle">{t("Error - duplicate feed")}</p>
    } else if (!isOnline) {
      return <p className="text-danger request-info-subtitle">{t("Error - offline")}</p>
    } else if (!isError && !isPending && emojiDetected && inputValue) {
      return <p className="text-danger request-info-subtitle">{t("Error - emoji")}</p>
    } else return <></>
  }

  const feedNameValidation = () => {
    if (!creatingFeedProgress && inputValue && (MAX_LENGTH_FEED_NAME - inputValue.length) <= 3) {
      return <div className="create-feed-form-status-icon">{showLimitFeedNameValueLength(inputValue)}</div>
    } else if (validated && !creatingFeedProgress && inputValue) {
      return <div className="create-feed-form-status-icon"><CheckMarkIcon /></div>
    } else if (validated && creatingFeedProgress && inputValue) {
      return <div className="create-feed-form-status-icon"><Spinner className="spinner" /></div>
    } else return <></>
  }

  return (
    <>
      <Htag tag="h3">
        {
          !creatingFeedProgress
            ? t("New feed")
            : t("Creating feed")
        }
      </Htag>

      <Form 
        onSubmit={(e) => e.preventDefault()} 
        id="product_tour_create_feed"
      >

        <Form.Group
          className="create-feed-form rounded"
          controlId="create-feed-input">

          <Form.Control
            autoFocus
            type="text"
            as="input"
            maxLength={MAX_LENGTH_FEED_NAME}
            value={inputValue}
            onChange={onChange}
            className="create-feed-form-input"
            name="title"
            placeholder={t("Name feed")}
          />

          {feedNameValidation()}

        </Form.Group>

        {postStatus()}

      </Form>

      <ProductTourCreateFeed />

      <span
        ref={simulateClick}
        className="opacity-click-simulate-element"
        onClick={() => navigate("/feeds/subscribe/" + feed.id)} />
    </>
  );
};

export default CreateFeed;