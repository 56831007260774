import { useNavigate } from "react-router-dom";

import { HelpModalProps, THEME_DARK, webApp } from "../../models/models";
import { openTelegramLink, setAppSecondaryColor } from "../../models/functions";
import { 
	useProductTour,
	useProductTour_AddChannelFromFeed, 
	useProductTour_CreateFeed, 
	useProductTour_SwipeChannel, 
	useProductTour_SwipeFeed, 
	useSelectMethodToAddChannel
} from "../../store";
import useChangeHeaderColorInModal from "../../hooks/useChangeHeaderColorInModal";

import { ReactComponent as ReportIcon } from "../../icons/report.svg"
import { ReactComponent as StartTourIcon } from "../../icons/start-tour.svg"
import { ReactComponent as ReferenceIcon } from "../../icons/reference.svg"
import { ReactComponent as SupportIcon } from "../../icons/support.svg"

import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import { useTranslation } from "react-i18next";

const HelpModal = ({
	show,
	onHide,
}: HelpModalProps) => {

	const { t } = useTranslation();

	const navigate = useNavigate();

	const { updateStatusProductTour } = useProductTour();
	const { updateStatusProductTour: updateStatusProductTour_CreateFeed } = useProductTour_CreateFeed();
	const { updateStatusProductTour: updateStatusProductTour_AddChannelFromFeed } = useProductTour_AddChannelFromFeed();
	const { updateStatusProductTour: updateStatusProductTour_SwipeFeed } = useProductTour_SwipeFeed();
	const { updateStatusProductTour: updateStatusProductTour_SwipeChannel } = useProductTour_SwipeChannel();

	const { changeMethod } = useSelectMethodToAddChannel();

	useChangeHeaderColorInModal(show);

	return (
		<Modal
			centered
			animation={false}
			show={show}
			onHide={onHide}
			dialogClassName="custom-modal modal-help"
			aria-labelledby="modal-help">

			<Modal.Header className="custom-modal-header-wrapper">
			<p className="custom-modal-header text-hint">{t("Help")}</p>
			</Modal.Header>

			<Modal.Body>
				<ListGroup className="custom-list-wrapper">

					<ListGroup.Item
						className="custom-list-wrapper-item background-list-item"
						onClick={() => {
							setAppSecondaryColor();
							navigate("/settings/feedback");
							onHide();
					}}>
						<div className="circle-icon-wrapper">
							<ReportIcon />
						</div>

						<p className={`custom-list-wrapper-title custom-wrap-text-ellipsis text-color ${THEME_DARK ? "text-white border-bottom_after-dark" : "border-bottom_after"}`}>
							{t("Report a problem")}
						</p>
					</ListGroup.Item>

					<ListGroup.Item
						className="custom-list-wrapper-item background-list-item"
						onClick={() => {
							navigate("/");
							updateStatusProductTour_CreateFeed(true);
							updateStatusProductTour(true);
							updateStatusProductTour_AddChannelFromFeed(true);
							updateStatusProductTour_SwipeFeed(true);
							updateStatusProductTour_SwipeChannel(true);
							changeMethod("");
							onHide();
					}}>
						<div className="circle-icon-wrapper">
							<StartTourIcon />
						</div>

						<p className={`custom-list-wrapper-title custom-wrap-text-ellipsis text-color ${THEME_DARK ? "text-white border-bottom_after-dark" : "border-bottom_after"}`}>
							{t("Start training")}
						</p>
					</ListGroup.Item>
					
					<ListGroup.Item 
						className="custom-list-wrapper-item background-list-item"
						onClick={() => {
							setAppSecondaryColor();
							webApp.openLink("https://telegrok.slite.page/p/FrrRKaBD3izK74/Kak-nachat-polzovatsya-Telegrok");
						}}
					>
						<div className="circle-icon-wrapper">
							<ReferenceIcon />
						</div>

						<p className={`custom-list-wrapper-title custom-wrap-text-ellipsis text-color ${THEME_DARK ? "text-white border-bottom_after-dark" : "border-bottom_after"}`}>
							{t("Reference")}
						</p>
					</ListGroup.Item>

					<ListGroup.Item 
							className="custom-list-wrapper-item background-list-item"
							onClick={() => {
								setAppSecondaryColor();
								openTelegramLink("https://t.me/TelegrokOfficialGroup");
							}}
					>
						<div className="circle-icon-wrapper">
							<SupportIcon />
						</div>

						<p className={`custom-list-wrapper-title custom-wrap-text-ellipsis text-color ${THEME_DARK && "text-white"}`}>
							{t("Support")}
						</p>
					</ListGroup.Item>
				</ListGroup>
			</Modal.Body>

      </Modal>
	)
}

export default HelpModal;