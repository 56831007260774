import { useEffect } from "react";
import { 
	PLATFORM_IOS, 
	THEME_DARK, 
	webApp
} from "../models/models";

export default function useChangeHeaderColorFirstLoad() {

	useEffect(() => {
		if (PLATFORM_IOS && THEME_DARK) {
			webApp.setBackgroundColor(webApp.themeParams.bg_color);
		} else {
			webApp.setBackgroundColor(webApp.themeParams.secondary_bg_color);
		}
		webApp.setHeaderColor(webApp.backgroundColor as "bg_color");
	}, [])

}