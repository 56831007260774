import { SwitchButtonProps } from "../models/models";

import "../styles/SwitchButton.css";

const SwitchButton = ({onChange, state}: SwitchButtonProps):JSX.Element => {
	return (
	  <label className="switch-button-wrapper">
		<input 
			type="checkbox" 
			checked={state} 
			onChange={onChange} 
		/>
		<span className="switch-button" />
	  </label>
	);
};

export default SwitchButton;