import { PromptModalProps, PLATFORM_WEB } from "../../models/models";
import { ReactComponent as ArrowIcon } from "../../icons/arrow.svg"

import "../../styles/PromptModal.css";

const PromptModal = ({ text }: PromptModalProps) => (
	<section className="help_info-modal-wrapper">

		<div className="help_info-modal">
			<div className="help_info-modal-content">
				<div className="help_info-modal-arrows">
					<ArrowIcon />
					<ArrowIcon />
				</div>

				<p className="help_info-modal-text">
					{text}
				</p>
			</div>

			<span className={`help_info-bg_triangle ${PLATFORM_WEB ? "help_info-bg_triangle_web" : ""}`} />
		</div>

	</section>
)

export default PromptModal;