import { ReactNode } from "react";

import '../styles/Htag.css'

interface HtagProps {
	tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
	textAlign?: "center" | "left";
	textColor?: string;
	onClick?: () => void;
	children: ReactNode;
}

const Htag = ({
	tag,
	children,
	textAlign = "center",
	textColor = "text-color",
	onClick = undefined
}: HtagProps): JSX.Element => {

	const textPosition = textAlign === "left" ? "text-left" : "text-center";

	switch (tag) {
		case 'h1':
			return <h1 onClick={onClick} className={`${textColor} ${textPosition} htag-h1 htag`}>{children}</h1>;
		case 'h2':
			return <h2 onClick={onClick} className={`${textColor} ${textPosition} htag-h2 htag`}>{children}</h2>;
		case 'h3':
			return <h3 onClick={onClick} className={`${textColor} ${textPosition} htag-h3 htag`}>{children}</h3>;
		case 'h4':
			return <h4 onClick={onClick} className={`${textColor} ${textPosition} htag-h4 htag`}>{children}</h4>;
		case 'h5':
			return <h5 onClick={onClick} className={`${textColor} ${textPosition} htag-h5 htag`}>{children}</h5>;
		default:
			return <></>;
	}
}

export default Htag;