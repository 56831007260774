import { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  useNavigate,
  useLocation,
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";

import { APP_COLOR_HINT, FeedModel, MODAL_BG_COLOR_DARK, PLATFORM_IOS, THEME_DARK, webApp } from "./models/models";
import { NotificationProvider } from "./context/notification";
import ScrollToTop from "./hooks/ScrollToTop";
import { getFeeds, useAddToFavorites, useRemoveFromFavorites } from "./hooks/api";
import { useProductTour, useShowModal_Favorites } from "./store";

import Feeds from "./screens/Feeds";
import FeedDetails from "./screens/FeedDetails";
import FeedDetailsSettings from "./screens/FeedDetailsSettings";
import CreateFeed from "./screens/CreateFeed";
import SubscribeFeed from "./screens/SubscribeFeed";
import LinkToChannel from "./screens/LinkToChannel";
import Categories from "./screens/Categories";
import CategoryDetail from "./screens/CategoryDetail";
import ChannelDetail from "./screens/ChannelDetail";
import AddChannelFromBotLink from "./screens/AddChannelFromBotLink";
import AddChannelFromSubscriptions from "./screens/AddChannelFromSubscriptions";
import Library from "./screens/Library";
import Settings from "./screens/Settings";
import Feedback from "./screens/Feedback";
import Language from "./screens/Language";
import Country from "./screens/Country";
import ForwardRedirectPage from "./screens/ForwardRedirectPage";
import FeedDetailsSettingsPublicationMethod from "./screens/FeedDetailsSettingsPublicationMethod";

import NavigationMenu from "./components/NavigationMenu";
import ProductTour from "./components/ProductTour/Onboarding";
import FavoriteActionModal from "./components/Modals/FavoriteActionModal";

import {
  useThemeParams,
  BackButton,
} from "@vkruglikov/react-telegram-web-app";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import i18n from "./i18n";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/CustomModal.css";
import "./styles/index.css";
import "./styles/_vars.css";
import "./styles/_themes.css";
import "./styles/_global.css";

const queryClient = new QueryClient();

function checkPrefixExternalTransitionInApp(str: string) {
  if(str.startsWith("feed-")) {
    return { type: "feed", id: str.slice(5) }
  } else if (str.startsWith("campaign-")) {
    return { type: "campaign", id: str.slice(9) }
  } else if (str.startsWith("add-to-favorites-")) {
    return { type: "add-to-favorites", id: str.slice(17) }
  } else if (str.startsWith("remove-from-favorites-")) {
    return { type: "remove-from-favorites", id: str.slice(22) }
  } 
}

const App = () => {

  const [colorScheme, themeParams] = useThemeParams();

  const navigate = useNavigate();
  const location = useLocation();

	const { updateStatusProductTour, statusProductTour } = useProductTour();
	// const { show: showFavoritesModalStore, setShow: setShowFavoritesModalStore } = useShowModal_Favorites();

  // const [favoritesType, setFavoritesType] = useState<"add-to-favorites" | "remove-from-favorites">();
  // const [showFavoritesModalPermission, setShowFavoritesModalPermission] = useState<boolean>(true);

  // const onHideFavoritesModal = () => {
  //   setShowFavoritesModalStore(false);
  //   setShowFavoritesModalPermission(false);
  // }

  let [searchParams] = useSearchParams();
  const paramsExternalTransitionInApp = searchParams.get("tgWebAppStartParam") as string;

  const { data: feeds } = useQuery<FeedModel[], Error>({
    queryKey: ["feeds"],
    queryFn: getFeeds,
    retry: 1
  });

  // const { mutate: removeFromFavoritesMethod } = useRemoveFromFavorites();
  // const { mutate: addToFavoritesMethod } = useAddToFavorites();

  useEffect(() => {
    if (PLATFORM_IOS && THEME_DARK) return;
    document.body.style.background = webApp.themeParams.secondary_bg_color;
  }, [])

  useEffect(() => {
    if (!PLATFORM_IOS) return;

    const root = document.documentElement;

    if (THEME_DARK) {
      root.style.setProperty(
        "--body-bg",
        webApp.themeParams.secondary_bg_color
      );
      root.style.setProperty("--body-bg_s", webApp.themeParams.bg_color);

      if (statusProductTour) {
        webApp.setBackgroundColor(MODAL_BG_COLOR_DARK);
      } else {
        webApp.setBackgroundColor(webApp.themeParams.bg_color);
      }
      webApp.setHeaderColor(webApp.backgroundColor as "bg_color" | "secondary_bg_color");

      document.body.style.background = webApp.themeParams.bg_color;
    } else {
      root.style.setProperty("--body-bg", webApp.themeParams.bg_color);
      root.style.setProperty(
        "--body-bg_s",
        webApp.themeParams.secondary_bg_color
      );
    }
  }, []);

  useEffect(() => {
    if(!paramsExternalTransitionInApp || !feeds) return;
    const prefix = checkPrefixExternalTransitionInApp(paramsExternalTransitionInApp);

    if(!prefix) return;

    function checkAvailabilityFeed() {
      if(!feeds || prefix?.type !== "feed") return false;
      if(feeds.filter(feed => feed.id.toString() === prefix.id).length > 0) {
        return true
      } else {
        return false
      }
    }

    // if(prefix.type === "add-to-favorites" && showFavoritesModalPermission) {
    //   setFavoritesType(prefix.type);
    //   setShowFavoritesModalStore(true);
    //   addToFavoritesMethod(prefix.id);
    //   setShowFavoritesModalPermission(false);
    //   return;
    // } else if (prefix.type === "remove-from-favorites" && showFavoritesModalPermission) {
    //   setFavoritesType(prefix.type);
    //   setShowFavoritesModalStore(true);
    //   removeFromFavoritesMethod(prefix.id);
    //   setShowFavoritesModalPermission(false);
    //   return;
    // }

    if (prefix.type === "feed" && checkAvailabilityFeed()) {
      webApp.MainButton.text = "⌛";
      if(statusProductTour) {
        updateStatusProductTour(false);
      }
      webApp.BackButton.hide();
      navigate(`/feeds/details/${prefix.id}`, { replace: false, state: { pageFrom: "channelFeed" } });
    } else if(prefix.type === "feed" && !checkAvailabilityFeed()) {
      navigate("/from-forward-redirect", { replace: false, state: { link: `/?utm_source=feed&utm_medium=${prefix.id}`} });
      webApp.BackButton.hide();
    } else if (prefix.type === "campaign"){
      navigate("/from-forward-redirect", { replace: false, state: { link: `/?utm_source=campaign&utm_medium=${prefix.id}`} });
      webApp.BackButton.hide();
    }
  }, [feeds]);

  const BackButtonComponent = () => {
    const onNavigate = () => {
      if (location.key !== "default") return navigate(-1);
    };
    return (
      <>
        {location.key !== "default" &&
          !location.pathname.includes("/library/channel/") &&
          !location.pathname.includes("/feeds/details/") && (
            <BackButton onClick={onNavigate} />
          )}
      </>
    );
  };

  return (
    <div data-bs-theme={colorScheme}>

      <BackButtonComponent />

      { statusProductTour && <ProductTour /> }

      <div className="content-wrapper disable-scrollbar">

        <NavigationMenu />

        {/* <FavoriteActionModal 
          show={showFavoritesModalStore}
          onHide={onHideFavoritesModal}
          type={favoritesType}
        /> */}

        <div id="product-tour-main-button" />

        <Routes>
          {/* <Route path="/folder-chats" element={<FolderChats />} /> */}
          {/* <Route path="/feeds/folder" element={<FolderChats />} /> */}

          <Route path="/" element={<Feeds />} />

          <Route path="/feeds/details/:feedId" element={<FeedDetails />} />
          <Route path="/feeds/:feedId/settings" element={<FeedDetailsSettings />} />
          <Route path="/feeds/:feedId/settings/publication-method" element={<FeedDetailsSettingsPublicationMethod />} />
          <Route path="/feeds/create" element={<CreateFeed />} />
          <Route path="/feeds/subscribe/:feedId" element={<SubscribeFeed />} />

          <Route path="/feeds/channel" element={<AddChannelFromBotLink />} />

          <Route path="/link-to-channel" element={<LinkToChannel />} />
          <Route path="/add-channel-from-subscriptions" element={<AddChannelFromSubscriptions />} />
          <Route path="/library" element={<Library />} />
          <Route
            path="/library/channel/:channelId"
            element={<ChannelDetail />}
          />
          <Route path="/library/categories" element={<Categories />} />
          <Route
            path="/library/categories/:categoryId"
            element={<CategoryDetail />}
          />

          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/language" element={<Language />} />
          <Route path="/settings/feedback" element={<Feedback />} />
          <Route path="/settings/country" element={<Country />} />

          <Route path="/from-forward-redirect" element={<ForwardRedirectPage />} />
          
        </Routes>
      </div>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

webApp.MainButton.text = i18n.t("Create Feed");
webApp.MainButton.color = APP_COLOR_HINT;
webApp.MainButton.hide();

root.render(
  <BrowserRouter basename="/">
    <NotificationProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<></>}>
          <ScrollToTop />
          <App />
        </Suspense>
      </QueryClientProvider>
    </NotificationProvider>
  </BrowserRouter>
);
