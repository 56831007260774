import { FC } from 'react';
import { useNavigate } from "react-router-dom";

import { MetaDataCategories, MetaDataCategory } from '../../models/models';
import { selectCategoryData } from "../../models/functions";

import Htag from "../Htag";

import { ReactComponent as ArrowIcon } from "../../icons/arrow.svg"

import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { useTranslation } from "react-i18next";

import "../../styles/LibrariesList.css"

const CategoriesList: FC<{
	categories: MetaDataCategories,
	allCategories?: boolean
}> = ({ categories, allCategories = false }) => {

	const { t } = useTranslation();

	const navigate = useNavigate();

	const topCategories = [
		"babies",
		"beauty",
		"economics",
		"marketing",
		"sales",
		"books",
		"handmade",
		"health",
		"entertainment",
	];

	const showAllCategories = (item: MetaDataCategory) => allCategories ? true : topCategories.includes(item.code);

	const listItems = categories.map((item) => {
		const { id, code } = item;

		const navigateParams = {
			name: t(selectCategoryData(code).categoryName),
			code: code
		}

		if (showAllCategories(item)) {
			return (
				<ListGroup.Item
					key={id}
					onClick={() => navigate("/library/categories/" + id, { replace: false, state: navigateParams })}
					className="background-list-item libraries-list-channel-item categories-list">

					<Image
						src={selectCategoryData(code).previewImage}
						alt='&#9785;'
						roundedCircle
						className="preview-icon" />

					<div className="channel-item-description-wrapper border-bottom">
						<div className="channel-item-description categories-list-item-text-wrapper custom-wrap-text-ellipsis">
							<p className="categories-list-item-text">
								{t(selectCategoryData(code).categoryName)}
							</p>
						</div>

						<div className="categories-list-item-icon-wrapper">
							<ArrowIcon />
						</div>
					</div>

				</ListGroup.Item>
			)
		} else {
			return null;
		}

	});

	return (
		<Card className={`background-list-item border-0 libraries-list-card ${allCategories ? "m-0" : ""}`}>

			{
				!allCategories && (
					<div className="background-list-item libraries-list-card-headline">
						<Htag tag="h4" textAlign="left">{t("Top categories")}</Htag>

						<Button
							onClick={() => navigate("/library/categories/")}
							className="p-0"
							variant="link">
							{t("All")}
						</Button>
					</div>
				)
			}

			{categories.length > 0 && (
				<ListGroup className="background-list-item libraries-list-group categories-list-group">
					{listItems}
				</ListGroup>
			)}

		</Card>
	);
};

export default CategoriesList;