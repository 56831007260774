import { useEffect, useState } from "react";

export const useDetectReloadApp = () => {
	const [reload, setReload] = useState(false);

	useEffect(() => {
		const navigationEntries = window.performance.getEntriesByType('navigation');
		// @ts-ignore
		if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
			setReload(true)
		}
	}, []);

	return reload;
};