import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useShowModal_Help, useToggleHeaderCatalog } from "../store";

import HelpModal from "./Modals/HelpModal";

import { ReactComponent as FeedsIcon } from "../icons/feeds.svg"
import { ReactComponent as LibraryIcon } from "../icons/library.svg"
import { ReactComponent as SettingsIcon } from "../icons/settings.svg"
import { ReactComponent as HelpIcon } from "../icons/help-circle.svg"

import { useTranslation } from "react-i18next";
import { useExpand } from "@vkruglikov/react-telegram-web-app";

import "../styles/NavigationMenu.css";

const NavigationMenu = () => {

	const { t } = useTranslation();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const {channelInputFocusStore, updateChannelInputFocusStore, showSearchInputStore } = useToggleHeaderCatalog();
	const {show: showHelpModal, setShow: setShowHelpModal} = useShowModal_Help();

	const feedsPage = pathname === "/";
	const libraryPage = pathname === "/library";
	const settingsPage = pathname === "/settings";
	const forwardRedirectPage = pathname === "/from-forward-redirect";

	const [activeItem, setActiveItem] = useState<string>(feedsPage ? "/" : pathname);

	const [isExpanded, expand] = useExpand();
	
	const onHideHelpModal = () => {
		setShowHelpModal(false);
	}

	useEffect(() => {
		if (showHelpModal && !isExpanded) {
			onHideHelpModal();
		}
	}, [isExpanded])

	useEffect(() => {
		updateChannelInputFocusStore(null);
	}, [pathname])

	useEffect(() => {
		if(showHelpModal) {
			setActiveItem("/help");
		} else if (feedsPage || forwardRedirectPage) {
			setActiveItem("/");
		} else {
			setActiveItem(pathname);
		}
	}, [pathname, showHelpModal])

	const onNavigate = (page: string) => {
		if (page === activeItem) return;
		navigate(page)
	}

	const selectAсtiveItemsStyles = (page: string) => {
		if (page === activeItem) {
			return "navigation-menu-item-active"
		} else return ""
	}

	if (!feedsPage && !libraryPage && !settingsPage && !forwardRedirectPage) return <></>;

	return (
		<>
			<nav className={`
				navigation-menu-wrapper
				${libraryPage ? "navigation-menu-wrapper_library" : ""}
				${channelInputFocusStore !== null 
					? showSearchInputStore
						? "library-page-wrapper-header-navigation_hide"
						: "library-page-wrapper-header-navigation_show"
					: ""
				}
			`}>
				<div
					id="navigation-menu-item-feeds"
					onClick={() => onNavigate("/")}
					className={`navigation-menu-item ${selectAсtiveItemsStyles("/")}`}>
					<div className="navigation-menu-item-icon">
						<FeedsIcon />
					</div>
	
					<p className="navigation-menu-item-text">{t("Feeds")}</p>
				</div>
	
				<div
					id="navigation-menu-item-catalog"
					onClick={() => onNavigate("/library")}
					className={`navigation-menu-item ${selectAсtiveItemsStyles("/library")}`}>
					<div className="navigation-menu-item-icon">
						<LibraryIcon />
					</div>
	
					<p className="navigation-menu-item-text">{t("Catalog")}</p>
				</div>
	
				<div
					id="navigation-menu-item-help"
					onClick={() => setShowHelpModal(true)}
					className={`navigation-menu-item ${selectAсtiveItemsStyles("/help")}`}>
					<div className="navigation-menu-item-icon">
						<HelpIcon />
					</div>
	
					<p className="navigation-menu-item-text">{t("Help")}</p>
				</div>
	
				<div
					onClick={() => onNavigate("/settings")}
					className={`navigation-menu-item ${selectAсtiveItemsStyles("/settings")}`}>
					<div className="navigation-menu-item-icon">
						<SettingsIcon />
					</div>
	
					<p className="navigation-menu-item-text">{t("Settings")}</p>
				</div>
	
			</nav>
  
		  	<HelpModal 
				show={showHelpModal}
				onHide={onHideHelpModal}
			/>
		</>
	)
}

export default NavigationMenu;