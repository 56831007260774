// Categories slider - Begin
import Blog from '../images/categories/slider/Blog.png'
import News from '../images/categories/slider/News.png'
import Humor from '../images/categories/slider/Humor.png'
import Tech from '../images/categories/slider/Tech.png'
import Economy from '../images/categories/slider/Economy.png'
import Businness from '../images/categories/slider/Businness.png'
import Crypto from '../images/categories/slider/Crypto.png'
import Travel from '../images/categories/slider/Travel.png'
import Marketing from '../images/categories/slider/Marketing.png'
import Psychology from '../images/categories/slider/Psychology.png'
import Design from '../images/categories/slider/Design.png'
import Policy from '../images/categories/slider/Policy.png'
import Art from '../images/categories/slider/Art.png'
import Law from '../images/categories/slider/Law.png'
import Education from '../images/categories/slider/Education.png'
import Books from '../images/categories/slider/Books.png'
import Lingvo from '../images/categories/slider/Lingvo.png'
import Career from '../images/categories/slider/Career.png'
import Science from '../images/categories/slider/Science.png'
import Guides from '../images/categories/slider/Guides.png'
import Sport from '../images/categories/slider/Sport.png'
import Beauty from '../images/categories/slider/Beauty.png'
import Medicine from '../images/categories/slider/Medicine.png'
import Health from '../images/categories/slider/Health.png'
import Images from '../images/categories/slider/Images.png'
import Apps from '../images/categories/slider/Apps.png'
import Video from '../images/categories/slider/Video.png'
import Music from '../images/categories/slider/Music.png'
import Games from '../images/categories/slider/Games.png'
import Food from '../images/categories/slider/Food.png'
import Qoutes from '../images/categories/slider/Qoutes.png'
import Handmade from '../images/categories/slider/Handmade.png'
import Family from '../images/categories/slider/Family.png'
import Nature from '../images/categories/slider/Nature.png'
import Interiour from '../images/categories/slider/Interiour.png'
import Telegram from '../images/categories/slider/Telegram.png'
import Instagram from '../images/categories/slider/Instagram.png'
import Sales from '../images/categories/slider/Sales.png'
import Transport from '../images/categories/slider/Transport.png'
import Religion from '../images/categories/slider/Religion.png'
import Tarot from '../images/categories/slider/Tarot.png'
import Darknet from '../images/categories/slider/Darknet.png'
import Betting from '../images/categories/slider/Betting.png'
import Shock from '../images/categories/slider/Shock.png'
import Ero from '../images/categories/slider/Ero.png'
import Adult from '../images/categories/slider/Adult.png'
import Misc from '../images/categories/slider/Misc.png'
// Categories slider - End

// Categories pages - Begin
import Blog_page from '../images/categories/categoryDetailPages/Blog_wide.png'
import News_page from '../images/categories/categoryDetailPages/News_wide.png'
import Humor_page from '../images/categories/categoryDetailPages/Humor_wide.png'
import Tech_page from '../images/categories/categoryDetailPages/Tech_wide.png'
import Economy_page from '../images/categories/categoryDetailPages/Economy_wide.png'
import Businness_page from '../images/categories/categoryDetailPages/Businness_wide.png'
import Crypto_page from '../images/categories/categoryDetailPages/Crypto_wide.png'
import Travel_page from '../images/categories/categoryDetailPages/Travel_wide.png'
import Marketing_page from '../images/categories/categoryDetailPages/Marketing_wide.png'
import Psychology_page from '../images/categories/categoryDetailPages/Psychology_wide.png'
import Design_page from '../images/categories/categoryDetailPages/Design_wide.png'
import Policy_page from '../images/categories/categoryDetailPages/Policy_wide.png'
import Art_page from '../images/categories/categoryDetailPages/Art_wide.png'
import Law_page from '../images/categories/categoryDetailPages/Law_wide.png'
import Education_page from '../images/categories/categoryDetailPages/Education_wide.png'
import Books_page from '../images/categories/categoryDetailPages/Books_wide.png'
import Lingvo_page from '../images/categories/categoryDetailPages/Lingvo_wide.png'
import Career_page from '../images/categories/categoryDetailPages/Career_wide.png'
import Science_page from '../images/categories/categoryDetailPages/Science_wide.png'
import Guides_page from '../images/categories/categoryDetailPages/Guides_wide.png'
import Sport_page from '../images/categories/categoryDetailPages/Sport_wide.png'
import Beauty_page from '../images/categories/categoryDetailPages/Beauty_wide.png'
import Medicine_page from '../images/categories/categoryDetailPages/Medicine_wide.png'
import Health_page from '../images/categories/categoryDetailPages/Health_wide.png'
import Images_page from '../images/categories/categoryDetailPages/Images_wide.png'
import Apps_page from '../images/categories/categoryDetailPages/Apps_wide.png'
import Video_page from '../images/categories/categoryDetailPages/Video_wide.png'
import Music_page from '../images/categories/categoryDetailPages/Music_wide.png'
import Games_page from '../images/categories/categoryDetailPages/Games_wide.png'
import Food_page from '../images/categories/categoryDetailPages/Food_wide.png'
import Qoutes_page from '../images/categories/categoryDetailPages/Qoutes_wide.png'
import Handmade_page from '../images/categories/categoryDetailPages/Handmade_wide.png'
import Family_page from '../images/categories/categoryDetailPages/Family_wide.png'
import Nature_page from '../images/categories/categoryDetailPages/Nature_wide.png'
import Interiour_page from '../images/categories/categoryDetailPages/Interiour_wide.png'
import Telegram_page from '../images/categories/categoryDetailPages/Telegram_wide.png'
import Instagram_page from '../images/categories/categoryDetailPages/Instagram_wide.png'
import Sales_page from '../images/categories/categoryDetailPages/Sales_wide.png'
import Transport_page from '../images/categories/categoryDetailPages/Transport_wide.png'
import Religion_page from '../images/categories/categoryDetailPages/Religion_wide.png'
import Tarot_page from '../images/categories/categoryDetailPages/Tarot_wide.png'
import Darknet_page from '../images/categories/categoryDetailPages/Darknet_wide.png'
import Betting_page from '../images/categories/categoryDetailPages/Betting_wide.png'
import Shock_page from '../images/categories/categoryDetailPages/Shock_wide.png'
import Ero_page from '../images/categories/categoryDetailPages/Ero_wide.png'
import Adult_page from '../images/categories/categoryDetailPages/Adult_wide.png'
import Misc_page from '../images/categories/categoryDetailPages/Misc_wide.png'
// Categories pages - End

// Categories Icons - Begin
import BlogIcon from '../images/categories/icons/Blog.svg'
import NewsIcon from '../images/categories/icons/News.svg'
import HumorIcon from '../images/categories/icons/Humor.svg'
import TechIcon from '../images/categories/icons/Tech.svg'
import EconomyIcon from '../images/categories/icons/Economy.svg'
import BusinnessIcon from '../images/categories/icons/Businness.svg'
import CryptoIcon from '../images/categories/icons/Crypto.svg'
import TravelIcon from '../images/categories/icons/Travel.svg'
import MarketingIcon from '../images/categories/icons/Marketing.svg'
import PsychologyIcon from '../images/categories/icons/Psychology.svg'
import DesignIcon from '../images/categories/icons/Design.svg'
import PolicyIcon from '../images/categories/icons/Policy.svg'
import ArtIcon from '../images/categories/icons/Art.svg'
import LawIcon from '../images/categories/icons/Law.svg'
import EducationIcon from '../images/categories/icons/Education.svg'
import BooksIcon from '../images/categories/icons/Books.svg'
import LingvoIcon from '../images/categories/icons/Lingvo.svg'
import CareerIcon from '../images/categories/icons/Career.svg'
import ScienceIcon from '../images/categories/icons/Science.svg'
import GuidesIcon from '../images/categories/icons/Guides.svg'
import SportIcon from '../images/categories/icons/Sport.svg'
import BeautyIcon from '../images/categories/icons/Beauty.svg'
import MedicineIcon from '../images/categories/icons/Medicine.svg'
import HealthIcon from '../images/categories/icons/Health.svg'
import ImagesIcon from '../images/categories/icons/Images.svg'
import AppsIcon from '../images/categories/icons/Apps.svg'
import VideoIcon from '../images/categories/icons/Video.svg'
import MusicIcon from '../images/categories/icons/Music.svg'
import GamesIcon from '../images/categories/icons/Games.svg'
import FoodIcon from '../images/categories/icons/Food.svg'
import QoutesIcon from '../images/categories/icons/Qoutes.svg'
import HandmadeIcon from '../images/categories/icons/Handmade.svg'
import FamilyIcon from '../images/categories/icons/Family.svg'
import NatureIcon from '../images/categories/icons/Nature.svg'
import InteriourIcon from '../images/categories/icons/Interiour.svg'
import TelegramIcon from '../images/categories/icons/Telegram.svg'
import InstagramIcon from '../images/categories/icons/Instagram.svg'
import SalesIcon from '../images/categories/icons/Sales.svg'
import TransportIcon from '../images/categories/icons/Transport.svg'
import ReligionIcon from '../images/categories/icons/Religion.svg'
import TarotIcon from '../images/categories/icons/Tarot.svg'
import DarknetIcon from '../images/categories/icons/Darknet.svg'
import BettingIcon from '../images/categories/icons/Betting.svg'
import ShockIcon from '../images/categories/icons/Shock.svg'
import EroIcon from '../images/categories/icons/Ero.svg'
import AdultIcon from '../images/categories/icons/Adult.svg'
import MiscIcon from '../images/categories/icons/Misc.svg'
// Categories Icons - End

import {
	FeedModel,
	MAX_LENGTH_FEED_NAME,
	PLATFORM_IOS,
	PLATFORM_WEB,
	webApp,
	MetaDataCategories
} from "./models"

import i18n from "../i18n"

const adultsCategories = [
	"darknet",
	"shock",
	"erotica",
	"gambling",
	"adult"
];

export const languagesList = ["ru", "en"];

export const openTelegramLink = (url: string) => {
	if (PLATFORM_IOS || PLATFORM_WEB) {
		webApp.openTelegramLink(url);
	} else {
		webApp.openTelegramLink(url); webApp.close()
	}
}

export const createPreviewLink = (link: string, title = "") => {
	if (link.includes('/joinchat/')) {
		return `@${title}`.trim()
	} else {
		return `@${link.substring(link.indexOf('t.me/') + 5)}`
	}
}

export const checkDuplicateFeed = (
	feedsList: FeedModel[], 
	feedName_new: string, 
	feedName_current?: string
) => {
	if(feedName_current && feedName_current === feedName_new) return;
	return feedsList.filter((feed) => feed.title === feedName_new.trim()).length > 0
};

export const setAppSecondaryColor = () => {
	if (webApp.themeParams.secondary_bg_color) {
		webApp.setBackgroundColor(webApp.themeParams.secondary_bg_color);
		webApp.setHeaderColor(webApp.backgroundColor as "bg_color" | "secondary_bg_color");
	} 
}

// telegram button start
export const showMainTelegramButton = (addClick: () => void, text: string, color: string = webApp.themeParams.button_color) => {
	webApp.MainButton.color = color;
	webApp.MainButton.enable();
	webApp.MainButton.onClick(addClick);
    webApp.MainButton.text = text;
    webApp.MainButton.show();
}

export const unmountedTelegramButton = (removeClick: () => void) => {
	webApp.MainButton.offClick(removeClick);
	webApp.MainButton.disable();
}

export const showMainTelegramButton_UI = (text?: string, color: string= webApp.themeParams.button_color) => {
	if(text) {
		webApp.MainButton.text = text;
	}
	webApp.MainButton.color = color;
	webApp.MainButton.show();
	webApp.MainButton.enable();
}
  
export const unmountedTelegramButton_UI = (color: string= webApp.themeParams.button_color) => {
	webApp.MainButton.color = color;
	webApp.MainButton.disable();
	webApp.MainButton.show();
}

export const showTelegramBackButton = (addClick: () => void) => {
	webApp.BackButton.show();
	webApp.BackButton.onClick(addClick);
}

export const unmountedTelegramButtonInModal = (removeClick: () => void) => {
	webApp.MainButton.offClick(removeClick);
}

export const unmountedTelegramBackButton = (removeClick: () => void) => {
	webApp.BackButton.offClick(removeClick);
}
// telegram button end

export const showLimitFeedNameValueLength = (value: string) => {
	if (value.length <= MAX_LENGTH_FEED_NAME && value.length > (MAX_LENGTH_FEED_NAME - 4)) {
	  return <p className="text-hint">{MAX_LENGTH_FEED_NAME - value.length}</p>
	} else if (value.length > MAX_LENGTH_FEED_NAME) {
	  return <p className="text-danger">{MAX_LENGTH_FEED_NAME - value.length}</p>
	}
}

export const checkDuplicateChannels = (feed: FeedModel | null, channelId: number | null) => {
	if (!feed || !feed.channels || feed.channels.length < 1 || !channelId) return

	const arr = feed.channels.filter((channel) => channel.telegram_id === channelId);

	return arr.length > 0 ? true : false
}

export function checkEmojiInText(text: string) {
	const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Emoji_Modifier_Base}|\p{Emoji_Modifier}|[\u2600-\u26FF]|\u2705|\u2934-\u2935|\u2B05-\u2B07|\u2B1B-\u2B1C|\u2B50|\u2B55|\u3030|\u303D|\u3297|\u3299|\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDF9F\uDFA1-\uDFAF\uDFE0-\uDFE6\uDFE8-\uDFED\uDFF0-\uDFF4\uDFF7-\uDFF8\uDFFB-\uDFFF]|\uD83D[\uDC00-\uDDFF]|\uD83E[\uDD00-\uDDFF]/gu;
	return emojiRegex.test(text);
}

export const selectCategoriesToShow = (showAdults: boolean, categories: MetaDataCategories) =>
	showAdults
		? categories
		: categories.filter((item) => !adultsCategories.includes(item.code))

export function numberWithSpaces(num: number) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function getSubscribers(num: number) {

	if (num === 1) {
		return `${numberWithSpaces(num)} ${i18n.t("Subscriber")}`;
	} else if (num === 2 || num === 3 || num === 4) {
		return `${numberWithSpaces(num)} ${i18n.t("Subscribers 2,3,4")}`;
	} else if (num === 0 || (num >= 5 && num <= 100)) {
		return `${numberWithSpaces(num)} ${i18n.t("Subscribers")}`;
	} else {
		const lastDigit = num % 10;
		if (lastDigit === 1) {
			return `${numberWithSpaces(num)} ${i18n.t("Subscriber")}`;
		} else if (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) {
			return `${numberWithSpaces(num)} ${i18n.t("Subscribers 2,3,4")}`;
		} else {
			return `${numberWithSpaces(num)} ${i18n.t("Subscribers")}`;
		}
	}
}

export const calculateFeedsModalPaddingTop = (feeds: number) => {
	return `calc(100vh - (90px + (60px * ${feeds})))`;
}

export const createPreviewImageFromTitle = (code: string) => {
	let result = '';

	if (/[a-zA-Zа-яА-Я0-9]/.test(code)) {
		if (code.includes(' ')) {
			const words = code.split(' ');

			if (words.length === 1) {
				result = words[0][0];
			} else {
				const firstTwoWords = words.slice(0, 2);
				result = firstTwoWords.map((word) => {
					let char = '';
					const wordTrimmed = word.trim();

					if (wordTrimmed.includes('И')) {
						const iIndex = wordTrimmed.indexOf('И');

						if (
							(iIndex === 0 || wordTrimmed[iIndex - 1] === ' ' || wordTrimmed[iIndex - 1].match(/[!?.,]/))
							&& (iIndex === wordTrimmed.length - 1 || wordTrimmed[iIndex + 1] === ' ' || wordTrimmed[iIndex + 1].match(/[!?.,]/))
						) {
							char = 'И';
						}
					}

					return char || wordTrimmed[0];
				}).join('');
			}
		} else if (/[0-9]/.test(code)) {
			const numbers = code.match(/[0-9]+/g);
			const letters = code.match(/[a-zA-Zа-яА-Я]+/g);

			if (numbers && letters && numbers.length > 0 && letters.length > 0) {
				const firstDigit = numbers[0][0];
				const firstLetter = letters[0][0];

				const digitIndex = code.indexOf(firstDigit);
				const letterIndex = code.indexOf(firstLetter);

				if (digitIndex < letterIndex) {
					result = firstDigit;
				} else {
					result = firstLetter;
				}
			} else if (numbers && numbers.length > 0) {
				result = numbers[0][0];
			} else if (letters && letters.length > 0) {
				result = letters[0][0];
			}
		} else {
			result = code[0];
		}
	} else {
		result = code[0];
	}

	return result;
};

export const selectCategoryData = (code: string) => {
	switch (code) {
		case 'tech':
			return {
				sliderImage: Tech,
				pageImage: Tech_page,
				previewImage: TechIcon,
				categoryName: "Technologies",
			};
		case 'video':
			return {
				sliderImage: Video,
				pageImage: Video_page,
				previewImage: VideoIcon,
				categoryName: "Video and Films",
			};
		case 'food':
			return {
				sliderImage: Food,
				pageImage: Food_page,
				previewImage: FoodIcon,
				categoryName: "Food and Cooking",
			};
		case 'entertainment':
			return {
				sliderImage: Humor,
				pageImage: Humor_page,
				previewImage: HumorIcon,
				categoryName: "Humor and Entertainment",
			};
		case 'pics':
			return {
				sliderImage: Images,
				pageImage: Images_page,
				previewImage: ImagesIcon,
				categoryName: "Pictures and Photos",
			};
		case 'education':
			return {
				sliderImage: Education,
				pageImage: Education_page,
				previewImage: EducationIcon,
				categoryName: "Education",
			};
		case 'business':
			return {
				sliderImage: Businness,
				pageImage: Businness_page,
				previewImage: BusinnessIcon,
				categoryName: "Business and Startups",
			};
		case 'adult':
			return {
				sliderImage: Adult,
				pageImage: Adult_page,
				previewImage: AdultIcon,
				categoryName: "Adult",
			};
		case 'economics':
			return {
				sliderImage: Economy,
				pageImage: Economy_page,
				previewImage: EconomyIcon,
				categoryName: "Economics",
			};
		case 'blogs':
			return {
				sliderImage: Blog,
				pageImage: Blog_page,
				previewImage: BlogIcon,
				categoryName: "Blogs",
			};
		case 'music':
			return {
				sliderImage: Music,
				pageImage: Music_page,
				previewImage: MusicIcon,
				categoryName: "Music",
			};
		case 'news':
			return {
				sliderImage: News,
				pageImage: News_page,
				previewImage: NewsIcon,
				categoryName: "News and Media",
			};
		case 'sales':
			return {
				sliderImage: Sales,
				pageImage: Sales_page,
				previewImage: SalesIcon,
				categoryName: "Sales",
			};
		case 'books':
			return {
				sliderImage: Books,
				pageImage: Books_page,
				previewImage: BooksIcon,
				categoryName: "Books",
			};
		case 'quotes':
			return {
				sliderImage: Qoutes,
				pageImage: Qoutes_page,
				previewImage: QoutesIcon,
				categoryName: "Quotes",
			};
		case 'other':
			return {
				sliderImage: Misc,
				pageImage: Misc_page,
				previewImage: MiscIcon,
				categoryName: "Other",
			};
		case 'apps':
			return {
				sliderImage: Apps,
				pageImage: Apps_page,
				previewImage: AppsIcon,
				categoryName: "Software & Applications",
			};
		case 'sport':
			return {
				sliderImage: Sport,
				pageImage: Sport_page,
				previewImage: SportIcon,
				categoryName: "Sport",
			};
		case 'language':
			return {
				sliderImage: Lingvo,
				pageImage: Lingvo_page,
				previewImage: LingvoIcon,
				categoryName: "Linguistics",
			};
		case 'crypto':
			return {
				sliderImage: Crypto,
				pageImage: Crypto_page,
				previewImage: CryptoIcon,
				categoryName: "Cryptocurrencies",
			};
		case 'travels':
			return {
				sliderImage: Travel,
				pageImage: Travel_page,
				previewImage: TravelIcon,
				categoryName: "Travel",
			};
		case 'handmade':
			return {
				sliderImage: Handmade,
				pageImage: Handmade_page,
				previewImage: HandmadeIcon,
				categoryName: "Handiwork",
			};
		case 'beauty':
			return {
				sliderImage: Beauty,
				pageImage: Beauty_page,
				previewImage: BeautyIcon,
				categoryName: "Fashion and Beauty",
			};
		case 'medicine':
			return {
				sliderImage: Medicine,
				pageImage: Medicine_page,
				previewImage: MedicineIcon,
				categoryName: "Medicine",
			};
		case 'career':
			return {
				sliderImage: Career,
				pageImage: Career_page,
				previewImage: CareerIcon,
				categoryName: "Career",
			};
		case 'transport':
			return {
				sliderImage: Transport,
				pageImage: Transport_page,
				previewImage: TransportIcon,
				categoryName: "Transport",
			};
		case 'marketing':
			return {
				sliderImage: Marketing,
				pageImage: Marketing_page,
				previewImage: MarketingIcon,
				categoryName: "Marketing, PR, Advertising",
			};
		case 'telegram':
			return {
				sliderImage: Telegram,
				pageImage: Telegram_page,
				previewImage: TelegramIcon,
				categoryName: "Telegram",
			};
		case 'psychology':
			return {
				sliderImage: Psychology,
				pageImage: Psychology_page,
				previewImage: PsychologyIcon,
				categoryName: "Psychology",
			};
		case 'babies':
			return {
				sliderImage: Family,
				pageImage: Family_page,
				previewImage: FamilyIcon,
				categoryName: "Family & Children",
			};
		case 'nature':
			return {
				sliderImage: Nature,
				pageImage: Nature_page,
				previewImage: NatureIcon,
				categoryName: "Nature",
			};
		case 'politics':
			return {
				sliderImage: Policy,
				pageImage: Policy_page,
				previewImage: PolicyIcon,
				categoryName: "Politics",
			};
		case 'design':
			return {
				sliderImage: Design,
				pageImage: Design_page,
				previewImage: DesignIcon,
				categoryName: "Design",
			};
		case 'religion':
			return {
				sliderImage: Religion,
				pageImage: Religion_page,
				previewImage: ReligionIcon,
				categoryName: "Religion",
			};
		case 'edutainment':
			return {
				sliderImage: Science,
				pageImage: Science_page,
				previewImage: ScienceIcon,
				categoryName: "Edutainment",
			};
		case 'health':
			return {
				sliderImage: Health,
				pageImage: Health_page,
				previewImage: HealthIcon,
				categoryName: "Health and Fitness",
			};
		case 'gambling':
			return {
				sliderImage: Betting,
				pageImage: Betting_page,
				previewImage: BettingIcon,
				categoryName: "Bookmaking",
			};
		case 'games':
			return {
				sliderImage: Games,
				pageImage: Games_page,
				previewImage: GamesIcon,
				categoryName: "Games",
			};
		case 'courses':
			return {
				sliderImage: Guides,
				pageImage: Guides_page,
				previewImage: GuidesIcon,
				categoryName: "Courses and Guides",
			};
		case 'art':
			return {
				sliderImage: Art,
				pageImage: Art_page,
				previewImage: ArtIcon,
				categoryName: "Art",
			};
		case 'law':
			return {
				sliderImage: Law,
				pageImage: Law_page,
				previewImage: LawIcon,
				categoryName: "Law",
			};
		case 'instagram':
			return {
				sliderImage: Instagram,
				pageImage: Instagram_page,
				previewImage: InstagramIcon,
				categoryName: "Instagram",
			};
		case 'erotica':
			return {
				sliderImage: Ero,
				pageImage: Ero_page,
				previewImage: EroIcon,
				categoryName: "Erotic",
			};
		case 'shock':
			return {
				sliderImage: Shock,
				pageImage: Shock_page,
				previewImage: ShockIcon,
				categoryName: "Shock content",
			};
		case 'esoterics':
			return {
				sliderImage: Tarot,
				pageImage: Tarot_page,
				previewImage: TarotIcon,
				categoryName: "Esoterics",
			};
		case 'darknet':
			return {
				sliderImage: Darknet,
				pageImage: Darknet_page,
				previewImage: DarknetIcon,
				categoryName: "Darknet",
			};
		case 'construction':
			return {
				sliderImage: Interiour,
				pageImage: Interiour_page,
				previewImage: InteriourIcon,
				categoryName: "Interior and Construction",
			};
		default:
			return {
				sliderImage: Misc,
				pageImage: Misc_page,
				previewImage: MiscIcon,
				categoryName: "Other",
			};
	}
}