import { FC } from "react";

import { ReactComponent as Spinner } from "../../icons/spinner.svg"

import "../../styles/Load.css"

const Load: FC<{ fullscreen?: boolean, catalogPage?: boolean }> = ({ fullscreen = false, catalogPage = false }) => {

	return (
		<>
			{
				fullscreen
					? (
						<div className={`${catalogPage ? "spinner-fullscreen-catalog" : "spinner-fullscreen"}`}>
							<Spinner className="spinner" />
						</div>
					)
					: <Spinner className="spinner" />
			}
		</>
	)
}

export default Load;
