import { BaseSyntheticEvent, FC } from "react";

import { THEME_DARK } from "../models/models";

import { ReactComponent as CloseLightTheme } from "../icons/close_lightTheme.svg";
import { ReactComponent as CloseDarkTheme } from "../icons/close_darkTheme.svg";

const CloseIcon : FC< { onClick?: (event: BaseSyntheticEvent) => void } >  = ({ onClick }): JSX.Element => (
	<div className="pointer">
		{
			!THEME_DARK
				? <CloseLightTheme onClick={onClick} />
				: <CloseDarkTheme onClick={onClick} />
		}
	</div>
)

export default CloseIcon;