import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MetaDataParams } from "../models/models";
import { selectCategoriesToShow, showMainTelegramButton, unmountedTelegramButton } from "../models/functions";
import { useToggleShowAdultsChannels } from "../store";
import { getMetaData } from "../hooks/api";

import Load from "../components/fetch/Load";
import Error from '../components/fetch/Error';
import CategoriesList from "../components/LibraryScreen/CategoriesList";
import Htag from "../components/Htag";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Categories: FC = () => {

	const { t } = useTranslation();

	const navigate = useNavigate()

	const { toggleStateStore: showAdultsChannelsToggleStore } = useToggleShowAdultsChannels();

	const {
		data: metaData,
		isLoading,
		isError,
		error
	} = useQuery<MetaDataParams, Error>({
		queryKey: ["meta-data"],
		queryFn: getMetaData,
		retry: 2,
		retryOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const goBack = () => {
		navigate(-1)
	}

	useEffect(() => {
		showMainTelegramButton(goBack, t("Back"));
		return () => unmountedTelegramButton(goBack);
	}, [])

	if (isLoading) return <Load fullscreen />;
	if (isError) return <Error message={error.message} />;
	if (!metaData) return <></>;

	const categoriesToShow = selectCategoriesToShow(showAdultsChannelsToggleStore, metaData.categories);

	return (
		<div className="m-0 p-0">
			<div className="d-flex align-items-center p-0 w-auto justify-content-between">
				<Htag textAlign="left" tag='h3'>{t("Categories")}</Htag>
			</div>

			<CategoriesList categories={categoriesToShow} allCategories />
		</div>
	)
};

export default Categories;