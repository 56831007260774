import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { openTelegramLink, showMainTelegramButton, unmountedTelegramButton } from "../models/functions";
import { webApp } from "../models/models";
import useChangeHeaderColorFirstLoad from "../hooks/useChangeHeaderColorFirstLoad";

import CustomButton from "../components/CustomButton";

import { useTranslation } from "react-i18next";

import "../styles/AddChannelFromSubscriptions.css";

const AddChannelFromSubscriptions: FC = () => {

	const navigate = useNavigate();
	const { t } = useTranslation();

	const goBack = () => {
		navigate(-1);
	}

	useChangeHeaderColorFirstLoad();

	useEffect(() => {
		showMainTelegramButton(goBack, t("Back"));
		return () => unmountedTelegramButton(goBack);
	}, [])


	return (
		<section className="add-channel-from-subsribe-wrapper">

			<div className="add-channel-from-subsribe-content">
				<p className="add-channel-from-subsribe-content-paragraph text-color">{t("Add channel from subscription - title")}</p>

				<div className="add-channel-from-subsribe-img"/>
			</div>

			<div className="add-channel-from-subsribe-buttons">
				<CustomButton 
					onClick={() => webApp.openLink("https://telegrok.slite.page/p/3ONynsOACSwtTx/Kak-dobavit-kanal-v-lentu")}
					name={t("Reference")}
					borderBottom
					icon="ReferenceIcon"
					additionalIcon="Arrow"
				/>
				<CustomButton
					borderTopRadius={false}
					onClick={() => openTelegramLink("https://t.me/TelegrokOfficialGroup")}
					name={t("Support")}
					icon="SupportIcon"
					additionalIcon="Arrow"
				/>
			</div>

		</section>
	);
};

export default AddChannelFromSubscriptions;