import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useProductTour, useProductTour_AddChannelFromFeed, useProductTour_CreateFeed, useProductTour_SwipeChannel, useProductTour_SwipeFeed, useShowModal_FeedsLimit } from "../../../store";

import { APP_COLOR_HINT, FeedModel, MAX_LENGTH_FEEDS, PLATFORM_IOS, THEME_DARK, webApp } from "../../../models/models";
import { getFeeds } from "../../../hooks/api";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import Tooltip from "../Tooltip";

import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useQuery } from "@tanstack/react-query";

import "../../../styles/ProductTour.css"

const ProductTour = () => {
	const { statusProductTour, updateStatusProductTour } = useProductTour();
	const { updateStatusProductTour: updateStatusProductTour_CreateFeed } = useProductTour_CreateFeed();
	const { updateStatusProductTour: updateStatusProductTour_AddChannelFromFeed } = useProductTour_AddChannelFromFeed();
	const { updateStatusProductTour: updateStatusProductTour_SwipeFeed } = useProductTour_SwipeFeed();
	const { updateStatusProductTour: updateStatusProductTour_SwipeChannel } = useProductTour_SwipeChannel();

	const { setShow } = useShowModal_FeedsLimit();

	const navigate = useNavigate();

	const {
		data: feeds
	  } = useQuery<FeedModel[], Error>({
		queryKey: ["feeds"],
		queryFn: getFeeds,
		retry: 2
	  })

	const [runTour] = useState<boolean>(statusProductTour);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [steps] = useState<Step[]>([
		{
			title: "Добро пожаловать!",
			content: <Step1 />,
			placement: "center",
			target: "body"
		},
		{
			title: "Ленты",
			content: <Step2 />,
			placement: "center",
			target: "body"
		},
		{
			title: "Ленты",
			content: <Step3 />,
			target: "#navigation-menu-item-feeds",
			disableBeacon: true,
		},
		{
			title: "Каталог",
			content: <Step4 />,
			target: "#navigation-menu-item-catalog",
			disableBeacon: true,
		},
		{
			title: "Помощь",
			content: <Step5 />,
			target: "#navigation-menu-item-help",
			disableBeacon: true,
		},
		{
			title: "Добавить ленту",
			content: <Step6 />,
			target: "#product-tour-main-button",
			disableBeacon: true
		},
	  ]);

	  const onClickCreateFeed = () => {
		updateStatusProductTour(false);
		navigate("/feeds/create");
	  }

	  const onClickLimitFeeds = () => {
		setShow(true);
		updateStatusProductTour(false);
		updateStatusProductTour_CreateFeed(false);
	  }

	  const onClickEndTour = () => {
		updateStatusProductTour(false);
		updateStatusProductTour_CreateFeed(false);
		updateStatusProductTour_AddChannelFromFeed(false);
		updateStatusProductTour_SwipeFeed(false);
		updateStatusProductTour_SwipeChannel(false);
	  }

	  useEffect(() => {
		if(currentStep === steps.length && (feeds && feeds.length < MAX_LENGTH_FEEDS)) {
			webApp.MainButton.color = webApp.themeParams.button_color;
			webApp.MainButton.onClick(onClickCreateFeed);
			webApp.MainButton.text = "Создать ленту";
		} else if (currentStep === steps.length && (feeds && feeds.length >= MAX_LENGTH_FEEDS)) {
			webApp.MainButton.color = APP_COLOR_HINT;
			webApp.MainButton.onClick(onClickLimitFeeds);
			webApp.MainButton.text = "Создать ленту";
		} else {
			webApp.MainButton.color = webApp.themeParams.button_color;
			webApp.MainButton.onClick(onClickEndTour);
			webApp.MainButton.text = "Завершить обучение";
		}

		webApp.MainButton.enable();
		
		return () => {
			webApp.MainButton.offClick(onClickCreateFeed);
			webApp.MainButton.offClick(onClickEndTour);
			webApp.MainButton.offClick(onClickLimitFeeds);
		}
	  }, [currentStep])

	  const handleJoyrideCallback = (data: CallBackProps) => {
		const { status, index } = data;
		const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

		setCurrentStep(index + 1)

		if(THEME_DARK) {
			window.document.querySelector(".react-joyride__overlay")?.classList.add("spotlight_dark_theme")
			window.document.querySelector(".react-joyride__spotlight")?.classList.add("react-joyride__spotlight_dark_theme")
		}

		if(index === steps.length - 1) {
			window.dispatchEvent(new Event('resize'));
			window.document.querySelector(".react-joyride__spotlight")?.classList.add("spotlight_hide")
		} else {
			window.document.querySelector(".react-joyride__spotlight")?.classList.remove("spotlight_hide")
		}

		if (finishedStatuses.includes(status)) {
			updateStatusProductTour(false);
		};
	  }  

	return (
		<Joyride
			disableOverlayClose
			disableScrollParentFix
			disableCloseOnEsc
			tooltipComponent={Tooltip}
			callback={handleJoyrideCallback}
			continuous
			hideCloseButton
			run={runTour}
			scrollToFirstStep
			showProgress
			steps={steps}
			styles={{
				options: {
					arrowColor: PLATFORM_IOS && THEME_DARK ? webApp.themeParams.secondary_bg_color : webApp.themeParams.bg_color,
					zIndex: 20,
				},
				spotlight: {
					backgroundColor: THEME_DARK ? APP_COLOR_HINT : "#ffffff"
				}
			}}
		/>
	)
}

export default ProductTour;