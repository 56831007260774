import ru from '../src/locales/ru/translation.json';
import en from '../src/locales/en/translation.json';

import { useChangeLanguage } from "./store";

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  ru: {
    translation: ru
  },
  en: {
    translation: en
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: useChangeLanguage.getState().language,
    debug: true,
    keySeparator: false, 
    nsSeparator: false
  });

export default i18n;