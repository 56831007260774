import { FC } from 'react';
import { useTranslation } from "react-i18next";

const Error: FC<{ message?: string }> = ({ message }) => {

  const { t } = useTranslation();

  return (
    <>
      <p>{t("There is an error")}</p>
      {message && (
        <p>{message}</p>
      )}
    </>
  );
};
export default Error;
