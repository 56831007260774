import { FC, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useChangeCountry, useChangeLanguage, useToggleShowAdultsChannels, useShowModal_Help, useToggleSummarizeAdPosts } from "../store";
import { showMainTelegramButton, unmountedTelegramButton } from "../models/functions";

import CustomButton from "../components/CustomButton";
import SwitchButton from "../components/SwitchButton";

import { ReactComponent as ArrowIcon } from "../icons/arrow.svg"

import ListGroup from 'react-bootstrap/ListGroup';

import { useTranslation } from "react-i18next";

import "../styles/Settings.css";

const selectLanguageName = (language: string) => {
  switch (language) {
    case "ru":
      return "Russian"
    case "en":
      return "English"
    default:
      return "🌐"
  }
}

const Settings: FC = (): JSX.Element => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  // const { toggleStateStore: showHelpersToggleStore, setToggleStateStore: setShowHelpersToggleStore } = useToggleShowHelpers(); 
  const { toggleStateStore: showAdultsChannelsToggleStore, setToggleStateStore: setShowAdultsChannelsToggleStore } = useToggleShowAdultsChannels();
  // const { toggleStateStore: summarizeToggleStore, setToggleStateStore: setSummarizeToggleStore } = useToggleSummarizeAdPosts();
  const { language } = useChangeLanguage();
	const { show: showHelpModal, setShow: setShowHelpModal} = useShowModal_Help();
	const { countryNameStore } = useChangeCountry();

  const goPreviousPage_settings = () => {
    if(showHelpModal) {
      setShowHelpModal(false);
    } else {
      navigate(-1);
    }
  }

  useEffect(() => {
    showMainTelegramButton(goPreviousPage_settings, t("Back"));
    return () => unmountedTelegramButton(goPreviousPage_settings);
  }, [])

  useEffect(() => {
    showMainTelegramButton(goPreviousPage_settings, t("Back"));
    return () => unmountedTelegramButton(goPreviousPage_settings);
  }, [showHelpModal])

  return (
    <section className="settings-wrapper">
      <ListGroup className="settings-list">

        <ListGroup.Item 
          className="settings-list-item background-list-item border-bottom_after pointer" 
          onClick={() => navigate("/settings/language")}
        >
          <div className="settings-list-item-name">
            <div className="settings-list-item-icon">🔤</div>

            <p className="settings-list-item-text">{t("Language")} Telegrok</p>
          </div>

          <div className="settings-list-item-value">
            <p className="settings-list-item-value-text">{t(selectLanguageName(language))}</p>

            <div className="settings-list-item-value-icon svg-fill-hint">
              <ArrowIcon width={15} height={15} />
            </div>
          </div>
        </ListGroup.Item>


        <ListGroup.Item 
          className="settings-list-item background-list-item border-bottom_after pointer"
          onClick={() => navigate("/settings/country")}
        >
          <div className="settings-list-item-name">
            <div className="settings-list-item-icon">🌐</div>

            <div className="settings-list-item-text-wrapper">
              <p className="settings-list-item-text">{t("Country")}</p>
            </div>
          </div>

          <div className="settings-list-item-value">
            <p className="settings-list-item-value-text">{countryNameStore}</p>

            <div className="settings-list-item-value-icon svg-fill-hint">
              <ArrowIcon width={15} height={15} />
            </div>
          </div>
        </ListGroup.Item>


        {/* <ListGroup.Item className="settings-list-item background-list-item">
          <div className="settings-list-item-name">
            <div className="settings-list-item-icon svg-fill-orange">
              <GlobeIcon />
            </div>

            <p className="settings-list-item-text">{t("Display hints")}</p>
          </div>

          <Form>
            <Form.Check type="switch" id="helpers-switch" onChange={toggleShowHelpers} checked={showHelpers} />
          </Form>
        </ListGroup.Item> */}


        <ListGroup.Item 
          className="settings-list-item background-list-item"
        >
          <div className="settings-list-item-name">
            <div className="settings-list-item-icon">🍓</div>

            <div className="settings-list-item-text-wrapper">
              <p className="settings-list-item-text">{t("Show channels 18+")}</p>
            </div>
          </div>

          <SwitchButton
            onChange={setShowAdultsChannelsToggleStore}
            state={showAdultsChannelsToggleStore}
          />
        </ListGroup.Item>

        {/* <ListGroup.Item 
          className="settings-list-item background-list-item border-bottom_after"
        >
          <div className="settings-list-item-name">
            <div className="settings-list-item-icon">📝</div>

            <div className="settings-list-item-text-wrapper">
              <p className="settings-list-item-text">{t("Summarize Ad Posts")}</p>
              <p className="settings-list-item-subtitle">{t("Experimental Feature")}</p>
            </div>
          </div>

          <SwitchButton
            onChange={setSummarizeToggleStore}
            state={summarizeToggleStore}
          />
        </ListGroup.Item>  */}

      </ListGroup>

      <CustomButton 
        name={t("Share what can be improved")} 
        onClick={() => navigate("/settings/feedback")}
        additionalIcon="Arrow"
      />
    </section>
  );

};

export default Settings;