import { useEffect } from "react";

export default function useDebounceShowNotificationModal(
	show: boolean,
	setShow: (state: boolean) => void
) {

	useEffect(() => {
		if (!show) return;
		const delayDebounceFn = setTimeout(() => {
			setShow(false)
		}, 7000);
		return () => clearTimeout(delayDebounceFn)
	  }, [show])

}