import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { webApp } from "../models/models";

import Load from "../components/fetch/Load";

const ForwardRedirectPage = () => {
	const { state } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		webApp.BackButton.hide();
	}, [])

	useEffect(() => {
		if(!state && !state.link) return;

		setTimeout(() => {
			navigate(state.link, { replace: false, state: { pageFrom: "forward-redirect" } });
		}, 0)
	}, [state])

	return <Load fullscreen/>
}

export default ForwardRedirectPage;