import { useEffect, useState } from "react";

import {
	APP_COLOR_HINT,
	AddChannelModalProps,
	AddChannelProps,
	FeedModel,
	MAX_CHANNELS_IN_FEED,
	THEME_DARK,
	webApp
} from "../../models/models";
import { useDefaultFeedToAddChannel } from "../../store";
import { 
	checkDuplicateChannels, 
	createPreviewImageFromTitle,
	calculateFeedsModalPaddingTop, 
	showMainTelegramButton, 
	unmountedTelegramButton, 
	unmountedTelegramButtonInModal
} from "../../models/functions";
import { getFeeds, useAddChannel } from "../../hooks/api";
import useChangeHeaderColorInModal from "../../hooks/useChangeHeaderColorInModal";
import useDebounceShowNotificationModal from "../../hooks/useDebounceNotificationModal";

import NotificationModal from "./NotificationModal";

import { ReactComponent as CheckMarkIcon } from "../../icons/checkmark.svg"

import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import { useTranslation } from "react-i18next";
import { useExpand } from "@vkruglikov/react-telegram-web-app";
import { useQuery } from "@tanstack/react-query";

const AddChannelModal = ({
	show,
	onHide,
	channelId,
	telegramId,
	setAddChannelStatus,
	setCurrentFeed,
	parentMainButtonFunction
}: AddChannelModalProps) => {

	const { t } = useTranslation();

	const [isExpanded, expand] = useExpand();

	const { defaultFeed, updateFeed: updateDefaultFeed } = useDefaultFeedToAddChannel();

	const [selectedFeed, setSelectedFeed] = useState<FeedModel | null>(defaultFeed);
	const [addChannelLoading, setAddChannelLoading] = useState<boolean>(false);
	const [showChannelsLimitModal, setShowChannelsLimitModal] = useState<boolean>(false);

	const duplicateChannel = checkDuplicateChannels(selectedFeed, telegramId || null);
	const maxChannels = selectedFeed && selectedFeed.channels && selectedFeed?.channels?.length >= MAX_CHANNELS_IN_FEED;

	const {
		mutate: addChannel,
		status: addChannelStatus
	} = useAddChannel();

	const {
		data: feeds,
		refetch: feedsRefetch
	} = useQuery<FeedModel[], Error>({
		queryKey: ["feeds"],
		queryFn: getFeeds,
		refetchOnWindowFocus: false,
	});

	const subscribedFeeds = feeds && feeds.filter((feed) => feed.status === "transferred");

	const onMainButtonClick = () => {
		if (duplicateChannel || !selectedFeed || addChannelLoading) {
			return undefined;
		} else if(maxChannels) {
			setShowChannelsLimitModal(true);
		} else {
			addChannelClick(paramsToAddChannel);
		}
	}

	const mainButtonText = () => {
		if (duplicateChannel) {
			return "Сhannel already been added"
		} else if (addChannelLoading) {
			return "Adding a channel"
		} else {
			return "Add to feed"
		}
	}

	useChangeHeaderColorInModal(show);
	useDebounceShowNotificationModal(showChannelsLimitModal, setShowChannelsLimitModal);

	useEffect(() => {
		if(show) {
			setAddChannelStatus("");
		}
		if(show || !parentMainButtonFunction) return;
		showMainTelegramButton(parentMainButtonFunction, t("Back"));
		return () => unmountedTelegramButton(parentMainButtonFunction);
	}, [show])

	useEffect(() => {
		if(!subscribedFeeds || !show || !selectedFeed) return;
		// обновляем состояние выбранной ранее\при переходе ленты при открытии модального окна, что бы видеть актуальное количество каналов
		const currentFeed = subscribedFeeds.find(feed => feed.id === selectedFeed.id);
		if(currentFeed) {
			setSelectedFeed(currentFeed)
		}
	}, [show])
	
	useEffect(() => {
		if(!show) return;

		showMainTelegramButton(onMainButtonClick, t(mainButtonText()));

		if((duplicateChannel || !selectedFeed || addChannelLoading || maxChannels)) {
			webApp.MainButton.color =  APP_COLOR_HINT;
			if(!maxChannels) {
				webApp.MainButton.disable();
			}
		} else {
			webApp.MainButton.color = webApp.themeParams.button_color;
			webApp.MainButton.enable();
		}

		return () => unmountedTelegramButtonInModal(onMainButtonClick);
	}, [show, duplicateChannel, selectedFeed, addChannelLoading, maxChannels])

	useEffect(() => {
		if (show && !isExpanded) {
			onHide();
		}
	}, [isExpanded])

	useEffect(() => {
		setAddChannelStatus(addChannelStatus);
		if (addChannelStatus === "success") {
			setAddChannelLoading(false);
			feedsRefetch();
			onHide();
		}
	}, [addChannelStatus])

	useEffect(() => {
		if(!show) return;
		if (subscribedFeeds && subscribedFeeds.length === 1) {
			setSelectedFeed(subscribedFeeds[0])
		}
	}, [subscribedFeeds])

	if (!feeds || feeds.length === 0) return <></>

	const paramsToAddChannel = {
		feedId: selectedFeed ? selectedFeed.id : null,
		channelParams: {
			channel_id: channelId || 0
		}
	};

	const addChannelClick = (params: AddChannelProps) => {
		setCurrentFeed && setCurrentFeed(params.feedId || 0);
		addChannel(params);
		setAddChannelLoading(true);
	};

	const updateFeed = (feed: FeedModel) => {
		updateDefaultFeed(feed);
		setSelectedFeed(feed);
	}

	return (
		<>
			<Modal
				animation={false}
				centered
				show={show}
				onHide={onHide}
				dialogClassName="custom-modal modal-add-channel"
				style={feeds && subscribedFeeds && subscribedFeeds.length > 0 ? { paddingTop: calculateFeedsModalPaddingTop(subscribedFeeds.length) } : { display: "none" } }
				aria-labelledby="example-custom-modal-styling-title"
			>

				<Modal.Header className="custom-modal-header-wrapper">
					<p className="custom-modal-header text-hint">{t("Select feed")}</p>
				</Modal.Header>

				<Modal.Body>
					{
						subscribedFeeds ?
							<ListGroup className="custom-list-wrapper">
								{
									subscribedFeeds.map((feed, index) => {
										const { id, title } = feed;

										return (
											<ListGroup.Item
												className="custom-list-wrapper-item background-list-item"
												key={id}
												onClick={() => feed !== selectedFeed ? updateFeed(feed) : undefined}
											>

												<div className="list-item-no-image-wrapper">
													<div className="list-item-no-image">{createPreviewImageFromTitle(title)}</div>
												</div>

												<div className={`
													custom-list-wrapper-title custom-wrap-text-ellipsis 
													${THEME_DARK
														? "text-white border-color-separator"
														: ""
													}
												`}>
													<p className="custom-wrap-text-ellipsis custom-list-wrapper-title_checkmark">{title}</p>
												</div>

												{
													selectedFeed && id === selectedFeed.id && (
														<div className="custom-list-wrapper-checkmark">
															<CheckMarkIcon />
														</div>
													)
												}

												{
													subscribedFeeds.length !== index + 1 && (
														<span className={THEME_DARK ? "border-bottom_after-dark" : "border-bottom_after"} />
													)
												}

											</ListGroup.Item>
										)
									})
								}
							</ListGroup>
							: <></>
					}
				</Modal.Body>

			</Modal >

			{
				showChannelsLimitModal && selectedFeed && (
					<NotificationModal
						icon="warning"
						text={`${t("Maximum number of channels in feed")} «${selectedFeed.title}»: ${MAX_CHANNELS_IN_FEED}`} 
					/>
				)
			}
		</>
	)
}

export default AddChannelModal;