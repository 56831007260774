import { FC, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useChangeLanguage } from "../store";
import { languagesList, showMainTelegramButton, unmountedTelegramButton } from "../models/functions";

import { ReactComponent as CheckMarkIcon } from "../icons/checkmark.svg";

import ListGroup from 'react-bootstrap/ListGroup';

import { useTranslation } from "react-i18next";
import i18n from "../i18n";

import "../styles/Language.css";

const Language: FC = () => {

	const { language, changeLanguage } = useChangeLanguage();

	const { t } = useTranslation();

	const navigate = useNavigate()

	const goPreviousPage = () => {
		navigate(-1)
	  }

	useEffect(() => {
		showMainTelegramButton(goPreviousPage, t("Back"));
	
		return () => unmountedTelegramButton(goPreviousPage);
	  }, [])

	const onChangeLanguage = (language: string) => {
		switch (language) {
			case "ru":
				changeLanguage("ru")
				i18n.changeLanguage("ru")
				return
			case "en":
				changeLanguage("en")
				i18n.changeLanguage("en")
				return
			default:
				return
		}
	}

	const selectLanguageName_Native = (language: string) => {
		switch (language) {
			case "ru":
				return "Русский"
			case "en":
				return "English"
			default:
				return "🌐"
		}
	}

	const selectLanguageName_English = (language: string) => {
		switch (language) {
			case "ru":
				return "Russian"
			case "en":
				return "English"
			default:
				return "🌐"
		}
	}

	return (
		<section className="language-page-wrapper">
			<p className="language-page-title">{t("Language")} Telegrok</p>

			<ListGroup className="language-page-list">
				{
					languagesList.map((item, index) => (
						<ListGroup.Item
							onClick={() => item !== language && onChangeLanguage(item)}
							className={`background-list-item language-page-list-item-wrapper ${index + 1 !== languagesList.length && "border-bottom_after"} pointer`}
							key={item}>

							<div className="language-page-list-item">
								<p className="language-page-list-item-title text-color">{selectLanguageName_Native(item)}</p>

								<p className="language-page-list-item-subtitle text-color">{selectLanguageName_English(item)}</p>
							</div>

							{
								item === language && <div className="custom-list-wrapper-checkmark"><CheckMarkIcon /></div>
							}

						</ListGroup.Item>
					))
				}
			</ListGroup>

		</section>
	)
}

export default Language;