import "../../../../styles/ProductTour.css";

const Step2 = () => (
	<div>
		<div className="product-tour-step-onboarding_image" />
		<div>
			<p><strong>Лента</strong> — это обычный Telegram-канал, в котором наш бот публикует посты из других каналов. Вы сами выбираете каналы-источники для ленты.</p>
		</div>
	</div>
)

export default Step2;