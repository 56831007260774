// Types
export type $FixMe = any;

export type ChannelIconProps = {
    img: string;
    title: string;
    searchChannelList?: boolean;
}

export type FeedbackPostProps = {
    feedback: string;
    os: string;
    browser: string;
    screen_width: string;
    screen_height: string;
    images: File[];
}

export type FeedModel = {
    id: number;
    title: string;
    status: "creating" | "created" | "transferred" | "transferring";
    telegram_id: number;
    invite_url: string;
    channels?: ChannelModel[];
    // publication_method: "manual" | "automatic";
};

export type CreateFeedProps = {
    title: string;
}

export type EditFeedParams = {
    feedId: number;
    data: EditFeedParamsData;
}
type EditFeedParamsData = {
    title: string;
    // publication_method: "manual" | "automatic";
}

export type AddChannelProps = {
    feedId: number | null;
    channelParams: AddChannelProps_ChannelParams;
}
type AddChannelProps_ChannelParams = {
    channel_id: number
}

export type FolderChatsChannelProps = {
    image: string,
    title: string
}

export type FolderChatsListProps = {
    channels: ChannelModel[]
}

export type AddChannelModalProps = {
    show: boolean;
    onHide: () => void;
    channelId?: number;
    telegramId?: number;
    setAddChannelStatus: (status: string) => void;
    setCurrentFeed?: (feed: number) => void;
    parentMainButtonFunction?: () => void;
}

export type HelpModalProps = {
    show: boolean;
    onHide: () => void;
}

export type WithoutFeedsModalProps = {
    onHide: () => void;
}

export type DeleteChannelParams = {
    feed: number;
    channel: number | null;
};

// get meta data types - begin
export type CountryCode = 'ru' | 'ua' | 'by' | 'uz' | 'kz' | 'ir' | 'kg' | 'in' | 'cn' | 'et';
type LanguageCode = 'russian' | 'ukrainian' | 'belarus' | 'farsi' | 'english' | 'uzbek' | 'kazakh' | 'hindi' | 'chinese' | 'tamil' | 'amhar';
type CategoryCode = 'tech' | 'video' | 'food' | 'entertainment' | 'pics' | 'education' | 'business' | 'adult' | 'economics' | 'blogs' | 'music' | 'news' | 'sales' | 'books' | 'quotes' | 'other' | 'apps' | 'sport' | 'language' | 'crypto' | 'travels' | 'handmade' | 'beauty' | 'medicine' | 'career' | 'transport' | 'marketing' | 'telegram' | 'psychology' | 'babies' | 'nature' | 'politics' | 'design' | 'religion' | 'edutainment' | 'health' | 'gambling' | 'games' | 'courses' | 'art' | 'law' | 'instagram' | 'erotica' | 'shock' | 'esoterics' | 'darknet' | 'construction';

export type MetaDataCountries = {
    [key in CountryCode]: string;
};

type LanguageMap = {
    [key in LanguageCode]: string;
};

export interface MetaDataCategory {
    id: number;
    name: string;
    code: CategoryCode;
}

export type MetaDataCategories = MetaDataCategory[];

export interface MetaDataParams {
    countries: MetaDataCountries;
    languages: LanguageMap;
    categories: MetaDataCategories;
}

// get meta data types - end

export type CategoriesSliderProps = {
    categories: MetaDataCategories;
}

export type ChannelModel = {
    id: number;
    category_id: number;
    telegram_id: number;
    public: boolean;
    url: string;
    title: string;
    description: string;
    image100: string;
    image640: string;
    participants: number;
}

export type MoveChannelParams = {
    from: number;
    where: number | null;
    channel: Channel;
}
interface Channel {
    channel_id: number | null;
}

export type NotificationModalProps = {
    text?: string;
    icon?: "checkmark" | "warning";
    bottomMargin?: boolean;
}

export type PromptModalProps = {
    text: string;
}

export interface CustomButtonProps {
    name: string;
    onClick?: () => void;
    textColor?: "primary" | "dark" | "secondary";
    icon?: "AddFeedIcon" | "LibraryIcon" | "FolderIcon" | "LinkIcon" | "FlagIcon" | "ReferenceIcon" | "SupportIcon";
    additionalIcon?: "Arrow" | "CheckMark" | "ArrowDown";
    showCheckMark?: boolean;
    borderBottom?: boolean;
    borderTopRadius?: boolean;
}

export interface SwitchButtonProps {
    onChange: () => void;
    state: boolean;
}

export interface CustomDropdopwnProps {
    items: string[];
    activeItem: string;
    onHide: () => void;
    setSelectItem: (item: string) => void;
    rightPositon: string;
    bottomPosition: string;
    width?: number;
    transformToTranslateText?: boolean;
}

export type getInfiniteChannelsByCategoryProps = {
    categoryId: number;
    page: number;
    by: "participants" | "created_at";
    direction: "asc" | "desc";
    country: CountryCode;
    period: "" | "week" | "month" | "year";
}

export type getInfiniteChannelsBySearchProps = {
    search: string;
    page: number;
    by: "participants" | "created_at";
    direction: "asc" | "desc";
    country: CountryCode;
    period: "" | "week" | "month" | "year";
}

export type SortedChannelsHeaderProps = {
    rounded?: boolean;
}

const selectModalHeaderColor = () => {
    if (PLATFORM_WEB) {
        return "#252527";
    } else if (PLATFORM_IOS) {
        return "#151517"
    } else if (PLATFORM_ANDROID) {
        return "#222931"
    } else if (PLATFORM_DESKTOP) {
        return "#2C323E"
    } else {
        return webApp.themeParams.bg_color
    }
}

// Variables
export const webApp = window.Telegram.WebApp;
export const BASE_URL = process.env.REACT_APP_API_HOST + '/api';
export const MAX_LENGTH_FEEDS = 5;
export const MAX_CHANNELS_IN_FEED = 25;
export const MAX_LENGTH_FEED_NAME = 255;
export const THEME_DARK = webApp.colorScheme === "dark";
export const PLATFORM_DESKTOP = webApp.platform === "tdesktop";
export const PLATFORM_IOS = webApp.platform === "ios";
export const PLATFORM_ANDROID = webApp.platform === "android";
export const PLATFORM_WEB = webApp.platform === "web";

export const MODAL_BG_COLOR = "#b1b1b6";
export const MODAL_BG_COLOR_DARK = THEME_DARK ? selectModalHeaderColor() : webApp.themeParams.bg_color;
export const APP_COLOR_HINT = "#8e8e93";